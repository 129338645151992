import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import ArrowRightIcon from "~/src/media/icons/arrowRight.svg";
import language from "~/src/utils/language";
import Header from "../header/header";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./support.css";
import { supportContent } from "./supportConstants";

const Support = () => {
    const { locales } = useContext(LanguageContext);
    const { SUPPORT } = language[locales];
    const { EMERGENCY, CATEGORY, INFORMATION } = supportContent;
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const createMarkup = (text: string) => {
        return { __html: text };
    };

    return (
        <div className="content-wrapper">
            <Header
                header={SUPPORT.HEADER}
                handleBack={() => {
                    navigate("/home");
                }}
            />
            <div className="support-emergency-wrapper">
                <h2 className="font2">{EMERGENCY.PAGE_HEADER}</h2>
                {EMERGENCY.CONTENT.map(({ HEADING, TEXT, BACKGROUND }) => {
                    return (
                        <RoundWrapper
                            key={HEADING}
                            background={BACKGROUND}
                            className="support-emergency-roundWrapper"
                        >
                            <h3
                                className="white-text font2"
                                style={{
                                    textAlign: "center",
                                    marginBottom: "1rem",
                                    marginTop: "0",
                                }}
                            >
                                {HEADING}
                            </h3>
                            <span
                                className="white-text"
                                /* biome-ignore lint/security/noDangerouslySetInnerHtml: we control the input */
                                dangerouslySetInnerHTML={createMarkup(TEXT)}
                            />
                        </RoundWrapper>
                    );
                })}

                <h3>
                    <Link className="support-share-button" to="/beb/share">
                        {SUPPORT.STORY}
                    </Link>
                </h3>
            </div>
            <div className="support-category-wrapper">
                <h2 className="font2">{CATEGORY.PAGE_HEADER}</h2>
                <ul>
                    {Object.values(CATEGORY.SECTIONS).map(({ LINK, PAGE_HEADER }) => {
                        return (
                            <li key={PAGE_HEADER}>
                                <Link className="support-link" to={LINK}>
                                    {PAGE_HEADER}
                                    <img src={ArrowRightIcon} alt="" />
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="support-category-wrapper">
                <h2 className="font2">{INFORMATION.PAGE_HEADER}</h2>
                <ul>
                    {Object.values(INFORMATION.SECTIONS).map(({ LINK, PAGE_HEADER }) => {
                        return (
                            <li key={PAGE_HEADER}>
                                <Link className="support-link" to={LINK}>
                                    {PAGE_HEADER}
                                    <img src={ArrowRightIcon} alt="" />
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="support-contact-section">
                {SUPPORT.CONTACT}
                &nbsp;
                <a className="support-contact-mail" href="mailto:info@ommej.se">
                    info@ommej.se
                </a>
                .
            </div>
        </div>
    );
};

export default Support;
