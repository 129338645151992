import { AvatarGroups } from "@ommej/metadata";
import type { AvatarId, AvatarParts, House, Profile as ProfileType } from "@ommej/types";
import { useContext, useState } from "react";
import * as React from "react";
import ErrorComponent from "~/src/components/tools/errorComponent/errorComponent";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import type { TPersonObject } from "~/src/types";
import { request } from "~/src/utils/api";
import language from "~/src/utils/language";
import { reloadProfile } from "~/src/utils/profileUtils";
import AvatarBuilder from "../avatarBuilder/avatarBuilder";
import ProfileHeader from "../header/profileHeader";
import Step from "../tools/stepper/step";
import Stepper from "../tools/stepper/stepper";
import Accommodation from "./accommodation";
import Avatar from "./avatar";
import BirthYear from "./birthYear";
import Gender from "./gender";
import Great from "./great";
import Houses from "./houses";
import MoreAccommodations from "./moreAccommodations";

const Profile = () => {
    const { locales } = React.useContext(LanguageContext);
    const { ERROR } = language[locales];
    const { profile, setProfile } = useContext(ProfileContext);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentHouse, setCurrentHouse] = useState<House>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    async function updateProfile(
        path: string,
        data: Partial<ProfileType>,
        method: "PUT" | "POST" = "PUT",
    ): Promise<string | boolean> {
        let res;
        try {
            res = await request(`clients/profile/${path}`, method, data);
            setProfile({ ...profile, ...data });
        } catch (error: unknown) {
            console.error("error:", error);
            setErrorMessage(ERROR.SERVER);
            return false;
        }

        try {
            const body = await res.text();
            return body || true;
        } catch (_err) {
            return true;
        }
    }

    const goToPreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const goToNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const goToStep = (input: number) => {
        setCurrentStep(input);
    };

    const handleAvatarClicked = async (avatar: AvatarId) => {
        if (await updateProfile("avatar", { avatar: { type: "ID", data: avatar } })) {
            goToStep(2);
        }
    };

    const handleAvatarBuilderClicked = async (avatar: AvatarParts) => {
        if (await updateProfile("avatar", { avatar: { type: "PARTS", data: avatar } })) {
            goToStep(2);
        }
    };

    const handleBirthYearClicked = async (birthYear: number) => {
        if (await updateProfile("birthyear", { birthYear })) {
            goToNextStep();
        }
    };

    const handleGenderClicked = async (gender: string) => {
        if (await updateProfile("gender", { gender })) {
            goToNextStep();
        }
    };

    const handleHouseClicked = (house: House | undefined) => {
        setCurrentHouse(house);
        goToNextStep();
    };

    const handleAccommodationDone = async (house: House, persons: TPersonObject[]) => {
        // add house
        const h = {
            avatar: house.avatar,
            type: house.type,
        };
        const houseId = await updateProfile("houses", h, "POST");
        if (!houseId) {
            // showing error message is handled in updateProfile
            return;
        }

        // add persons
        const promises = persons.map((p) => {
            const person = {
                avatar: p.avatar,
                type: p.type,
            };
            return updateProfile("persons", person, "POST");
        });
        const personIds = await Promise.all(promises);
        // TODO check that all persons were added correctly

        // add persons to house
        try {
            await request(
                `clients/profile/houses/${houseId as string}/persons`,
                "PUT",
                personIds as Array<string>,
            );
            goToNextStep();
        } catch (err) {
            console.error(err);
            setErrorMessage(ERROR.SERVER);
        }
    };

    const handleMoreAccommodationClicked = async (done: boolean) => {
        try {
            await reloadProfile(setProfile);
        } catch (_err) {
            // We need better errorhandling. Make error-callback in other commit!
            setErrorMessage(ERROR.PROFILE);
        }
        if (!done) {
            goToStep(4);
        } else {
            goToNextStep();
        }
    };

    return (
        <div className="content-wrapper">
            <Stepper
                currentStep={currentStep}
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
            >
                <Step>
                    <ProfileHeader
                        func={() => {
                            goToStep(0);
                        }}
                        step={currentStep}
                    />
                    <Avatar
                        handleAvatarClicked={handleAvatarClicked}
                        createAvatarClicked={() => {
                            goToNextStep();
                        }}
                        state={{ ageGroup: AvatarGroups.CHILD, avatarChildSelection: true }}
                        showHeader
                    />
                </Step>
                <Step>
                    <ProfileHeader
                        func={() => {
                            goToPreviousStep();
                        }}
                        step={currentStep}
                    />
                    <AvatarBuilder handleAvatarBuilderClicked={handleAvatarBuilderClicked} />
                </Step>
                <Step>
                    <ProfileHeader
                        func={() => {
                            goToStep(0);
                        }}
                        step={currentStep}
                    />
                    <BirthYear handleBirthYearClicked={handleBirthYearClicked} />
                </Step>
                <Step>
                    <ProfileHeader
                        func={() => {
                            goToPreviousStep();
                        }}
                        step={currentStep}
                    />
                    <Gender handleGenderClicked={handleGenderClicked} />
                </Step>
                <Step>
                    <ProfileHeader
                        func={() => {
                            goToPreviousStep();
                        }}
                        step={currentStep}
                    />
                    <Houses handleHouseClicked={handleHouseClicked} />
                </Step>
                <Step>
                    <ProfileHeader
                        func={() => {
                            goToPreviousStep();
                        }}
                        step={currentStep}
                    />
                    {currentHouse && (
                        <Accommodation
                            handlePersonsClicked={handleAccommodationDone}
                            house={currentHouse}
                        />
                    )}
                </Step>
                <Step>
                    <ProfileHeader func={null} step={currentStep} />
                    <MoreAccommodations
                        handleMoreAccommodationClicked={handleMoreAccommodationClicked}
                    />
                </Step>
                <Step>
                    <Great />
                </Step>
            </Stepper>
            {errorMessage && <ErrorComponent errorText={errorMessage} />}
        </div>
    );
};

export default Profile;
