import { GetAvatar } from "@ommej/componente";
import { personTypes } from "@ommej/metadata";
import type { AvatarId, Form, SingleAnswer, Uuid } from "@ommej/types";
import { useEffect, useRef, useState } from "react";
import * as React from "react";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import { LanguageContext } from "~/src/contexts/languageContext";
import language from "~/src/utils/language";
import AnswersText from "./answersText";
import type { QuestionViewResource } from "./form";
import "./form.css";
import FormFooter from "./formFooter";
import FormQuestion from "./formQuestion";

type Qst = {
    text: string;
    avatar: AvatarId;
};

const QuestionMultiplePerson: React.FC<QuestionViewResource> = ({
    question,
    setAnswer,
    resources,
    existingAnswer,
}) => {
    const { locales } = React.useContext(LanguageContext);
    const { COMMON, FORM } = language[locales];
    const [currentQuestion, setCurrentQuestion] = useState<Qst>();
    const [templateData, setTemplateData] = useState([...question.data]);
    const [partialAnswers, setPartialAnswers] = useState<SingleAnswer[]>([]);
    const answers = useRef<SingleAnswer[]>([]);
    const currentData = useRef<Uuid | undefined>();
    const answerToGroup = new Map<Uuid, number | undefined>();

    function markAnswers(data: Uuid | undefined) {
        if (!existingAnswer || !data) {
            return;
        }
        const marked: SingleAnswer[] = [];
        for (const existing of existingAnswer) {
            // do we have an existing answer for the current person? if so, it should
            // be added to partialAnswers
            if (existing.data.includes(data)) {
                marked.push({
                    answer: existing.answer,
                    data: [data],
                    timestamp: existing.timestamp,
                });
            }
        }
        setPartialAnswers(marked);
    }

    const onNextClick = () => {
        if (partialAnswers.length === 0) {
            return;
        }
        answers.current.push(...partialAnswers);
        setPartialAnswers([]);
        const data = templateData.shift();
        currentData.current = data;
        setTemplateData(templateData);
        markAnswers(data);
        if (data) {
            const person = resources[data];
            if (!person) {
                return;
            }
            setCurrentQuestion({
                text: person.type
                    ? question.question.text.sv.replaceAll(
                          "%data%",
                          personTypes[person.type].lang.sv,
                      )
                    : "unknown", // should never happen
                avatar: person.avatar,
            });
        } else {
            const a = new Map<Uuid, Uuid[]>();
            for (const answer of answers.current) {
                if (!a.has(answer.answer)) {
                    a.set(answer.answer, answer.data);
                } else {
                    a.set(answer.answer, [...(a.get(answer.answer) ?? []), ...answer.data]);
                }
            }

            const ans: SingleAnswer[] = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, val] of a) {
                ans.push({ answer: key, data: val, timestamp: new Date() });
            }
            setAnswer(question.id, ans);
        }
    };

    const onAnswerClick = (answerId: Uuid) => {
        // toggle the answer
        const existingIndex = partialAnswers.findIndex((pAns) => {
            return pAns.answer === answerId;
        });

        let tmpPartialAnswers: SingleAnswer[];
        if (existingIndex !== -1) {
            tmpPartialAnswers = [...partialAnswers];
            tmpPartialAnswers.splice(existingIndex, 1);
        } else {
            tmpPartialAnswers = [
                ...partialAnswers,
                {
                    answer: answerId,
                    data: currentData.current ? [currentData.current] : [],
                    timestamp: new Date(),
                },
            ];
        }

        // remove answers in other groups
        const newPartialAnswers = tmpPartialAnswers.filter((answer) => {
            return answerToGroup.get(answer.answer) === answerToGroup.get(answerId);
        });

        setPartialAnswers(newPartialAnswers);
    };

    useEffect(() => {
        answers.current = [];
        setPartialAnswers([]);
        const data = templateData.shift();
        currentData.current = data;
        setTemplateData(templateData);
        markAnswers(data);
        if (data) {
            const person = resources[data];
            if (!person) {
                return;
            }
            setCurrentQuestion({
                text: person.type
                    ? question.question.text.sv.replaceAll(
                          "%data%",
                          personTypes[person.type].lang.sv,
                      )
                    : "unknown", // should never happen
                avatar: person.avatar,
            });
        } else {
            // this should never happen - we should not end up in this question
            // type/component without any data
            console.error("This should not happen");
        }
    }, [question]);

    return currentQuestion ? (
        <>
            <div className="form-question-wrapper">
                <FormQuestion text={currentQuestion.text} />
                <div className="form-question-resource">
                    <GetAvatar avatarData={currentQuestion.avatar} />
                </div>
                <div className="bodyText14" style={{ textAlign: "center" }}>
                    {FORM.MULTIPLE}
                </div>
                <div className="form-answers">
                    {Object.entries(question.question.answers).map(([answerId, answer]) => {
                        answerToGroup.set(answerId, (answer as Form.AnswerMultiple).group);
                        switch (answer.type) {
                            case "text":
                                return (
                                    <AnswersText
                                        testid="form-answer-alternative"
                                        key={answerId}
                                        text={answer.text.sv}
                                        onClick={() => {
                                            onAnswerClick(answerId);
                                        }}
                                        marked={partialAnswers.some((pAns) => {
                                            return pAns.answer === answerId;
                                        })}
                                    />
                                );
                            default:
                                return <span key={answerId}>{FORM.UNKNOWN}</span>;
                        }
                    })}
                </div>
            </div>
            <FormFooter>
                {partialAnswers.length !== 0 && (
                    <ButtonPrimary
                        testid="form-done"
                        size="large"
                        onClick={onNextClick}
                        disabled={partialAnswers.length === 0}
                    >
                        {COMMON.BUTTON_NEXT}
                    </ButtonPrimary>
                )}
            </FormFooter>
        </>
    ) : null;
};

export default QuestionMultiplePerson;
