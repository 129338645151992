import type * as React from "react";
import "./underscoreButton.css";

type TUnderscoreButtonProps = {
    children: React.ReactNode;
    ariaText?: string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    active?: boolean;
};

const UnderscoreButton = ({ children, ariaText, active, onClick }: TUnderscoreButtonProps) => {
    return (
        <button className="underscoreButton" type="button" aria-label={ariaText} onClick={onClick}>
            <div className="underscoreButtonText">{children}</div>
            <div className={`underscore ${active ? "active" : ""}`} />
        </button>
    );
};
export default UnderscoreButton;
