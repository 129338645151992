import { GetAvatar } from "@ommej/componente";
import { personTypes } from "@ommej/metadata";
import type { Uuid } from "@ommej/types";
import { useContext, useEffect, useState } from "react";
import * as React from "react";
import NewPerson from "~/src/components/profile/newPerson";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import ButtonText from "~/src/components/tools/buttons/buttonText";
import Modal from "~/src/components/tools/modal";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import DeleteIcon from "~/src/media/icons/delete.svg";
import AddIcon from "~/src/media/icons/plusCircle.svg";
import DummyAvatar from "~/src/media/svg/dummy_avatar.svg";
import language from "~/src/utils/language";
import { getHousePath } from "~/src/utils/utils";
import { type QuestionViewResource, getPersonsInHouse } from "./form";
import "./form.css";
import FormFooter from "./formFooter";
import FormQuestion from "./formQuestion";

type PersonsAccommodationState = {
    selectedResource: Uuid[];
    currentAnswer: Uuid | undefined;
    creatingNewPerson: boolean;
};

enum QUESTION_TYPE {
    ACCOMMODATIONS = "accommodations",
    PERSONS = "persons",
}

const QuestionPersonsAccommodations: React.FC<QuestionViewResource> = ({
    question,
    setAnswer,
    resources,
    existingAnswer,
}) => {
    const { profile } = useContext(ProfileContext);
    const { locales } = React.useContext(LanguageContext);
    const { COMMON, FORM, ACCOMMODATION } = language[locales];
    const [openModal, setOpenModal] = useState(false);

    const initialState: PersonsAccommodationState = {
        selectedResource: existingAnswer?.[0].data || [],
        currentAnswer: existingAnswer?.[0].answer,
        creatingNewPerson: false,
    };
    const [personsAccommodationState, setPersonsAccommodationState] =
        useState<PersonsAccommodationState>(initialState);

    const onAnswerClick = (answerId: Uuid, id: Uuid) => {
        const selectedResource = id
            ? [...personsAccommodationState.selectedResource, id]
            : personsAccommodationState.selectedResource;
        const stateUpdate = { selectedResource, creatingNewPerson: false, currentAnswer: answerId };
        setPersonsAccommodationState({ ...personsAccommodationState, ...stateUpdate });
    };

    useEffect(() => {
        setPersonsAccommodationState(initialState);
    }, [question, existingAnswer, resources]);

    // This function is only for new accommodation design. If we refactor persons
    // to new design, this function is probably replacing onAnswerClick and handleDelete
    const handleAccommodationClick = (answerId: Uuid, id: Uuid) => {
        const isActive = personsAccommodationState.selectedResource.includes(id);
        if (isActive) {
            const newResourceArr = personsAccommodationState.selectedResource.filter((resource) => {
                return resource !== id;
            });
            const stateUpdate = { selectedResource: newResourceArr };
            setPersonsAccommodationState({ ...personsAccommodationState, ...stateUpdate });
        } else {
            const selectedResource = [...personsAccommodationState.selectedResource, id];
            const stateUpdate = {
                selectedResource,
                creatingNewPerson: false,
                currentAnswer: answerId,
            };
            setPersonsAccommodationState({ ...personsAccommodationState, ...stateUpdate });
        }
    };

    const setCreatingNewPerson = (creatingNewPerson: boolean) => {
        const stateUpdate = { creatingNewPerson };
        setPersonsAccommodationState({ ...personsAccommodationState, ...stateUpdate });
    };

    const newPersonCreated = (person: Uuid | undefined) => {
        const answersArray = Object.entries(question.question.answers);
        const [personsAnswerId] = answersArray.find(([, answer]) => {
            return answer.type === "persons";
        }) || [undefined, undefined];

        if (person && personsAnswerId) {
            onAnswerClick(personsAnswerId, person);
            setOpenModal(false);
        } else {
            setCreatingNewPerson(false);
        }
    };

    const onNextClick = () => {
        if (
            !personsAccommodationState.currentAnswer ||
            personsAccommodationState.selectedResource.length === 0
        ) {
            /* Clicking next button without any chosen resources should
             * set answer to "none"
             */
            const [noneAnswerId] = Object.entries(question.question.answers).find(([, answer]) => {
                return answer.type === "text";
            }) || [undefined, undefined];
            /* This is risky, we ASSUME that the only
             * answer of type text is the "none" answer.
             */
            if (noneAnswerId) {
                setAnswer(question.id, [
                    {
                        answer: noneAnswerId,
                        data: [],
                        timestamp: new Date(),
                    },
                ]);
            }
        } else {
            setAnswer(question.id, [
                {
                    answer: personsAccommodationState.currentAnswer,
                    data: personsAccommodationState.selectedResource,
                    timestamp: new Date(),
                },
            ]);
        }
    };

    const handleDelete = (resourceId: string | undefined) => {
        if (!resourceId) {
            return;
        }
        const newResourcenArr = personsAccommodationState.selectedResource.filter((resource) => {
            return resource !== resourceId;
        });
        const stateUpdate = { selectedResource: newResourcenArr };
        setPersonsAccommodationState({ ...personsAccommodationState, ...stateUpdate });
    };

    // we will probably rename this to checkActive if we also
    // refactor persons to new design
    const checkDisabled = (resourceId: string) => {
        return personsAccommodationState.selectedResource.some((resource) => {
            return resource === resourceId;
        });
    };

    const renderString = () => {
        switch (question.question.type) {
            case QUESTION_TYPE.ACCOMMODATIONS:
                return COMMON.BUTTON_NO_HOUSE;
            default:
                return COMMON.BUTTON_NONE;
        }
    };

    return (
        <>
            <Modal
                isOpen={openModal}
                footerWithButtons={false}
                handleClose={() => {
                    setOpenModal(false);
                }}
            >
                <header style={{ display: "flex", marginBottom: "1rem" }}>
                    <ButtonText
                        size="xs"
                        onClick={() => {
                            setOpenModal(false);
                        }}
                    >
                        {COMMON.BUTTON_CANCEL}
                    </ButtonText>
                </header>
                <NewPerson newPersonCreated={newPersonCreated} />
            </Modal>
            <div className="form-questionPersons-wrapper">
                <FormQuestion person text={question.question.text.sv} />
                {/* only show code if questiontype is not accommodations, 
        probably remove this code if we refactor persons to new design */}
                {question.question.type !== QUESTION_TYPE.ACCOMMODATIONS && (
                    <div className="form-questionPersons-dropWrapper">
                        {personsAccommodationState.selectedResource.length === 0 && (
                            <p>{COMMON.NO_CHOSEN}</p>
                        )}
                        {personsAccommodationState.selectedResource.map((resource) => {
                            const chosenResource =
                                question.question.type === QUESTION_TYPE.PERSONS
                                    ? profile.persons?.[resource]
                                    : profile.houses?.[resource];
                            return (
                                <div className="form-questionPersons-personsWrapper" key={resource}>
                                    {question.question.type === QUESTION_TYPE.PERSONS ? (
                                        <GetAvatar avatarData={chosenResource?.avatar} />
                                    ) : (
                                        <img
                                            className="form-questionPersons-personImg"
                                            src={getHousePath(chosenResource?.avatar)}
                                            alt="avatar"
                                        />
                                    )}
                                    <button
                                        className="questionPersons-person-delete"
                                        type="button"
                                        onClick={() => {
                                            handleDelete(resource);
                                        }}
                                    >
                                        <img src={DeleteIcon} alt="Delete chosen avatar" />
                                    </button>
                                    <p className="questionPersons-person-text">
                                        {chosenResource?.type &&
                                            (question.question.type === QUESTION_TYPE.PERSONS
                                                ? personTypes[chosenResource.type].lang.sv
                                                : getPersonsInHouse(
                                                      language[locales],
                                                      profile,
                                                      chosenResource.id,
                                                  ))}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                )}
                <div className="form-questionPersons-answers">
                    {Object.entries(question.question.answers).map(([answerId, answer]) => {
                        switch (answer.type) {
                            case "text":
                                // Removed button but need to handle the answer alternative
                                return null;
                            case QUESTION_TYPE.ACCOMMODATIONS:
                                return (
                                    <React.Fragment key={answerId}>
                                        <p className="question-acc-subheader">
                                            {FORM.CHOOSE_ACCOMMODATION}
                                        </p>
                                        <div className="question-acc-wrapper">
                                            {Object.entries(resources).map(([id, resource]) => {
                                                // eslint-disable-next-line no-param-reassign
                                                resource.id = id;
                                                return (
                                                    <button
                                                        data-testid="form-answer-alternative"
                                                        className={`question-acc-button ${
                                                            checkDisabled(resource.id)
                                                                ? "acc-button-active"
                                                                : ""
                                                        }`}
                                                        key={id}
                                                        type="button"
                                                        onClick={() => {
                                                            handleAccommodationClick(answerId, id);
                                                        }}
                                                    >
                                                        <img
                                                            src={getHousePath(resource.avatar)}
                                                            alt=""
                                                        />
                                                        <p className="questionPersons-person-text">
                                                            {resource.type &&
                                                                getPersonsInHouse(
                                                                    language[locales],
                                                                    profile,
                                                                    resource.id,
                                                                )}
                                                        </p>
                                                    </button>
                                                );
                                            })}
                                        </div>
                                    </React.Fragment>
                                );
                            case QUESTION_TYPE.PERSONS:
                                return (
                                    <div key={answerId} className="questionPersons-wrapper">
                                        {Object.entries(resources).map(([id, resource]) => {
                                            // eslint-disable-next-line no-param-reassign
                                            resource.id = id;
                                            return (
                                                <div
                                                    className={`questionPersons-button-wrapper
                          ${checkDisabled(id) && "questionPerson-disabled"}`}
                                                    key={id}
                                                >
                                                    <button
                                                        data-testid="form-answer-alternative"
                                                        className="questionPersons-button"
                                                        type="button"
                                                        disabled={checkDisabled(id)}
                                                        onClick={() => {
                                                            onAnswerClick(answerId, id);
                                                        }}
                                                    >
                                                        <GetAvatar avatarData={resource.avatar} />
                                                        <img
                                                            src={AddIcon}
                                                            alt="Add avatar"
                                                            className="questionPersons-person-add-icon"
                                                        />
                                                    </button>
                                                    <p className="questionPersons-person-text">
                                                        {resource.type &&
                                                            personTypes[resource.type].lang.sv}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                        <div className="questionPersons-button-wrapper">
                                            <button
                                                className="questionPersons-button"
                                                type="button"
                                                disabled={false}
                                                onClick={() => {
                                                    setOpenModal(true);
                                                }}
                                            >
                                                <img
                                                    src={DummyAvatar}
                                                    alt=""
                                                    className="form-questionPersons-personImg"
                                                />
                                                <img
                                                    src={AddIcon}
                                                    alt="Add avatar"
                                                    className="questionPersons-person-add-icon"
                                                />
                                            </button>
                                            <p className="questionPersons-person-text">
                                                {ACCOMMODATION.ADD_PERSON}
                                            </p>
                                        </div>
                                    </div>
                                );
                            default:
                                return <span key={answerId}>{FORM.UNKNOWN}</span>;
                        }
                    })}
                </div>
            </div>
            <FormFooter>
                <ButtonPrimary
                    testid="form-done"
                    color={personsAccommodationState.selectedResource.length > 0 ? "blue" : "white"}
                    size="large"
                    onClick={onNextClick}
                >
                    {personsAccommodationState.selectedResource.length > 0
                        ? COMMON.BUTTON_NEXT
                        : renderString()}
                </ButtonPrimary>
            </FormFooter>
        </>
    );
};

export default QuestionPersonsAccommodations;
