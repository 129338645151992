import { GetAvatar } from "@ommej/componente";
import { AvatarGroups, type PersonType, type PersonTypes, personTypes } from "@ommej/metadata";
import type { AvatarId, House } from "@ommej/types";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import AddIcon from "~/src/media/icons/button_add.svg";
import DeleteIcon from "~/src/media/icons/button_delete.svg";
import DummyAvatar from "~/src/media/svg/dummy_avatar.svg";
import type { TPersonObject } from "~/src/types";
import language from "~/src/utils/language";
import { getHousePath } from "~/src/utils/utils";
import ButtonSecondary from "../tools/buttons/buttonSecondary";
import "./accommodation.css";
import Avatar from "./avatar";

type AccommodationProps = {
    handlePersonsClicked: (house: House, persons: TPersonObject[]) => void;
    house: House;
};

type SortedRelationsPerson = { name: string } & PersonType;

type SortedRelationsPersons = {
    [key: string]: SortedRelationsPerson[];
};

const Accommodation = ({ handlePersonsClicked, house }: AccommodationProps) => {
    const { profile } = useContext(ProfileContext);
    const { locales } = useContext(LanguageContext);
    const { COMMON, ACCOMMODATION } = language[locales];
    const [showAvatarPicker, setShowAvatarPicker] = useState(false);
    const [ageGroup, setAgeGroup] = useState<AvatarGroups>(AvatarGroups.CHILD);
    const [type, setType] = useState<keyof PersonTypes | undefined>();
    const [personArray, setPersonArray] = useState<TPersonObject[]>([]);
    const [sortedRelations, setSortedRelations] = useState<SortedRelationsPersons>();

    const sortAvatars = () => {
        const sortPersonType = Object.entries(personTypes).reduce((acc, [key, currentObj]) => {
            if (!acc[currentObj.data.avatarRelation]) {
                acc[currentObj.data.avatarRelation] = [];
            }
            acc[currentObj.data.avatarRelation].push({ name: key, ...currentObj });
            return acc;
        }, {} as SortedRelationsPersons);
        setSortedRelations(sortPersonType);
    };

    useEffect(() => {
        sortAvatars();
    }, []);

    useEffect(() => {
        // set persons if it's an existing accommodation
        if (!house.id) {
            return;
        }
        const personsInAccommodation = profile.housePersonMap[house.id];
        const persons = personsInAccommodation.map((personId) => {
            const personObj: TPersonObject = {
                id: personId,
                avatar: profile.persons[personId].avatar,
                type: profile.persons[personId].type,
                key: (Math.random() + 1).toString(36).substring(4),
            };
            return personObj;
        });
        setPersonArray(persons);
    }, []);

    const handleAvatarClicked = (avatarId: AvatarId) => {
        const personObj: TPersonObject = {
            avatar: avatarId,
            type,
            key: (Math.random() + 1).toString(36).substring(4),
        };
        setPersonArray([...personArray, personObj]);
        setShowAvatarPicker(false);
    };

    const getHouseIndex = () => {
        if (profile.houses) {
            if (house.id) {
                return Object.keys(profile.houses).indexOf(house.id) + 1;
            }
            return Object.keys(profile.houses).length + 1;
        }
        return 1;
    };

    const handleAddPerson = (relation: keyof PersonTypes, relationObject: PersonType) => {
        const { avatarAgeGroups } = relationObject.data;
        setAgeGroup(avatarAgeGroups[0]);
        setType(relation);
        setShowAvatarPicker(true);
    };

    const deletePerson = (key: string | undefined) => {
        if (!key) {
            return;
        }
        const newPersonArr = personArray.filter((person) => {
            return person.key !== key;
        });
        setPersonArray(newPersonArr);
    };

    const sendAccommodation = () => {
        handlePersonsClicked(house, personArray);
    };

    if (!sortedRelations) {
        return null;
    }
    return !showAvatarPicker ? (
        <>
            <h2 className="font1">{ACCOMMODATION.HEADER_TEXT}</h2>

            <div className="accommodation-house-wrapper">
                <div className="accommodation-currentHouse-wrapper">
                    <img src={getHousePath(house.avatar)} alt="A house" />
                    <p className="bodyText14">{`${ACCOMMODATION.HOUSE} ${getHouseIndex()}`}</p>
                </div>
                <div className="accommodation-personlist-wrapper">
                    {Object.values(personArray || []).map((person) => {
                        return (
                            <div
                                className="accommodation-personlist-person-wrapper"
                                key={person.key}
                            >
                                <GetAvatar avatarData={person.avatar} />
                                <button
                                    className="accommodation-person-delete"
                                    type="button"
                                    onClick={() => {
                                        deletePerson(person.key);
                                    }}
                                >
                                    <img src={DeleteIcon} alt="Delete chosen avatar" />
                                </button>
                                <p style={{ fontSize: "10px", marginTop: "0.25rem" }}>
                                    {person.type && personTypes[person.type].lang[locales]}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
            {Object.entries(sortedRelations).map(([relation, personObj]) => {
                return (
                    <div key={relation} className="accommodation-relation-wrapper">
                        <h3>{ACCOMMODATION[relation]}</h3>
                        <div className="accommodation-person-wrapper">
                            {personObj.map((person: SortedRelationsPerson) => {
                                return (
                                    <button
                                        type="button"
                                        key={person.lang[locales]}
                                        onClick={() => {
                                            handleAddPerson(person.name, person);
                                        }}
                                    >
                                        <img
                                            src={DummyAvatar}
                                            alt="avatar"
                                            style={{ marginBottom: "0.25rem" }}
                                        />
                                        <img
                                            src={AddIcon}
                                            alt="Add avatar"
                                            className="accommodation-person-add-icon"
                                        />
                                        {person.lang[locales]}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
            {personArray.length > 0 && (
                <div className="accommodation-button-wrapper">
                    <ButtonSecondary size="medium" onClick={sendAccommodation}>
                        {COMMON.BUTTON_READY}
                    </ButtonSecondary>
                </div>
            )}
        </>
    ) : (
        <Avatar
            handleAvatarClicked={handleAvatarClicked}
            state={{ ageGroup, avatarChildSelection: false }}
        />
    );
};

export default Accommodation;
