/* eslint-disable max-len */
export const supportContent = {
    EMERGENCY: {
        PAGE_HEADER: "Vid akuta ärenden",
        CONTENT: [
            {
                HEADING: "Vid fara - larma!",
                TEXT: 'Om du eller någon nära dig mår akut dåligt eller är utsatt för våld eller hot, <strong class="bold-text white-text">ring direkt 112</strong>',
                BACKGROUND: "var(--color-red-danger)",
            },
        ],
    },
    CATEGORY: {
        PAGE_HEADER: "Prata eller chatta med någon nu",
        SECTIONS: {
            MENTAL_HEALTH: {
                PAGE_HEADER: "Jag eller någon jag känner mår dåligt",
                LINK: "bad",
                CONTENT: [
                    {
                        HEADING: "Mind",
                        TEXT: '<p class="bodyText12">Om du har självmordstankar eller känner någon som har sådana tankar kan du få stöd via chatt eller på telefonnummer 90101. <span class="bold-text">Öppet dygnet runt, varje dag</span>.</p>',
                        URL: "https://mind.se/stod-kunskap/prata-eller-chatta-med-volontar/sjalvmordslinjen/",
                    },
                    {
                        HEADING: "Bris",
                        TEXT: '<p class="bodyText12">Du som mår psykiskt dåligt och är under 18 kan ringa 116 11, maila eller chatta med en kurator på Bris. <span class="bold-text">Öppet dygnet runt, varje dag</span>.</p>',
                        URL: "https://www.bris.se/",
                    },
                    {
                        HEADING: "Jourhavande präst",
                        TEXT: '<p class="bodyText12">Om du mår akut dåligt, har ångest eller känner dig ensam kan du ringa 112 och be att få prata med jourhavande präst. Alla är välkomna att ringa, även om du inte är troende. Du kan också chatta. <span class="bold-text">Telefonlinjen är öppen alla kvällar och nätter kl. 21-06. Chatten är öppen alla kvällar kl. 20-24</span>.</p>',
                        URL: "https://www.svenskakyrkan.se/jourhavandeprast/chatta",
                    },
                    {
                        HEADING: "Jourhavande kompis",
                        TEXT: '<p class="bodyText12">Anonym stödchatt för dig under 25 som vill prata med en annan ung person. <span class="bold-text">Öppet mån-tor kl. 18-21, samt lör-sön kl. 14-17</span>.</p>',
                        URL: "https://www.rkuf.se/fa-hjalp/jourhavande-kompis",
                    },
                    {
                        HEADING: "Tilia",
                        TEXT: '<p class="bodyText12">Här kan du få stöd om du mår dåligt. Du kan chatta eller mejla. <span class="bold-text">Öppet varje kväll kl. 21-22</span>.</p>',
                        URL: "https://www.umo.se/lankbibliotek/tilia/",
                    },
                    {
                        HEADING: "Kyrkans SOS",
                        TEXT: '<p class="bodyText12">Oavsett vilken tro du har kan du alltid ringa till SOS-telefonen 0771-800 650 för att prata med någon. <span class="bold-text">Telefonlinjen är öppen varje dag, året om</span>.</p>',
                        URL: "http://www.kyrkanssos.se",
                    },
                    {
                        HEADING: "Nätvandrarchatten",
                        TEXT: '<p class="bodyText12">För dig mellan 12 och 25 som vill chatta med någon. <span class="bold-text">Chatten är öppen mån, ons, sön kl. 19-22</span>.</p>',
                        URL: "https://kyrkanssos.se/hitta-hjalp/natvandrarchatten/",
                    },
                    {
                        HEADING: "Spec",
                        TEXT: '<p class="bodyText12">Om du känner någon som tagit sitt liv kan du få stöd via chatt eller telefon. Alla som svarar är också efterlevande till någon som tagit sitt liv. Telefonnummer: 020 -18 18 00. <span class="bold-text">Chatten är öppen mån och ons kl. 18:30-20. Telefonlinjen är öppen alla dagar kl. 19-22</span>.</p>',
                        URL: "https://spes.se/chatt/",
                    },
                    {
                        HEADING: "Unga Lukas",
                        TEXT: '<p class="bodyText12">För tjejer och killar ålder 13-25 som vill chatta med en vuxen om tankar, känslor eller något annat som är viktigt för dig. <span class="bold-text">Öppen mån-tor kl. 19-22</span>.</p>',
                        URL: "https://chatt.ungalukas.se/",
                    },
                ],
            },
            VIOLENCE: {
                PAGE_HEADER: "Jag eller någon jag känner är utsatt eller någon gör oss illa",
                LINK: "violence",
                CONTENT: [
                    {
                        HEADING: "Brottsofferjouren",
                        TEXT: '<p class="bodyText12">Om du eller någon du känner har blivit utsatt för brott finns det stöd hos Brottsofferjouren. Du kan ringa 116006, chatta eller boka in ett möte för att få hjälp och rådgivning. <span class="bold-text">Öppet mån-fre kl. 09-19 och helger 09-16. Lunchstängt mellan kl. 12-13</span>.</p>',
                        URL: "https://www.brottsofferjouren.se/brottsofferstod/",
                    },
                    {
                        HEADING: "Ecpat",
                        TEXT: '<p class="bodyText12">Alla som blivit utsatta för sexuella kränkningar, hot och övergrepp, på nätet eller IRL kan få hjälp och råd hos Ecpat. Du kan också få hjälp att ta bort nakenbilder som har spridits på nätet. Du kan ringa 020-112 100, mejla eller chatta. <span class="bold-text">Öppet tis - tor kl. 17-22 samt mån och fre kl. 15-17.</span>.</p>',
                        URL: "https://dittecpat.se/starta-chat/",
                    },
                    {
                        HEADING: "Unga relationer",
                        TEXT: '<p class="bodyText12">En chatt för dig under 20 som mår dåligt i din relation, blivit utsatt för kränkningar, hot eller våld, känner någon som blir utsatt, men också om du själv utsätter någon för det. <span class="bold-text">Chatten är öppen varje kväll kl. 20-22.</span></p>',
                        URL: "https://ungarelationer.se/",
                    },
                    {
                        HEADING: "Maskrosbarn",
                        TEXT: '<p class="bodyText12">Stödchatt för dig som är 13-19 år och har en förälder eller vårdnadshavare som har problem med alkohol eller droger, mår psykiskt dåligt eller utsätter dig för våld. Du kan också boka in ett digitalt samtal med en kurator på den här sidan: <a href="https://www.timecenter.se/maskrosbarn/">https://www.timecenter.se/maskrosbarn/</a>. <span class="bold-text">Öppet mån-tor kl. 20-22 samt sön kl. 20-22.</span></p>',
                        URL: "https://maskrosbarn.org/aktiviteter-stod/for-ungdomar/chatt/",
                    },
                    {
                        HEADING: "Gapf",
                        TEXT: '<p class="bodyText12">Stödtelefon, mail och chatt till för dig som blir utsatt för hedersrelaterat våld och förtryck. Telefonnumret till Gapf är 070-000 93 28. <span class="bold-text">Stödtelefonen är öppet året runt, dygnet runt. Chatten är öppen mån och ons kl. 19-21.</span></p>',
                        URL: "https://gapf.se/kontakta-stodjouren/",
                    },
                ],
            },
            HEALTH: {
                PAGE_HEADER: "Stöd kring kropp och hälsa",
                LINK: "health",
                CONTENT: [
                    {
                        HEADING: "Ätstörningslinjen, Frisk & Fri",
                        TEXT: '<p class="bodyText12">Chatt eller stöd på telefon om du har tankar kring kropp, mat och träning som känns jobbiga. Telefonnummer: 020-20 80 18. <span class="bold-text">Öppet mån-tor kl. 19-20 och ons kl. 13-15. Chatten är öppen mån till tor kl. 20-21.</span></p>',
                        URL: "https://www.umo.se/lankbibliotek/atstorningslinjen-frisk-och-fri/",
                    },
                    {
                        HEADING: "Kärleken är fri",
                        TEXT: '<p class="bodyText12">Stöd på chatt och mejl för dig som har frågor om hedersrelaterat våld eller förtryck, dina rättigheter, kärlek, tvångsäktenskap eller könsstympning. <span class="bold-text">Chatten har öppet sön-tor kl. 19-21.</span></p>',
                        URL: "https://www.raddabarnen.se/rad-och-kunskap/karleken-ar-fri",
                    },
                    {
                        HEADING: "Ellencentret, Stiftelsen 1000 möjligheter",
                        TEXT: '<p class="bodyText12">Stödchatt för dig som har haft sex mot ersättning, blivit såld av andra eller som skadar dig själv genom sex. <span class="bold-text">Chatten är öppen mån 13-15 och tor 17-19. </span></p>',
                        URL: "https://ellencentret.se/fa-stod#chatta",
                    },
                ],
            },
            GENDER: {
                PAGE_HEADER: "Könstillhörighet och sexuell läggning",
                LINK: "gender",
                CONTENT: [
                    {
                        HEADING: "RFSL Stödmottagning",
                        TEXT: '<p class="bodyText12">Stödtelefon där du som är hbtqi-person och har utsatts för trakasserier, hot eller våld kan få stöd och råd. Även du som är närstående eller kompis till en hbtqi-person kan ringa hit och få stöd. <span class="bold-text">Ring: 020-34 13 16. Öppet tor kl. 9-12. </span></p>',
                        URL: "https://www.umo.se/lankbibliotek/rfsl-stodmottagning/",
                    },
                    {
                        HEADING: "RFSU-chatten",
                        TEXT: '<p class="bodyText12">Här kan du chatta om allt som har med kroppen och sexualitet att göra, oavsett vilken könstillhörighet eller sexuell läggning du har. <span class="bold-text">Chatten är öppen mån kl. 18-19. och tor kl. 13-14.</span></p>',
                        URL: "https://www.rfsu.se/sex-och-relationer/for-dig-som-undrar/rfsu-chatten/",
                    },
                    {
                        HEADING: "Regnbågslinjen",
                        TEXT: '<p class="bodyText12">Hit kan du ringa på 0770-55 00 10 och prata med volontärer som själva är HBTQI-personer och utvalda och utbildade för att möta dig där du befinner dig. <span class="bold-text">Öppet tor kl. 16-21.</span></p>',
                        URL: "https://kyrkanssos.se/hitta-hjalp/regnbagslinjen/",
                    },
                ],
            },
            GIRL: {
                PAGE_HEADER: "Jag är tjej och vill chatta med någon",
                LINK: "girl",
                CONTENT: [
                    {
                        HEADING: "Tjejzonen",
                        TEXT: '<p class="bodyText12">Chatt där du kan prata med någon om dina känslor, funderingar och problem. <span class="bold-text">Öppet mån-sön kl. 20-22.</span></p>',
                        URL: "https://www.umo.se/lankbibliotek/tjejzonen/",
                    },
                    {
                        HEADING: "rokstjejjourer.se",
                        TEXT: '<p class="bodyText12">På den här sidan får du olika alternativ på tjejjourer du kan chatta med eller ringa till.</p>',
                        URL: "https://rokstjejjourer.se",
                    },
                    {
                        HEADING: "Freezonen",
                        TEXT: '<p class="bodyText12">Stöd på chatt eller telefon för tjejer och kvinnor som har blivit utsatt för våld eller brott. Telefonnummer: 0733 30 30 28. <span class="bold-text">Stödtelefonen är öppen mån-tor kl. 9-16. Du kan också chatta mån-tor kl. 19-21.</span></p>',
                        URL: "https://www.freezonen.se/st%C3%B6dchatten",
                    },
                ],
            },
            BOY: {
                PAGE_HEADER: "Jag är kille och vill chatta med någon",
                LINK: "boy",
                CONTENT: [
                    {
                        HEADING: "Killar.se",
                        TEXT: '<p class="bodyText12">Stödchatt för dig som är kille och vill prata med någon. <span class="bold-text">Öppet sön-tor kl. 19-21.</span></p>',
                        URL: "https://killar.se/",
                    },
                    {
                        HEADING: "Under Kevlaret",
                        TEXT: '<p class="bodyText12">Hit kan du som är kille vända dig för att prata med och få stöd. I chatten svarar bara killar. <span class="bold-text">Öppet sön-tor kl. 20-22.</span></p>',
                        URL: "https://www.underkevlaret.se/chatt",
                    },
                    {
                        HEADING: "Preventell",
                        TEXT: '<p class="bodyText12">Telefonlinje för dig som upplever att du tappat kontrollen över din sexualitet och som kanske känner oro för tankar och handlingar. Prata anonymt med någon som har stor erfarenhet av liknande frågor. Ring: 020-66 77 88. <span class="bold-text">Öppet vardagar kl. 12-15.</span></p>',
                        URL: "http://preventell.se/",
                    },
                ],
            },
            BULLYING: {
                PAGE_HEADER: "Jag blir utfrusen eller mobbad",
                LINK: "bullied",
                CONTENT: [
                    {
                        HEADING: "Friends rådgivning",
                        TEXT: '<p class="bodyText12">Hit kan du ringa för att få råd och stöd om du upplever mobbning eller utsatthet på nätet eller IRL. Telefonnummer: 08-545 519 90. <span class="bold-text">Öppet mån-fre kl. 9-12.</span></p>',
                        URL: "https://friends.se/kunskapsbanken/barn-och-unga/rad-och-stod/",
                    },
                ],
            },
            CRIMINAL: {
                PAGE_HEADER: "Jag eller någon jag känner gör kriminella saker",
                LINK: "criminal",
                CONTENT: [
                    {
                        HEADING: "Fryshuset",
                        TEXT: '<p class="bodyText12">Hit kan du höra av dig om du vill lämna ett kriminellt gäng. Ring 08-691 76 00 eller mejla infostockholm@fryshuset.se. <span class="bold-text">Öppet vardagar 08.00-21.45 samt helger 10.00-20.45.</span></p>',
                        URL: "https://fryshuset.se/verksamhet/passus/2540",
                    },
                    {
                        HEADING: "Bufff",
                        TEXT: '<p class="bodyText12">Här finns stöd på telefon och chatt dit du kan vända dig om någon i din familj sitter i fängelse. Ring: 020-200 330. <span class="bold-text">Telefonlinjen är öppen vardagar kl. 9-17. Chatten har öppet tis, tor och sön kl. 19-21.</span></p>',
                        URL: "https://bufff.se/",
                    },
                    {
                        HEADING: "Välj att sluta",
                        TEXT: '<p class="bodyText12">Här kan du få stöd anonymt om du riskerar att skada den du älskar och vill få hjälp att förändra ett kontrollerande eller våldsamt beteende. Även den som är utsatt för våld eller kontroll kan ringa hit. Ring 020-555 666. <span class="bold-text">Öppet mån-fre 8.30-16.</span></p>',
                        URL: "https://valjattsluta.se/",
                    },
                ],
            },
            ADDICTION: {
                PAGE_HEADER: "Jag eller någon jag känner har ett beroende",
                LINK: "addiction",
                CONTENT: [
                    {
                        HEADING: "Stödlinjen",
                        TEXT: '<p class="bodyText12">Är du orolig över dina eller någon annans spelvanor? Hit kan du ringa anonymt eller chatta. Telefonnummer: 020-81 91 00. <span class="bold-text">Öppet vardagar kl. 9-17, samt till kl. 21 mån och tor. </span></p>',
                        URL: "https://stodlinjen.se/",
                    },
                    {
                        HEADING: "Trygga Barnen",
                        TEXT: '<p class="bodyText12">Om någon i din familj eller närhet har problem med alkohol eller droger kan du får stöd på telefon eller på Snapchat. Telefonnummer: 070-47 77 910. <span class="bold-text">Öppet alla dagar på dagtid. Chatten på Snapchat har öppet mån-fre kl. 9-17 och 19-23, lör-sön kl. 11-23. Användarnamn: tryggabarnen.</span></p>',
                        URL: "https://tryggabarnen.org/",
                    },
                    {
                        HEADING: "Maria Ungdom",
                        TEXT: '<p class="bodyText12">Hjälper dig som är ung och som har problem med alkohol eller andra droger. Du kan få personlig rådgivning på telefonnummer 08-123 474 10. <span class="bold-text">Öppet helgfria vardagar, kl. 9-12.</span></p>',
                        URL: "https://www.mariaungdom.se/kontakta-oss/radgivning/",
                    },
                ],
            },
            LANGUAGE: {
                PAGE_HEADER: "Jag vill ha stöd på andra språk ",
                LINK: "language",
                CONTENT: [
                    {
                        HEADING: "Terrafems jourtelefon",
                        TEXT: '<p class="bodyText12">På 020-52 10 10 kan du få stöd och råd på olika språk om du har blivit utsatt för våld. Samtalet syns inte på din telefonräkning och numret registreras inte. Vi behärskar tillsammans 74 språk. <span class="bold-text">Öppet vardagar kl. 9-18.</span></p>',
                        URL: "https://terrafem.org/vi-stodjer-dig/",
                    },
                    {
                        HEADING: "Boujt – Barn- och ungdomsjour på teckenspråk ",
                        TEXT: '<p class="bodyText12">Här kan du få stöd kring allt möjligt på svenskt teckenspråk. Du kan chatta, ställa frågor till frågelådan eller mejla. Chatten funkar bäst på webbläsare som inte är för gamla. <span class="bold-text">Chatten är öppen, mån, tis och tor kl. 18.30-20.30.</span></p>',
                        URL: "https://www.boujt.se/chatten",
                    },
                    {
                        HEADING: "Somaya kvinno- och tjejjour",
                        TEXT: '<p class="bodyText12">Stöd på olika språk för tjejer som är utsatta för hot, våld eller förtryck. Ring 020-81 82 83, chatta eller mejla. <span class="bold-text">Telefonlinjen är öppen vardagar kl. 9-16.</span></p>',
                        URL: "https://www.somaya.se/",
                    },
                ],
            },
        },
    },
    INFORMATION: {
        PAGE_HEADER: "Hitta information eller föreningar",
        SECTIONS: {
            RIGHTS: {
                PAGE_HEADER: "Mina rättigheter om jag far illa",
                LINK: "rights",
                CONTENT: [
                    {
                        HEADING: "Unga relationer",
                        TEXT: '<p class="bodyText12">Här finns information om psykiskt och fysiskt våld i nära relationer för dig som är ung.</p>',
                        URL: "https://ungarelationer.se/category/om-vald/",
                    },
                    {
                        HEADING: "Friends",
                        TEXT: '<p class="bodyText12">Här kan du få mer information om mobbning och utfrysning både på nätet och IRL.</p>',
                        URL: "https://friends.se/kunskapsbanken/",
                    },
                    {
                        HEADING: "Beo skolinspektionen",
                        TEXT: '<p class="bodyText12">Skolinspektionens barn- och elevombudet informerar dig om dina rättigheter om du far illa i skolan. Hit kan du höra av dig om du blir mobbad och inte får tillräckligt mycket hjälp av skolan. </p>',
                        URL: "https://beo.skolinspektionen.se/for-barn-och-elever/",
                    },
                ],
            },
            MENTAL_HEALTH: {
                PAGE_HEADER: "Dåligt mående, sjukdom och sjukvård",
                LINK: "illness",
                CONTENT: [
                    {
                        HEADING: "Bup",
                        TEXT: '<p class="bodyText12">Hit kan du skicka in frågor och få stöd om du mår psykiskt dåligt.</p>',
                        URL: "https://www.umo.se/lankbibliotek/fraga-bup.se/",
                    },
                    {
                        HEADING: "Nära cancer",
                        TEXT: '<p class="bodyText12">Här kan du få stöd och information om någon du känner drabbats av cancer.</p>',
                        URL: "https://www.naracancer.se/prata-med-nagon/",
                    },
                    {
                        HEADING: "Ätstörning.se",
                        TEXT: '<p class="bodyText12">Fakta om olika typer av ätstörningar.</p>',
                        URL: "https://atstorning.se/",
                    },
                ],
            },
        },
    },
};
