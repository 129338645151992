import EN from "./en.json";
import SE from "./sv.json";

export interface ILanguage {
    [translationGroup: string]: {
        [translationKey: string]: string;
    };
}
const language = {
    en: EN as ILanguage,
    sv: SE as ILanguage,
};

export default language;
