{
    "COMMON": {
        "OMMEJ": "Ommej",
        "WHAT_IS_OMMEJ": "Vad är Ommej?",
        "BUTTON_CREATE": "Create Account",
        "BUTTON_LOGIN": "Log in",
        "BUTTON_READY": "Done",
        "BUTTON_DONE": "Done",
        "BUTTON_CLICK": "Press",
        "BUTTON_CHANGE": "Change",
        "BUTTON_ALL": "See all",
        "BUTTON_NEXT": "Next",
        "BUTTON_NONE": "None",
        "BUTTON_NO_HOUSE": "No accommodation",
        "BUTTON_CONTINUE": "Continue",
        "BUTTON_CONTINUE_ANSWER": "Continue answering",
        "BUTTON_SITE": "Go to site",
        "BUTTON_ADD": "Add",
        "BUTTON_PREVIEW": "Preview",
        "BUTTON_SEND": "Send",
        "BUTTON_SHARE": "Share",
        "BUTTON_COPY": "Copy link",
        "BUTTON_MYSTORY": "My story",
        "BUTTON_SUPPORT": "Get support",
        "BUTTON_JOIN": "Join",
        "BUTTON_CANCEL": "Cancel",
        "BUTTON_JUMP": "Cancel",
        "BUTTON_ACCEPT": "Accept",
        "BUTTON_DECLINE": "Decline",
        "BUTTON_GET": "Get",
        "BUTTON_OK": "Okej",
        "INPUT_MOBILE": "Mobile number",
        "INPUT_EMAIL": "Email address",
        "INPUT_USERNAME": "Username",
        "INPUT_PASSWORD": "Password",
        "INPUT_CREATING_PASSWORD": "Password (at least 6 characters)",
        "INPUT_KEY": "Invite key",
        "VIDEO": "Sorry, your browser does not support embedded videos.",
        "YES": "Yes",
        "NO": "No",
        "OK": "OK",
        "VIDEO_OMMEJ": "What is Ommej?",
        "VIDEO_CONVENTION": "Convention on the Rights of the Child",
        "CHANGE_AVATAR": "Change avatar",
        "CHANGE_BIRTHYEAR": "Change birthyear",
        "CHANGE_GENDER": "Change gender",
        "AVATAR": "Avatar",
        "LOGOUT": "Log out",
        "OMMEJ_LOGO": "Ommejs logotyp",
        "NO_CHOSEN": "Not chosen anything",
        "SAVED": "Saved",
        "OFFLINE": "Your network seems to be offline. Then this app is not working properly. As soon as your network is back you it's possible to use the app again.",
        "READ_MORE": "Read more",
        "APPROVE": "I approve",
        "TERMS": "Terms",
        "SHOW_MORE": "Show more",
        "SHOW_MORE_ITEMS": "Show more",
        "SHOW_LESS": "Show less",
        "LOADING": "Loading",
        "CHOOSE": "Choose",
        "INACTIVE_ANSWERS": "Previous answers",
        "INACTIVE_ANSWERS_INFO": "Here are some of your old answers that we are no longer sure about. For example it could be because you went back and changed an answer. Keep answering questions and eventually this will correct itself.",
        "CODE": "Code",
        "INVALID_INVITATION": "You have received an invalid invitation. Please contact the person who sent it to get a new one.",
        "INVALID_INVITATION_FULL": "Denna inbjudan har redan accepterats, antingen av dig eller någon annan. Kontakta den som bjudit in dig om något verkar fel.",
        "ARE_YOU_SURE": "Are you sure?",
        "BACK": "Tillbaka",
        "OR": "Eller",
        "SETTINGS": "Settings",
        "ALLOW_NOTIFICATIONS": "Allow notifications",
        "MOODCHECK": "Mood check",
        "NOTIFICATION_SYS_SETTINGS_1": "För att få notifieringar behöver du tillåta det i systeminställningarna.",
        "NOTIFICATION_SYS_SETTINGS_2": "Tryck här för att öppna inställningar.",
        "REMOVE": "Remove",
        "DAYS": "Days",
        "DAY": "Day",
        "LOGIN_WITH": "Login using"
    },
    "PROMPT": {
        "TEXT": "Ommej",
        "SUB_TEXT": "Share your world with others"
    },
    "ERROR": {
        "CREDENTIALS": "Wrong username or password",
        "CREDENTIALS_EXTRA": "Wrong username or password. If you forgot your password you can reset it",
        "HERE": "here",
        "AUTH": "You are not authorized",
        "SERVER": "We could not reach our server",
        "GENERIC": "Sorry, something went wrong",
        "GENERIC_CREATE": "Sorry, something went wrong. Maybe you already have an account?",
        "PROFILE": "Something went wrong when we tried to update your profile",
        "UPDATE_AVATAR": "Something went wrong when we tried to update the avatar",
        "UPDATE_ACCOMMODATION": "Something went wrong when we tried to update the accommodation"
    },
    "ERROR_COMPONENT": {
        "HEADING": "Oops!",
        "SUB_HEADING": "Something went wrong. We are working on resolving the problem!",
        "IMG_TEXT": "People fixing an error"
    },
    "START": {
        "HEADING": "Welcome to",
        "SUBHEADER": "Logga in eller skapa ett nytt konto för att berätta din historia.",
        "ACCOUNT": "Don't you have an account?",
        "CREATE": "Create one here",
        "OMMEJ_VIDEO": "Watch Ommej video"
    },
    "LOGIN": {
        "HEADING_RESET": "Reset password",
        "HEADER_TEXT": "Log in",
        "PASSWORD_AGAIN": "Repeat password",
        "CHECK_EMAIL": "If we found an account using the email address you will receive an email. Check your inbox to get help how you reset your password.",
        "CHECK_SMS": "If we found an account using the phone number you will receive a text message. Check your sms inbox to get help on how you reset your password.",
        "ERROR_PASSWORD": "Passwords are invaid or do not match, please try again",
        "FORGOT_PASSWORD": "Forgot password?"
    },
    "RESET_USERNAME": {
        "EXPLAIN": "To be able to continue you need to enter your e-mail address or mobile phone number. This will be your new username."
    },
    "MIGRATION": {
        "LOGIN_OLD": "Välkommen tillbaka till Ommej! Vi har gjort om appen så att den blir bättre, snyggare och roligare för dig. Logga in som vanligt så får du se!",
        "HEADER": "Viktigt meddelande!",
        "TEXT1": "Vi har gjort lite uppdateringar så att appen ska bli ännu bättre för dig.",
        "TEXT2": "Detta kan innebära att du får frågor som liknar något du redan har svarat på tidigare. Hoppas du vill svara igen!"
    },
    "ACCOUNT": {
        "HEADER_TEXT": "Create Account",
        "HEADING": "Welcome to Ommej",
        "SUB_HEADING": "Start by choosing a name and a password to your Me"
    },
    "HOME": {
        "ANSWER_QUESTIONS": "Answer questions",
        "MY_STORY": "Min berättelse",
        "MY_INVITATIONS": "Contacts",
        "MY_PROFILE": "Min profil",
        "MY_PROFILE_MAKE_READY": "Complete profile",
        "SUPPORT": "Få stöd",
        "REQUESTS_HEADER": "Frågor som skickats till dig",
        "MODAL_MOOD_HEADER": "Bra jobbat!",
        "MODAL_MOOD_TEXT1": "Du har loggat ditt mood",
        "MODAL_MOOD_IN_A_ROW": "i rad",
        "MODAL_MOOD_TEXT2": "Vill du fortsätta berätta om ditt liv?",
        "MODAL_MOOD_TEXT2_NO_PROFILE": "Färdigställ gärna din profil så vi kan börja med frågorna!",
        "BUTTON_YES": "Fortsätt berätta",
        "BUTTON_CANCEL": "Inte nu",
        "MOOD_TODAY": "Mood of today",
        "MOODSTREAK": "Moodstreak"
    },
    "MOOD": {
        "HEADER": "My mood today is",
        "SUBHEADER": "this is how i feel too...",
        "API_ERROR": "Något gick fel när vi försökte spara ditt mood!",
        "WRAPPER_HEADER": "Mina moods",
        "WRAPPER_API": "Något gick fel när vi skulle ladda dina moods",
        "WRAPPER_TEXT": "Dina moods visar ditt humör över tid. Här kan du se hur ditt humör har svängt upp och ner och kanske till och med förstå varför du varit extra ledsen eller glad.",
        "EMPTY": "Du har inga moods registrerade. Fyll i ditt första mood i hemvyn."
    },
    "SEE_ANSWERS": {
        "HEADER": "Can I see your answers?",
        "TEXT": " want to connect with you and see your answers in the Ommej app",
        "ADMIN": "a municipality administrator"
    },
    "AVATAR": {
        "HEADER_TEXT": "Create Account",
        "CHOOSE": "Choose your Me",
        "CREATE_OWN": "Create your own",
        "EDIT": "Change your avatar"
    },
    "BIRTHYEAR": {
        "HEADER_TEXT": "Create Account",
        "HEADING": "Which year ",
        "HEADING_BORN": "were you born?",
        "SUBHEADER": "Enter your birth year (4 digits)."
    },
    "GENDER": {
        "FEMALE": "Girl",
        "MALE": "Boy",
        "NEUTRAL": "Non-binary",
        "HEADER_TEXT": "Create Account",
        "HEADING": "I am:",
        "VIDEO_TEXT": "Watch a video about gender identity"
    },
    "HOUSES": {
        "HEADER_TEXT": "Create Account",
        "HEADER": "I'm living here",
        "SUBHEADER": "You can choose more than one accommodation, start with one",
        "SUBHEADER_HOUSEEXISTS": "Where does yours live ",
        "HOUSE": "Accommodation",
        "DETACHED_HOUSE": "Detached house",
        "APARTMENT": "Apartment",
        "ROW_HOUSE": "Row house",
        "OTHER_HOUSE": "Other",
        "BUTTON_NO": "Doesn't live with me"
    },
    "ACCOMMODATION": {
        "HEADER_TEXT": "I'm living here with",
        "HOUSE": "Accommodation",
        "ADD_PERSON": "Add a person",
        "FAMILY": "Family",
        "EXTENDED_FAMILY": "Extended family",
        "RELATIVE": "Relatives",
        "RELATIONS": "Relations",
        "OTHERS": "Others"
    },
    "MOREACCOMMODATION": {
        "HEADER_TEXT": "Do you live elsewhere?"
    },
    "GREAT": {
        "HEADING": "Good work!",
        "SUB_HEADING": "Your Mej is done. Are you ready to start answering some questions?",
        "IMG_TEXT": "Children celebrates with confetti"
    },
    "AVATAR_PICKER": {
        "HEADING": "Choose Avatar"
    },
    "BIRTHYEAR_PICKER": {
        "HEADING": "Select year of birth"
    },
    "GENDER_PICKER": {
        "HEADING": "Choose your gender"
    },
    "HOUSE_PICKER": {
        "HEADING": "Pick a new house"
    },
    "ACCOMMODATION_PICKER": {
        "ADD_HOUSE": "Add an accommodation",
        "ADD_PERSONS": "Pick who you live with",
        "PERSON_CREATED": "Person created",
        "CHANGED": "Boendet ändrat",
        "CREATED": "Boende tillagt"
    },
    "CHANGEPERSON_PICKER": {
        "HEADING": "Change person"
    },
    "MY_PROFILE": {
        "HEADER": "My profile",
        "BORN": "I was born in",
        "GENDER": "My gender is",
        "NO_GENDER": "You haven't picked a gender",
        "DELETE_PERSON": "Delete person",
        "LIVING": "I live here",
        "PERSONS_LIVING": "Persons living here",
        "ACCOMMODATION": "Accommodation",
        "ADD_ACCOMMODATION": "Add an accommodation",
        "PERSONS": "Other persons",
        "DELETE_ACCOUNT": "Delete my account"
    },
    "DELETE_ACCOUNT": {
        "HEADER": "Delete account",
        "HEADING_SURE": "Är du säker på att du vill radera ditt konto?",
        "HEADING_KEEP_ANSWER": "Får vi behålla dina svar?",
        "HEADING_DELETED": "Ditt konto är raderat!",
        "TEXT_SURE": "Du kan inte ångra dig senare. När du tagit bort ditt konto behöver du skapa ett nytt konto för att kunna använda Ommej igen.",
        "TEXT_KEEP_ANSWER": "Ommej vill gärna använda dina svar så att vi kan hjälpa fler barn, är det OK? Svaren kan inte kopplas till dig på något sätt.",
        "BUTTON_DELETE": "Ja, fortsätt",
        "BUTTON_KEEP": "Det går bra!",
        "BUTTON_ERASE": "Nej, ta bort allt"
    },
    "FORM": {
        "UNKNOWN": "Something went wrong with the question",
        "MULTIPLE": "(You can select multiple answers)",
        "CHOOSE_ACCOMMODATION": "(Choose suitable accommodations)",
        "LIVES_WITH": "Here I live with",
        "LAST_ANSWER_DATE": "You answered this question",
        "AGREE": "Agree",
        "DISAGREE": "Disagree",
        "TO_QUESTIONS": "Till frågorna",
        "HEADER": "Questions from"
    },
    "SUPPORT": {
        "HEADER": "Get support",
        "SOS": "Call SOS",
        "STORY": "Share my story with someone I know",
        "CONTACT": "Vi gör vårt bästa för att se till att alla öppettider i listan stämmer men ibland kan något ändras innan vi hinner märka det. Om du vill får du gärna meddela oss om du finner ett fel:"
    },
    "BEB": {
        "HEADER": "My story",
        "NO_DATA": "Your story will be shown here when you have answered some questions.",
        "I_AM": "I am",
        "YEAR": "year",
        "BORN": "I was born",
        "GENDER": "My gender is",
        "GOOD_THINGS": "Good things in my life",
        "IMPORTANT_THINGS": "This is important to me",
        "ANSWER_PERSON": "Answers about persons",
        "PERSON_IMG": "persons in your life",
        "ANSWER": "Answer",
        "MYSELF": "Myself",
        "SCHOOL": "School",
        "FAMILY": "Family",
        "LEISURE": "Leisure",
        "HOUSES": "About accommodation",
        "BASE": "Other questions and answers",
        "TAG_MYSELF": "About me and how I feel",
        "TAG_SCHOOL": "What I think about school",
        "TAG_FAMILY": "About my family",
        "TAG_LEISURE": "About my leisure",
        "TAG_BASE": "Other questions and answers",
        "CHANGE_QUESTION": "Change answer for question",
        "REPLACE": "This or that ",
        "GOOD_HEADER": "Good things in my life",
        "GOOD_IMG": "People celebrating",
        "GOOD_NOTHING": "Nothing here yet, but it will come.",
        "GOOD_TEXT": "<p>Även om inte livet alltid är lätt finns det flera saker som är bra i ditt liv och som är viktiga för att du ska ha det bra.</p><br /><p class=\"bold-text\">Här är några bra och viktiga saker som du berättat om!</p>",
        "IMPORTANT_BUTTON": "To support page",
        "IMPORTANT_HEADER": "Important to me",
        "IMPORTANT_IMG": "Girl who is worried",
        "IMPORTANT_NOTHING": "We have not found anything you are worried about. That's good!",
        "IMPORTANT_TEXT": "<p>Här nedan står alla saker som du har sagt att du är orolig för eller vill ha hjälp med.</p><br /><p class=\"bold-text\">I Få stöd hittar du massor av tips och stöd om vart du kan vända dig.</p>",
        "PERSONS_HEADER": "About persons in your life",
        "PERSONS_IMG": "Avatar image",
        "HOUSES_HEADER": "About accommodation",
        "HOUSES_ACCOMMODATION": "Accommodation",
        "BUTTON_SHARE": "Share my story",
        "SHARE_TRUST": "Do you want to share your story with someone you know and trust?",
        "SHARE_TELL": "Notice that people you share your story with, don't see your name. So it could be good to tell them when you have sent your story.",
        "SHARE_IMG": "People sending mail",
        "START_OVER_HEADER": "Vill du börja om?",
        "START_OVER_BUTTON": "Börja om",
        "START_OVER_TEXT_1": "Du kan välja att rensa alla dina svar för att bygga din berättelse från början igen.",
        "START_OVER_TEXT_2": "Dina personer, boenden och avatarer försvinner inte.",
        "START_OVER_CONFIRM_TEXT": "Du kan inte ångra dig senare. Alla dina svar rensas och du får börja om från början igen.",
        "START_OVER_DONE": "Dina svar är rensade!",
        "FORMS_ANSWER_HEADER": "Övriga svar",
        "FORMS_ANSWER_INFO": "Här visas de svar som dina kontakter har bett om, dessa kan du inte ändra eller ta bort"
    },
    "SHARED_BEB": {
        "HEADER": "Share my story",
        "SHARE_HEADING": "Tell your story",
        "SHARE_TEXT1": "Här kan du dela din berättelse med någon du känner och litar på.",
        "SHARE_TEXT2": "Tänk på att den du delar med inte får se ditt namn, så det kan vara bra att berätta för dem när du skickat.",
        "SHARE_TEXT3": "Nedanför ser du en förhandsvisning av hur din berättelse kommer se ut för den du skickar till. Om allt är OK, tryck på knappen nedan.",
        "SHARE_COPY": "Dela den här länken med någon du vill ska se dina svar.",
        "HELP_HEADING": "I want help with this",
        "WORRIED_HEADING": "I am worried for this",
        "PROBLEM_HEADING": "I am experiencing this",
        "PERSONS_HEADING": "These persons is in my life",
        "ACCOMMODATION_LIVE": "Persons living here",
        "ACCOMMODATION_NO": "No one lives here",
        "OMMEJ_HEADING": "What I think about Ommej",
        "PARENT_HEADING": "Ett barn vill berätta något för dig!",
        "PARENT_INGRESS": "Nedan ser du en sammanställning av vad ett barn har berättat om i Ommej att hen är orolig för eller vill ha hjälp med i sitt liv, och vad hen sagt är \"lugnt\". Barnet har valt att dela den här information med dig eftersom du är en viktig person i deras liv.",
        "PARENT_TEXT1": "Ommej är en app där barn och unga kan berätta om hur de mår, i alla olika delar av sina liv, få information och lotsas till stöd och hjälp. Svaren du ser reflekterar barnets upplevelse just nu. Om barnet uppdaterar svaren i Ommej-appen kommer sidan att uppdateras.",
        "PARENT_TEXT2": "I en kommande app för vuxna kommer du som valts ut av barnet att kunna se exakt vad barnet har svarat på varje enskild fråga i Ommej - om barnet så önskar. Där kommer också att finnas råd och information samt tips på sätt att stötta barnet och mycket mer. Är du nyfiken på appen för föräldrar, vårdnadshavare och andra viktiga vuxna? ",
        "PARENT_LINK1": "Skriv upp dig här ",
        "PARENT_TEXT3": "för att bli informerad om när den släpps eller  få vara med och förhandstesta appen och tycka till. Det du gör kan hjälpa många barn.",
        "PARENT_TEXT4": "Kommer du från en verksamhet som arbetar med barn? För dig finns det en admin-app som kan kopplas samman med barnappen och som möjliggör större delaktighet för barnen, systematisk uppföljning, kvalitetssäkring och sömlös samverkan inom och mellan verksamheter, bland mycket annat. ",
        "PARENT_LINK2": "Kontakta oss här",
        "PARENT_TEXT5": "för en gratis demo.",
        "SMS_TEXT": "Congratulations! You are an important person to a child who wants to send their story via the app Ommej."
    },
    "REQUEST_FORM": {
        "HEADER": "Cleared forms"
    },
    "ALLQUESTION": {
        "HEADING": "Great work!",
        "SUBHEADING": "Now you have answered all questions. You could be really proud of yourself!",
        "IMG": "People celebrating",
        "CHANGE_ANSWER": "If you want to change or share your answer with somebody, you could do it here:",
        "SUPPORT1": "Don't forget, you can always",
        "SUPPORT2": "get support"
    },
    "REQUESTS": {
        "HEADER": "Contacts",
        "ACCEPTED": "Kan se mina svar",
        "PENDING": "Vill se mina svar",
        "ACCEPT": "Godkänn",
        "DECLINE": "Avböj",
        "CREATED": "Skapad",
        "NO_INVITATIONS": "Inga förfrågningar",
        "PAUSED": "Den här inbjudan är för tillfället pausad och din handläggare kollar just nu inte dina svar.",
        "CLOSED": "Den här inbjudan är stängd och handläggaren kollar inte längre på svaren."
    },
    "CODE": {
        "HEADER": "Add your code",
        "HEADER_NORMAL": "Have you got a code?",
        "SUBHEADER_NORMAL": "Enter your code to accept the invitation.",
        "HEADER_SUCCESS": "Great!",
        "SUBHEADER_SUCCESS": "You are now connected to",
        "HEADER_FAILURE": "Whops!",
        "SUBHEADER_FAILURE": "Sorry, we didn't find any invitation with the code you specified! Please, try again or contact the person who invited you!",
        "INPUT_CODE": "Add your code"
    },
    "AVATAR_BUILDER": {
        "HEADER": "Create my mej",
        "HAIR": "Choose hairstyle and color",
        "CLOTHES": "Choose clothes and color",
        "HEADS": "Choose head type and skin color",
        "EYES": "Choose eyes",
        "MOUTHS": "Choose a mouth",
        "NOSES": "Choose a nose",
        "BEARDS": "Choose beard and color",
        "EYEWEARS": "Choose eyewears",
        "HEADWEARS": "Choose headwears",
        "ACCESSORIES": "Choose accessories"
    },
    "AVATAR_BUILDER_TABS": {
        "HAIR": "Hair",
        "CLOTHES": "Clothes",
        "HEADS": "HEADS",
        "EYES": "EYES",
        "MOUTHS": "MOUTHS",
        "NOSES": "NOSES",
        "BEARDS": "BEARDS",
        "EYEWEARS": "EYEWEARS",
        "HEADWEARS": "HEADWEARS",
        "ACCESSORIES": "ACCESSORIES"
    },
    "FORM_TAGS": {
        "BB_CARE": "Care",
        "BB_SAFETY": "Safety",
        "BB_FEEL_GOOD": "Feel good",
        "BB_LEISURE": "Leisure",
        "BB_CONTEXT": "Context",
        "BB_RESPONSIBLE": "Responsible",
        "BB_RESPECT": "Respect",
        "BB_DEVELOPING": "Developing"
    }
}
