import { GetAvatar } from "@ommej/componente";
import { genderTypes, personTypes } from "@ommej/metadata";
import { getQuestion } from "@ommej/pregunta";
import type { BebMentions, BebModel, BebObject, Form, Uuid } from "@ommej/types";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Confirmation from "~/src/components/confirmation";
import Done from "~/src/components/sprinkles/done";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import ErrorComponent from "~/src/components/tools/errorComponent/errorComponent";
import Spinner from "~/src/components/tools/spinner/spinner";
import { AuthContext } from "~/src/contexts/authContext";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import ArrowWhiteIcon from "~/src/media/icons/arrow_white.svg";
import ChatMarkIcon from "~/src/media/icons/chatMark.svg";
import DiagramIcon from "~/src/media/icons/diagram.svg";
import RefreshIcon from "~/src/media/icons/refresh_white.svg";
import ArrowRightIcon from "~/src/media/svg/arrowRight.svg";
import { isDemo, request } from "~/src/utils/api";
import language from "~/src/utils/language";
import { getFormName, getPersonalQuestion, track } from "~/src/utils/utils";
import Header from "../header/header";
import ButtonSecondary from "../tools/buttons/buttonSecondary";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./beb.css";
import Category from "./category";
import Houses from "./houses";
import Important from "./important";
import Persons from "./persons";

type BebProps = {
    formId?: string;
    formVersion?: string;
};

type TQuestionTags = {
    MYSELF: string;
    SCHOOL: string;
    FAMILY: string;
    LEISURE: string;
    BASE: string;
};

enum START_OVER_FLOW {
    DISABLED = 0,
    CONFIRMATION = 1,
    FAILED = 2,
    DONE = 3,
}

const Beb = ({ formId, formVersion }: BebProps) => {
    const { user } = useContext(AuthContext);
    const { profile } = useContext(ProfileContext);
    const { locales } = useContext(LanguageContext);
    const { BEB, COMMON } = language[locales];
    const [form, setForm] = useState<Form.FormData>();
    const [formMetaData, setFormMetaData] = useState<Form.Metadata[]>();
    const [bebData, setBebData] = useState<BebModel>();
    const [bebDataInactive, setBebDataInactive] = useState<BebModel>();
    const [loadDone, setLoadDone] = useState<boolean>(false);
    const [startOverStep, setStartOverStep] = useState<START_OVER_FLOW>(START_OVER_FLOW.DISABLED);
    const [haveOtherForms, setHaveOtherForms] = useState(false);
    const params = useParams();
    const [currentView, setCurrentView] = useState<string | undefined>(params.what);
    const navigate = useNavigate();

    const categoryArray: [Partial<Form.QuestionTags>, string][] = [
        ["MYSELF", BEB.TAG_MYSELF],
        ["SCHOOL", BEB.TAG_SCHOOL],
        ["FAMILY", BEB.TAG_FAMILY],
        ["LEISURE", BEB.TAG_LEISURE],
        ["BASE", BEB.TAG_BASE],
    ];

    useEffect(() => {
        setCurrentView(params.what);
    }, [params]);

    async function getBebData() {
        try {
            const res = await request(`clients/beb/${formId}/${formVersion}`, "GET");
            if (res.status === 200) {
                const resData = await res.json();
                if (resData && Object.keys(resData).length > 0) {
                    setBebData(resData.active || resData);
                    if (resData.inactive && Object.keys(resData.inactive).length > 0) {
                        setBebDataInactive(resData.inactive);
                    }
                }
            }
        } catch (err) {
            console.error(err);
            // OK, probably no answers in backend yet
        }
    }

    async function getForm() {
        try {
            const res = await request(`forms/${formId}/${formVersion}`, "GET");
            const resForm = await res.json();
            setForm(resForm);
        } catch (_err) {
            console.error("Failed to get form");
        }
    }

    async function getFormsMetadata() {
        try {
            const res = await request("forms/metadata", "GET");
            const resForm = await res.json();
            setFormMetaData(resForm);
        } catch (_err) {
            console.error("Failed to get form");
        }
    }

    useEffect(() => {
        async function load() {
            await Promise.all([getBebData(), getForm(), getFormsMetadata()]);
            setLoadDone(true);

            setHaveOtherForms(
                !!user?.invitations?.accepted.some((invitation) => {
                    if (!invitation.forms) {
                        return false;
                    }
                    return invitation.forms.some((inv) => {
                        return inv.done;
                    });
                }),
            );
        }
        load();
        return () => {
            setLoadDone(false);
        };
    }, []);

    const countMentionedAnswers = (mentionedObj: Record<Uuid, BebMentions>) => {
        let count = 0;
        for (const value of Object.values(mentionedObj)) {
            count += value.mentioned.length;
        }
        return count;
    };

    const header = (
        <Header
            header={BEB.HEADER}
            handleBack={() => {
                navigate("/home");
            }}
            rightIcon="SHARE"
        />
    );

    if (!loadDone) {
        return (
            <div className="content-wrapper">
                {header}
                <Spinner />
            </div>
        );
    }

    if ((!bebData || !form || !formMetaData) && !haveOtherForms) {
        return (
            <div className={`content-wrapper ${isDemo ? "app-demo-background" : "content-dark"}`}>
                {header}
                <RoundWrapper className="beb-me-wrapper">{BEB.NO_DATA}</RoundWrapper>
            </div>
        );
    }

    if (startOverStep === START_OVER_FLOW.CONFIRMATION) {
        // this function is onlye needed right here, no need to have it global to
        // the component
        // eslint-disable-next-line no-inner-declarations
        async function onStartOverClick() {
            try {
                const res = await request(`clients/answers/${formId}/${formVersion}`, "PUT", {
                    answers: {},
                    inactive: {},
                    snapshot: true,
                });
                if (res.status === 200) {
                    setStartOverStep(START_OVER_FLOW.DONE);
                } else {
                    setStartOverStep(START_OVER_FLOW.FAILED);
                }
            } catch (_err: unknown) {
                setStartOverStep(START_OVER_FLOW.FAILED);
            }
        }
        return (
            <>
                <Header
                    header={BEB.START_OVER_BUTTON}
                    handleBack={() => {
                        setStartOverStep(START_OVER_FLOW.DISABLED);
                    }}
                    rightIcon="HOME"
                />
                <Confirmation
                    buttonPositive={`${COMMON.YES}, ${BEB.START_OVER_BUTTON.toLowerCase()}`}
                    buttonNegative={COMMON.BUTTON_CANCEL}
                    onClickPositive={() => {
                        track("start_over-ok");
                        onStartOverClick();
                    }}
                    onClickNegative={() => {
                        track("start_over-cancel");
                        setStartOverStep(START_OVER_FLOW.DISABLED);
                    }}
                >
                    <h2 className="font1" style={{ marginBottom: "1.5rem" }}>
                        {COMMON.ARE_YOU_SURE}
                    </h2>
                    <p>{BEB.START_OVER_CONFIRM_TEXT}</p>
                    <p
                        style={{
                            marginTop: "2rem",
                            color: "var(--color-indigo)",
                            fontWeight: "600",
                        }}
                    >
                        {BEB.START_OVER_TEXT_2}
                    </p>
                </Confirmation>
            </>
        );
    }

    if (startOverStep === START_OVER_FLOW.FAILED) {
        return (
            <ErrorComponent
                handleErrorComponent={() => {
                    setStartOverStep(START_OVER_FLOW.DISABLED);
                }}
            />
        );
    }

    if (startOverStep === START_OVER_FLOW.DONE) {
        return <Done navigateUrl="/" text={BEB.START_OVER_DONE} />;
    }

    if (bebData && form) {
        switch (currentView) {
            case "good":
            case "important":
                return (
                    <Important
                        type={currentView}
                        data={
                            currentView === "important"
                                ? [...bebData.HELP, ...bebData.WORRIES]
                                : bebData.RESOURCE
                        }
                        inactiveData={
                            // shouldn't be too hard to parse...
                            // eslint-disable-next-line no-nested-ternary
                            bebDataInactive
                                ? currentView === "important"
                                    ? [...bebDataInactive.HELP, ...bebDataInactive.WORRIES]
                                    : bebDataInactive.RESOURCE
                                : []
                        }
                        form={form}
                        close={() => {
                            navigate("/beb");
                        }}
                    />
                );
            case "persons":
                return (
                    <Persons
                        data={bebData.persons}
                        inactiveData={bebDataInactive?.persons || {}}
                        form={form}
                        close={() => {
                            navigate("/beb");
                        }}
                    />
                );
            case "houses":
                return (
                    <Houses
                        data={bebData.houses}
                        inactiveData={bebDataInactive?.houses || {}}
                        form={form}
                        close={() => {
                            navigate("/beb");
                        }}
                    />
                );
            case "myself":
            case "leisure":
            case "school":
            case "family":
            case "base":
                return (
                    <Category
                        type={currentView.toUpperCase()}
                        data={bebData[currentView.toUpperCase() as keyof TQuestionTags] || {}}
                        inactiveData={
                            bebDataInactive?.[currentView.toUpperCase() as keyof TQuestionTags] ||
                            {}
                        }
                        form={form}
                        close={() => {
                            navigate("/beb");
                        }}
                    />
                );
            default:
                // break to use the outer, last, return
                break;
        }
    }

    function getCategoryListItems(questions: Record<Uuid, BebObject[]>, tag: string) {
        return Object.entries(questions)
            .slice(0, 4)
            .map(([questionId, bebOject]) => {
                return bebOject.map((entry) => {
                    switch (entry.answer.type) {
                        case "text": {
                            // type assertion: answer of type 'text' is an AnswerText
                            const answer = entry.answer as Form.AnswerText;
                            return entry.data.length > 0 ? (
                                entry.data.map((dataId) => {
                                    const q = getPersonalQuestion(profile, entry.question, dataId);
                                    return <li key={dataId}>{`${q} ${answer.text.sv}`}</li>;
                                })
                            ) : (
                                <li key={questionId + answer.text.sv}>
                                    {`${entry.question.text.sv}: ${answer.text.sv}`}
                                </li>
                            );
                        }
                        case "accommodations":
                            // Answers with type accommodation is listed under houses,
                            // if the child has answered "ingen" then the answer is TEXT
                            // and rendered under AnswerTypes.TEXT
                            return null;
                        case "persons":
                            return (
                                entry.data.length > 0 &&
                                entry.data.map((dataId) => {
                                    const q = getPersonalQuestion(profile, entry.question, dataId);
                                    return (
                                        <li key={dataId + tag}>
                                            {`${q.replace(
                                                BEB.REPLACE,
                                                `${
                                                    personTypes[
                                                        profile.persons[dataId].type as string
                                                    ].lang.sv
                                                } `,
                                            )}`}
                                        </li>
                                    );
                                })
                            );
                        default:
                            return (
                                <li key={questionId + entry.answer.type}>
                                    {entry.question.text.sv}
                                </li>
                            );
                    }
                });
            });
    }

    function getAccommodations() {
        if (!form) {
            return null;
        }

        const active = bebData && Object.keys(bebData.houses).length > 0 && (
            <>
                <span className="beb-section-header">
                    <h2 className="font3">{BEB.HOUSES}</h2>
                    <span className="beb-section-number">{`${countMentionedAnswers(
                        bebData.houses,
                    )} ${BEB.ANSWER.toLowerCase()}`}</span>
                </span>
                <ul className="bodyText14">
                    {Object.values(bebData.houses).map((houseData) => {
                        return houseData.mentioned.slice(0, 4).map((question) => {
                            const currentQuestion = getQuestion(form, undefined, question.question);
                            return (
                                <li key={question.question}>
                                    {currentQuestion?.question?.text.sv}
                                </li>
                            );
                        });
                    })}
                </ul>
            </>
        );

        // if no active answers, show inactive answers
        let inactive;
        if (!active) {
            inactive = bebDataInactive?.houses &&
                Object.keys(bebDataInactive.houses).length > 0 && (
                    <details className="inactive-answers-details">
                        <summary>{COMMON.INACTIVE_ANSWERS}</summary>
                        <p className="details-info">{COMMON.INACTIVE_ANSWERS_INFO}</p>
                        <ul className="inactive-answers">
                            {Object.values(bebDataInactive.houses).map((houseData) => {
                                return houseData.mentioned.slice(0, 4).map((question) => {
                                    const currentQuestion = getQuestion(
                                        form,
                                        undefined,
                                        question.question,
                                    );
                                    return (
                                        <li key={question.question}>
                                            {currentQuestion?.question?.text.sv}
                                        </li>
                                    );
                                });
                            })}
                        </ul>
                    </details>
                );
        }

        if (!active && !inactive) {
            return null;
        }

        return (
            <RoundWrapper className="beb-section-wrapper">
                {active}
                {inactive}
                {bebData && Object.keys(bebData.houses).length > 0 && (
                    <ButtonSecondary
                        size="xs"
                        icon={{ url: DiagramIcon, layout: "left" }}
                        onClick={() => {
                            navigate("/beb/houses");
                        }}
                    >
                        {COMMON.BUTTON_ALL}
                    </ButtonSecondary>
                )}
            </RoundWrapper>
        );
    }

    function getGeneralBeb() {
        if (!bebData) {
            return <RoundWrapper className="beb-me-wrapper">{BEB.NO_DATA}</RoundWrapper>;
        }

        return (
            <>
                <div className="beb-sunny-rain-wrapper beb-good-wrapper">
                    <h2 className="font1" style={{ color: "var(--color-indigo-dark)" }}>
                        {BEB.GOOD_THINGS}
                    </h2>
                    <ButtonSecondary
                        size="xs"
                        icon={{ url: DiagramIcon, layout: "left" }}
                        onClick={() => {
                            navigate("/beb/good");
                        }}
                    >
                        {COMMON.BUTTON_ALL}
                    </ButtonSecondary>
                </div>
                <div className="beb-sunny-rain-wrapper beb-important-wrapper">
                    <h2 className="font1" style={{ color: "#fff" }}>
                        {BEB.IMPORTANT_THINGS}
                    </h2>
                    <ButtonSecondary
                        size="xs"
                        icon={{ url: DiagramIcon, layout: "left" }}
                        onClick={() => {
                            navigate("/beb/important");
                        }}
                    >
                        {COMMON.BUTTON_ALL}
                    </ButtonSecondary>
                </div>
                {Object.keys(bebData.persons).length > 0 && (
                    <RoundWrapper>
                        <span className="beb-section-header">
                            <h2 className="font3">{BEB.ANSWER_PERSON}</h2>
                            <span className="beb-section-number">{`${countMentionedAnswers(
                                bebData.persons,
                            )} ${BEB.ANSWER.toLowerCase()}`}</span>
                        </span>
                        <div className="beb-persons-wrapper">
                            {Object.keys(bebData.persons).map((id) => {
                                return (
                                    <div key={id} className="beb-persons-card">
                                        <GetAvatar avatarData={profile?.persons?.[id].avatar} />
                                        <p className="bodyText12">
                                            {
                                                personTypes[profile.persons?.[id].type as string]
                                                    .lang.sv
                                            }
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="beb-persons-button">
                            <ButtonSecondary
                                size="xs"
                                icon={{ url: DiagramIcon, layout: "left" }}
                                onClick={() => {
                                    navigate("/beb/persons");
                                }}
                            >
                                {COMMON.BUTTON_ALL}
                            </ButtonSecondary>
                        </div>
                    </RoundWrapper>
                )}

                {getAccommodations()}
                {categoryArray.map(([tag, text]) => {
                    const questions: Record<Uuid, BebObject[]> =
                        bebData?.[tag as keyof TQuestionTags];
                    const questionsInactive: Record<Uuid, BebObject[]> | undefined =
                        bebDataInactive?.[tag as keyof TQuestionTags];
                    if (
                        Object.keys(questions).length === 0 &&
                        (!questionsInactive || Object.keys(questionsInactive).length === 0)
                    ) {
                        return null;
                    }
                    return (
                        <RoundWrapper key={tag} className="beb-section-wrapper">
                            <span className="beb-section-header">
                                <h2 className="font3">{text}</h2>
                                <span className="beb-section-number">{`${
                                    Object.keys(questions).length
                                } ${BEB.ANSWER.toLowerCase()}`}</span>
                            </span>
                            <ul className="bodyText14">{getCategoryListItems(questions, tag)}</ul>
                            {Object.keys(questions).length === 0 &&
                                questionsInactive &&
                                Object.keys(questionsInactive).length > 0 && (
                                    <details className="inactive-answers-details">
                                        <summary>{COMMON.INACTIVE_ANSWERS}</summary>
                                        <p className="details-info">
                                            {COMMON.INACTIVE_ANSWERS_INFO}
                                        </p>
                                        <ul className="inactive-answers">
                                            {getCategoryListItems(questionsInactive, tag)}
                                        </ul>
                                    </details>
                                )}
                            {bebData[tag as keyof TQuestionTags] &&
                                Object.keys(bebData[tag as keyof TQuestionTags]).length > 0 && (
                                    <ButtonSecondary
                                        size="xs"
                                        icon={{ url: DiagramIcon, layout: "left" }}
                                        onClick={() => {
                                            navigate(`/beb/${tag.toLowerCase()}`);
                                        }}
                                    >
                                        {COMMON.BUTTON_ALL}
                                    </ButtonSecondary>
                                )}
                        </RoundWrapper>
                    );
                })}
            </>
        );
    }

    return (
        <div className={`content-wrapper ${isDemo ? "app-demo-background" : "content-dark"}`}>
            {header}
            <RoundWrapper className="beb-me-wrapper">
                <GetAvatar avatarData={profile.avatar} />
                <p className="bodyText14">{`${BEB.BORN} ${profile.birthYear ?? "-"}`}</p>
                {profile.gender && (
                    <p className="bodyText14">{`${BEB.GENDER} ${
                        genderTypes[profile.gender].sv
                    }`}</p>
                )}
            </RoundWrapper>

            <hr className="myProfile-hr" />

            {getGeneralBeb()}

            {bebData && (
                <>
                    <RoundWrapper key="start_over" className="beb-section-wrapper">
                        <span className="beb-section-header">
                            <h2 className="font3">{BEB.START_OVER_HEADER}</h2>
                        </span>
                        <div
                            className="bodyText14"
                            style={{
                                marginLeft: "1rem",
                                marginRight: "1rem",
                                marginBottom: "2.5rem",
                            }}
                        >
                            {BEB.START_OVER_TEXT_1}
                            <br />
                            <br />
                            {BEB.START_OVER_TEXT_2}
                        </div>
                        <ButtonPrimary
                            size="medium"
                            className="beb-startOver-button"
                            umamiEvent="start_over-start"
                            icon={{ layout: "left", url: RefreshIcon }}
                            onClick={async () => {
                                setStartOverStep(START_OVER_FLOW.CONFIRMATION);
                            }}
                        >
                            {BEB.START_OVER_BUTTON}
                        </ButtonPrimary>
                    </RoundWrapper>

                    <ButtonPrimary
                        className="beb-share-button"
                        size="large"
                        icon={{ url: ArrowWhiteIcon, layout: "right" }}
                        onClick={() => {
                            navigate("/beb/share");
                        }}
                    >
                        {BEB.BUTTON_SHARE}
                    </ButtonPrimary>
                </>
            )}

            <hr className="myProfile-hr" />

            {haveOtherForms && (
                <details className="inactive-answers-details beb-followUp-header">
                    <summary style={{ fontSize: "1rem", color: "black" }}>
                        <h2
                            className="forms-summary font2"
                            style={{ display: "inline-block", cursor: "pointer" }}
                        >
                            {BEB.FORMS_ANSWER_HEADER}
                        </h2>
                    </summary>
                    <p className="details-info" style={{ background: "none", margin: "0.5rem" }}>
                        {BEB.FORMS_ANSWER_INFO}
                    </p>
                    {user?.invitations?.accepted.map((invitation) => {
                        if (!invitation.forms) {
                            return null;
                        }
                        return invitation.forms.map((inv) => {
                            return (
                                inv.done && (
                                    <Link
                                        className="beb-followUp-wrapper"
                                        to={`/beb/requestform/${inv.form.id}/${inv.form.version}/${invitation.id}/${inv.id}`}
                                        key={inv.id}
                                    >
                                        <img
                                            src={ChatMarkIcon}
                                            alt="chat icon with question mark"
                                        />
                                        <div className="beb-followUp-text">
                                            {formMetaData && (
                                                <h4>
                                                    {getFormName(
                                                        formMetaData,
                                                        inv.form.id,
                                                        inv.form.version,
                                                    )}
                                                </h4>
                                            )}
                                            <span>{`${invitation.owner}, ${invitation.unit}`}</span>
                                        </div>
                                        <img src={ArrowRightIcon} alt="arrow" />
                                    </Link>
                                )
                            );
                        });
                    })}
                </details>
            )}
        </div>
    );
};

export default Beb;
