import type { AvatarGroups } from "@ommej/metadata";
import type { AvatarId } from "@ommej/types";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import { request } from "~/src/utils/api";
import language from "~/src/utils/language";
import { reloadProfile, updatePersons } from "~/src/utils/profileUtils";
import Header from "../header/header";
import Avatar from "../profile/avatar";

type TLocationState = {
    state: {
        ageGroup: AvatarGroups;
        personId?: string;
        type: string;
        avatarChildSelection: boolean;
    };
};

const AvatarPicker = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { profile, setProfile } = useContext(ProfileContext);
    const { locales } = useContext(LanguageContext);
    const { AVATAR_PICKER } = language[locales];
    const locationState = location.state as TLocationState | undefined;

    const handleAvatarClicked = async (avatar: AvatarId) => {
        try {
            if (locationState?.state.personId) {
                await updatePersons([
                    {
                        personId: locationState.state.personId,
                        personData: { avatar, type: locationState.state.type },
                    },
                ]);
                await reloadProfile(setProfile);
            } else {
                await request("clients/profile/avatar", "PUT", {
                    avatar: { type: "ID", data: avatar },
                });
                setProfile({ ...profile, avatar: { type: "ID", data: avatar } });
            }
            navigate(-1);
        } catch (error: unknown) {
            console.error("error:", error);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className="content-wrapper">
            <Header handleBack={handleBack} header={AVATAR_PICKER.HEADING} />
            <Avatar handleAvatarClicked={handleAvatarClicked} state={locationState?.state} />
        </div>
    );
};

export default AvatarPicker;
