import type * as React from "react";
import "./textInput.css";

interface Interface {
    name: string;
    placeholder: string;
    className?: string | undefined;
    value?: string;
    ariaLabel: string;
    handleChange?(e: React.ChangeEvent<HTMLInputElement>): void;
    onError: boolean;
    type?: string;
    minLength?: number;
    min?: string;
    max?: string;
    autoComplete?: string;
}

const TextInput = ({
    name,
    placeholder,
    className = "",
    value,
    ariaLabel,
    handleChange,
    onError,
    minLength,
    min,
    max,
    autoComplete,
    type = "text",
}: Interface) => {
    return (
        <label htmlFor={name} aria-label={ariaLabel}>
            <input
                type={type}
                id={name}
                name={name}
                className={`textInput-input ${className} ${onError ? "textInput-error" : ""}`}
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
                minLength={minLength}
                autoComplete={autoComplete}
                min={min}
                max={max}
                required
            />
        </label>
    );
};

export default TextInput;
