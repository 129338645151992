import { getQuestion } from "@ommej/pregunta";
import type { Form, Profile, SingleAnswer, Uuid } from "@ommej/types";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import ArrowThinIcon from "~/src/media/icons/arrowThin.svg";
import PencilIcon from "~/src/media/icons/pencil.svg";
import GoodCelebrate from "~/src/media/svg/good_celebrate.svg";
import ImportantImg from "~/src/media/svg/important_img.svg";
import { isDemo } from "~/src/utils/api";
import language from "~/src/utils/language";
import { getPersonalQuestion } from "~/src/utils/utils";
import Header from "../header/header";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./important.css";

type TlistItem = { qid: Uuid; text?: string; answerText?: string; listKey: string };

const pushListItem = (listItems: TlistItem[], item: TlistItem) => {
    if (item.text && item.listKey && item.qid) {
        listItems.push(item);
    }
};

function getListItems(
    form: Form.FormData,
    profile: Profile,
    data: { qid: Uuid; answer: SingleAnswer }[],
) {
    const questions = new Set();
    const listItems: TlistItem[] = [];
    for (const importantObject of data) {
        const { qid } = importantObject;
        if (questions.has(qid)) {
            continue;
        }
        questions.add(qid);
        const currentQuestion = getQuestion(form, undefined, qid);
        let text = currentQuestion?.question?.text.sv;
        const answerId = importantObject.answer.answer;
        let answerText: string | undefined;
        if (currentQuestion?.question?.answers[answerId].type === "text") {
            // type assertion: only AnswerText & AnswerMultiple are of type text
            answerText = (
                currentQuestion.question.answers[answerId] as Form.AnswerText | Form.AnswerMultiple
            ).text.sv;
        }
        const questionFilter = ["multiple_person", "single_person"];
        if (currentQuestion?.question && questionFilter.includes(currentQuestion.question.type)) {
            for (const answer of importantObject.answer.data) {
                text = getPersonalQuestion(profile, currentQuestion.question, answer);
                const listKey = answerId + answer;
                pushListItem(listItems, { qid, text, answerText, listKey });
            }
        } else {
            pushListItem(listItems, { qid, text, answerText, listKey: answerId });
        }
    }

    return listItems;
}

type ImportantProp = {
    type: "good" | "important";
    data: { qid: Uuid; answer: SingleAnswer }[];
    inactiveData: { qid: Uuid; answer: SingleAnswer }[];
    form: Form.FormData;
    close: () => void;
};

const Important = ({ type, data, inactiveData, form, close }: ImportantProp) => {
    const { profile } = useContext(ProfileContext);
    const { locales } = useContext(LanguageContext);
    const { COMMON, BEB } = language[locales];
    const [itemsListModel, setItemsListModel] = useState<TlistItem[]>([]);
    const [itemsListModelInactive, setItemsListModelInactive] = useState<TlistItem[]>([]);

    useEffect(() => {
        const listItems = getListItems(form, profile, data);
        setItemsListModel(listItems);

        const listItemsInactive = getListItems(form, profile, inactiveData);
        setItemsListModelInactive(listItemsInactive);
    }, [profile]);

    return (
        <div className={`content-wrapper ${isDemo ? "app-demo-background" : "content-dark"}`}>
            <Header
                handleBack={() => {
                    close();
                }}
                header={BEB[`${type.toUpperCase()}_HEADER`]}
            />
            <div
                className={`important-text-wrapper ${
                    type === "important"
                        ? "important-text-background-rain"
                        : "important-text-background-sun"
                }`}
            >
                <p
                    className="bodyText14"
                    /* biome-ignore lint/security/noDangerouslySetInnerHtml: we control the input */
                    dangerouslySetInnerHTML={{ __html: BEB[`${type.toUpperCase()}_TEXT`] }}
                />
                {(type === "important" && (
                    <Link className="link-with-icon buttons-wrapper b-secondary" to="/support">
                        {BEB.IMPORTANT_BUTTON}
                        <img src={ArrowThinIcon} alt="" />
                    </Link>
                )) || <span />}
                <img
                    className="important-text-img"
                    src={type === "important" ? ImportantImg : GoodCelebrate}
                    alt={BEB[`${type.toUpperCase()}_IMG`]}
                />
            </div>
            <RoundWrapper className="important-questions-wrapper">
                {data.length > 0 ? (
                    <ul>
                        {itemsListModel.map((listItem) => {
                            return (
                                <li key={listItem.listKey}>
                                    <div className="important-question-wrapper">
                                        <p>
                                            {listItem.text}
                                            {listItem.answerText && <>: {listItem.answerText}</>}
                                        </p>
                                        <Link
                                            className="beb-edit-answer-link"
                                            to={`/form/${form.metadata.id}/${form.metadata.version}/${listItem.qid}`}
                                            state={{
                                                back: `/beb/${type}`,
                                                next: "/beb",
                                            }}
                                        >
                                            <img src={PencilIcon} alt="Change question" />
                                        </Link>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <p className="bodyText14">{BEB[`${type}_NOTHING`]}</p>
                )}
                {inactiveData.length > 0 ? (
                    <details className="inactive-answers-details">
                        <summary>
                            <h2 className="summary-header">{COMMON.INACTIVE_ANSWERS}</h2>
                        </summary>
                        <p className="details-info">{COMMON.INACTIVE_ANSWERS_INFO}</p>
                        <ul className="inactive-answers">
                            {itemsListModelInactive.map((listItem) => {
                                return (
                                    <li
                                        key={listItem.listKey}
                                        className="important-question-wrapper"
                                    >
                                        {listItem.text}
                                        {listItem.answerText && <>: {listItem.answerText}</>}
                                    </li>
                                );
                            })}
                        </ul>
                    </details>
                ) : (
                    <p className="bodyText14">{BEB[`${type}_NOTHING`]}</p>
                )}
            </RoundWrapper>
        </div>
    );
};

export default Important;
