import { PhoneInput } from "@ommej/componente";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import type * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import UnderscoreButton from "~/src/components/tools/buttons/underscoreButton";
import PasswordInput from "~/src/components/tools/inputs/passwordInput";
import { LanguageContext } from "~/src/contexts/languageContext";
import ArrowWhiteIcon from "~/src/media/icons/arrow_white.svg";
import Logo from "~/src/media/svg/logo_full.svg";
import { request, requestWithAuth } from "~/src/utils/api";
import language from "~/src/utils/language";
import Header from "../header/header";
import TextInput from "../tools/inputs/textInput";

enum usernameTypes {
    email = "E-post",
    tel = "Mobilnummer",
}

type UsernameTypes = keyof typeof usernameTypes;

const ResetPwd = () => {
    const { locales } = useContext(LanguageContext);
    const { COMMON, ERROR, RESET_PASSWORD } = language[locales];
    const navigate = useNavigate();
    const [mailSent, setMailSent] = useState<boolean>(false);
    const [token, setToken] = useState<string | null>();
    const [error, setError] = useState<string>();
    const [queryParams] = useSearchParams();
    const [formValid, setFormValid] = useState<boolean>(false);
    const [usernameType, setUsernameType] = useState<UsernameTypes>("tel");
    const eForm = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (queryParams.get("token")) {
            setToken(queryParams.get("token"));
        }
    }, []);

    const handleChangeUsernameType = useCallback(
        (e: UsernameTypes) => {
            setFormValid(false);
            if (usernameType !== e) {
                setUsernameType(e);
            }
        },
        [usernameType],
    );

    const onChange = useCallback(() => {
        if (!eForm.current) {
            setFormValid(false);
            return;
        }
        if (!token) {
            setFormValid(eForm.current.checkValidity());
        } else {
            setFormValid(
                eForm.current.checkValidity() &&
                    eForm.current["reset-password2"].value ===
                        eForm.current["reset-password1"].value,
            );
        }
    }, [eForm, token]);

    const onSubmitUsername = useCallback(async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await request("clients/resetpwd/start", "POST", {
                // formattedValue is set if phone number (via TelInput) was used
                username: e.target.username.formattedValue || e.target.username.value,
                redirect: window.location.href.replace("capacitor", "https"),
            });
            setMailSent(true);
        } catch (_err) {
            setError(ERROR.AUTH);
        }
    }, []);

    const onSubmitPassword = useCallback(
        async (e: React.ChangeEvent<HTMLFormElement>) => {
            e.preventDefault();
            // can't happen
            if (!token) {
                return;
            }
            try {
                await requestWithAuth("clients/resetpwd", "POST", token, {
                    password: e.target["reset-password1"].value,
                });
                navigate("/");
            } catch (_err) {
                setError(ERROR.AUTH);
            }
        },
        [token],
    );

    if (token) {
        return (
            <>
                <Header
                    handleBack={() => {
                        navigate(-1);
                    }}
                    header={RESET_PASSWORD.HEADING_CHANGE}
                />
                <img className="login-logo" src={Logo} alt="Ommej logotype" />
                <form ref={eForm} onSubmit={onSubmitPassword}>
                    <fieldset className="login-form">
                        <legend>Skriv in ditt nya lösenord</legend>
                        <PasswordInput
                            autoComplete="new-password"
                            error={false}
                            name="reset-password1"
                            onChange={onChange}
                            create
                        />
                        <PasswordInput
                            autoComplete="new-password"
                            error={false}
                            name="reset-password2"
                            onChange={onChange}
                            placeholder={RESET_PASSWORD.PASSWORD_AGAIN}
                            create
                        />
                        <p className="login-error-text">{error}</p>
                        <ButtonPrimary
                            size="large"
                            icon={{ url: ArrowWhiteIcon, layout: "right" }}
                            className="login-button"
                            type="submit"
                            disabled={!formValid}
                        >
                            {COMMON.OK}
                        </ButtonPrimary>
                    </fieldset>
                </form>
            </>
        );
    }

    return (
        <>
            <Header
                handleBack={() => {
                    navigate(-1);
                }}
                header={RESET_PASSWORD.HEADING_RESET}
            />
            <img className="login-logo" src={Logo} alt="Ommej logotype" />
            {mailSent ? (
                <p className="login-reset-sent">
                    {usernameType === "tel" ? RESET_PASSWORD.CHECK_SMS : RESET_PASSWORD.CHECK_EMAIL}
                </p>
            ) : (
                <>
                    <div className="login-username-type-wrapper">
                        {Object.entries(usernameTypes).map(([key, value]) => {
                            return (
                                <UnderscoreButton
                                    key={key}
                                    active={usernameType === key}
                                    onClick={() => {
                                        return handleChangeUsernameType(key as UsernameTypes);
                                    }}
                                >
                                    {value}
                                </UnderscoreButton>
                            );
                        })}
                    </div>
                    <form ref={eForm} onSubmit={onSubmitUsername}>
                        <fieldset className="login-form">
                            <legend>Skriv in ditt användarnamn</legend>
                            {usernameType === "tel" ? (
                                <PhoneInput
                                    wrapperClass="login-input"
                                    name="username"
                                    handleChange={onChange}
                                />
                            ) : (
                                <TextInput
                                    ariaLabel={COMMON.INPUT_EMAIL}
                                    className="login-input"
                                    handleChange={onChange}
                                    key="changeusername"
                                    name="username"
                                    onError={false}
                                    placeholder={COMMON.INPUT_EMAIL}
                                    type="email"
                                    autoComplete="email"
                                />
                            )}
                            <p className="login-error-text">{error}</p>
                            <ButtonPrimary
                                size="large"
                                icon={{ url: ArrowWhiteIcon, layout: "right" }}
                                className="login-button"
                                type="submit"
                                disabled={!formValid}
                            >
                                {COMMON.OK}
                            </ButtonPrimary>
                        </fieldset>
                    </form>
                </>
            )}
        </>
    );
};

export default ResetPwd;
