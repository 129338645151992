import { PhoneInput } from "@ommej/componente";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Consent from "~/src/components/profile/consent";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import { AuthContext } from "~/src/contexts/authContext";
import { LanguageContext } from "~/src/contexts/languageContext";
import Logo from "~/src/media/svg/logo_full.svg";
import { request } from "~/src/utils/api";
import { minLength } from "~/src/utils/constants";
import language from "~/src/utils/language";
import Header from "../header/header";
import UnderscoreButton from "../tools/buttons/underscoreButton";
import TextInput from "../tools/inputs/textInput";
import "./login.css";
import MigratePopup from "./migratePopup";

enum usernameTypes {
    email = "E-post",
    tel = "Mobilnummer",
}

const Migrate = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [validInputsSignIn, setValidInputsSignIn] = useState<boolean>(false);
    const [validInputsUsername, setValidInputsUsername] = useState<boolean>(false);
    const [showMigrationInfo, setShowMigrationInfo] = useState(false);
    const [showConsent, setShowConsent] = useState(false);
    const { setUser } = useContext(AuthContext);
    // signedIn == true => show change username view, otherwise sign in legacy view
    const [signedIn, setSignedIn] = useState<boolean>(false);
    const [invitationCode, setInvitationCode] = useState<string | null>();
    const [currentUsernameType, setCurrentUsernameType] = useState<string>("email");
    const newUsername = useRef<string>("");
    const currentPassword = useRef<string>("");
    const { locales } = React.useContext(LanguageContext);
    const { COMMON, RESET_USERNAME, ERROR, MIGRATION } = language[locales];

    const eFormSignIn = useRef<HTMLFormElement>(null);
    const eFormUsername = useRef<HTMLFormElement>(null);

    const [queryParams] = useSearchParams();

    useEffect(() => {
        if (queryParams.get("link")) {
            setInvitationCode(queryParams.get("link"));
        } else {
            navigate("/");
        }
    }, []);

    const onChangeUsername = useCallback(() => {
        setValidInputsUsername(
            eFormUsername?.current ? eFormUsername.current.checkValidity() : false,
        );
    }, []);

    const onChangeSignedIn = useCallback(() => {
        setValidInputsSignIn(eFormSignIn?.current ? eFormSignIn.current.checkValidity() : false);
    }, []);

    const onClick = useCallback(
        async (e: React.ChangeEvent<HTMLFormElement>) => {
            e.preventDefault();
            setErrorMessage(undefined);
            let body;
            if (!signedIn) {
                setSignedIn(false);
                const { username, password } = e.target;
                body = {
                    username: username.value,
                    password: password.value,
                    invitationCode,
                };
                currentPassword.current = body.password;
            } else {
                // formattedValue is set if phone number (via TelInput) was used
                const username = e.target.username.formattedValue || e.target.username.value;
                body = {
                    username,
                };
                newUsername.current = body.username;
            }
            try {
                const apiRequest = await request(
                    signedIn ? "clients/resetusername" : "clients/signin/legacy",
                    "POST",
                    body,
                );
                if (apiRequest.status === 200) {
                    if (!signedIn) {
                        setShowConsent(true);
                        setSignedIn(true);
                    } else {
                        setShowMigrationInfo(true);
                    }
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                if (error.status >= 500) {
                    setErrorMessage(ERROR.SERVER);
                } else if (error.status >= 400) {
                    setErrorMessage(ERROR.CREDENTIALS);
                } else {
                    setErrorMessage(ERROR.GENERIC);
                }
            }
        },
        [invitationCode, signedIn],
    );

    if (showMigrationInfo) {
        return (
            <MigratePopup
                handleButton={async () => {
                    try {
                        // login the user directly
                        const loginRequest = await request("clients/signin", "POST", {
                            username: newUsername.current,
                            password: currentPassword.current,
                        });
                        const response = await loginRequest.json();
                        // setting user context will trigger App which will redirect us to
                        // somewhere appropriate (most likely home).
                        setUser({
                            id: response.id,
                            auth: true,
                            invitations: response.invitations,
                        });
                    } catch (_err: unknown) {
                        // we at least tried to make it smooth, but something failed. Let's
                        // redirect to home which will either stay there or redirect to
                        // login.
                        navigate("/home");
                    }
                }}
            />
        );
    }

    if (showConsent && signedIn) {
        return (
            <>
                <Header header={COMMON.TERMS} />
                <Consent
                    handleConsent={() => {
                        setShowConsent(false);
                    }}
                />
            </>
        );
    }

    return (
        <>
            <a href="/">
                <img className="login-logo" src={Logo} alt="Ommej logotype" />
            </a>
            {signedIn ? (
                <>
                    <div className="login-explaination-text">{RESET_USERNAME.EXPLAIN}</div>
                    <div className="login-username-type-wrapper">
                        {Object.entries(usernameTypes).map(([key, value]) => {
                            return (
                                <UnderscoreButton
                                    key={key}
                                    active={currentUsernameType === key}
                                    onClick={() => {
                                        setCurrentUsernameType(key);
                                        setValidInputsUsername(false);
                                    }}
                                >
                                    {value}
                                </UnderscoreButton>
                            );
                        })}
                    </div>
                    <form ref={eFormUsername} onSubmit={onClick}>
                        <fieldset className="login-form">
                            <legend>Logga in med ditt användarnamn och lösenord</legend>
                            {currentUsernameType === "tel" ? (
                                <PhoneInput
                                    wrapperClass="login-input"
                                    name="username"
                                    handleChange={onChangeUsername}
                                />
                            ) : (
                                <TextInput
                                    ariaLabel={COMMON.INPUT_EMAIL}
                                    className="login-input"
                                    handleChange={onChangeUsername}
                                    key="changeusername"
                                    name="username"
                                    onError={!!errorMessage}
                                    placeholder={COMMON.INPUT_EMAIL}
                                    type="email"
                                    autoComplete="email"
                                />
                            )}
                            <p className="login-error-text">{errorMessage}</p>
                            <ButtonPrimary
                                size="large"
                                type="submit"
                                className="login-button"
                                disabled={!validInputsUsername}
                            >
                                {COMMON.OK}
                            </ButtonPrimary>
                        </fieldset>
                    </form>
                </>
            ) : (
                <>
                    <p className="login-reset-sent">{MIGRATION.LOGIN_OLD}</p>
                    <form ref={eFormSignIn} onSubmit={onClick}>
                        <fieldset className="login-form">
                            <legend>Logga in med ditt användarnamn och lösenord</legend>
                            <TextInput
                                ariaLabel={COMMON.INPUT_USERNAME}
                                className="login-input"
                                handleChange={onChangeSignedIn}
                                key="username"
                                minLength={minLength.username}
                                name="username"
                                onError={!!errorMessage}
                                placeholder={COMMON.INPUT_USERNAME}
                                type="text"
                                autoComplete="username"
                            />
                            <TextInput
                                ariaLabel={COMMON.INPUT_PASSWORD}
                                className="login-input"
                                handleChange={onChangeSignedIn}
                                key="password"
                                minLength={minLength.password}
                                name="password"
                                onError={!!errorMessage}
                                placeholder={COMMON.INPUT_PASSWORD}
                                type="password"
                                autoComplete="password"
                            />
                            <p className="login-error-text">{errorMessage}</p>
                            <ButtonPrimary
                                type="submit"
                                size="large"
                                className="login-button"
                                disabled={!validInputsSignIn}
                            >
                                {COMMON.BUTTON_LOGIN}
                            </ButtonPrimary>
                        </fieldset>
                    </form>
                </>
            )}
        </>
    );
};

export default Migrate;
