import type * as React from "react";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import "./confirmation.css";
import ButtonSecondary from "./tools/buttons/buttonSecondary";
import ButtonText from "./tools/buttons/buttonText";

type ConfirmationProps = {
    children: React.ReactNode;
    onClickPositive: () => void;
    onClickNegative?: () => void;
    onClickCancel?: () => void;
    buttonPositive: string;
    buttonNegative?: string;
    buttonCancel?: string;
};

const Confirmation = ({
    children,
    buttonPositive,
    buttonNegative,
    buttonCancel,
    onClickPositive,
    onClickNegative,
    onClickCancel,
}: ConfirmationProps) => {
    return (
        <div className="confirmation-wrapper">
            {children}
            <footer className="confirmation-footer">
                <ButtonPrimary size="medium" onClick={onClickPositive}>
                    {buttonPositive}
                </ButtonPrimary>
                {buttonNegative && onClickNegative && (
                    <ButtonSecondary size="medium" onClick={onClickNegative}>
                        {buttonNegative}
                    </ButtonSecondary>
                )}
                {buttonCancel && onClickCancel && (
                    <ButtonText size="medium" onClick={onClickCancel}>
                        {buttonCancel}
                    </ButtonText>
                )}
            </footer>
        </div>
    );
};
export default Confirmation;
