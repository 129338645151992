import { App as CapacitorApp, type URLOpenListenerEvent } from "@capacitor/app";
import { LocalNotifications } from "@capacitor/local-notifications";
import { PushNotifications } from "@capacitor/push-notifications";
import type { PushNotifications as PushTypes } from "@ommej/types";
import { useContext, useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import Form from "~/src/components/form/form";
import { LanguageContext } from "~/src/contexts/languageContext";
import { isDemo, request } from "~/src/utils/api";
import language from "~/src/utils/language";
import { track } from "~/src/utils/utils";
import "./App.css";
import AvatarBuilder from "./components/avatarBuilder/avatarBuilder";
import Beb from "./components/beb/beb";
import RequestForm from "./components/beb/requestForm";
import SharedBeb from "./components/beb/sharedBeb";
import Connect from "./components/connect";
import FormHandler from "./components/form/formHandler";
import DeleteAccount from "./components/home/deleteAccount";
import Home from "./components/home/home";
import Mood from "./components/home/mood";
import MoodChartWrapper from "./components/home/moodChartWrapper";
import MyProfile from "./components/home/myProfile";
import Organizations from "./components/home/organizations";
import SettingsView from "./components/home/settings";
import Support from "./components/home/support";
import InvitationCode from "./components/invitations/invitationCode";
import Invitations from "./components/invitations/invitations";
import Login from "./components/login/login";
import Migrate from "./components/login/migrate";
import ResetPwd from "./components/login/resetPwd";
import AccommodationPicker from "./components/pickers/accommodationPicker";
import AvatarPicker from "./components/pickers/avatarPicker";
import BirthYearPicker from "./components/pickers/birthYearPicker";
import ChangePersonPicker from "./components/pickers/changePersonPicker";
import GenderPicker from "./components/pickers/genderPicker";
import HousePicker from "./components/pickers/housePicker";
import Profile from "./components/profile/profile";
import AllQuestionDone from "./components/sprinkles/allQuestionDone";
import Start from "./components/start/start";
import ErrorComponent from "./components/tools/errorComponent/errorComponent";
import { AuthContext } from "./contexts/authContext";
import { NotificationContext } from "./contexts/notificationContext";
import { ProfileProvider } from "./contexts/profileContext";
import closeIcon from "./media/icons/close.svg";
import LogoImg from "./media/png/logo-512.png";
import { FORM_DEFAULT_ID, FORM_DEFAULT_VERSION } from "./utils/constants";
import { useOnLineStatus } from "./utils/useOnlineStatus";

interface BeforeInstallPromptEvent extends Event {
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;
    prompt(): Promise<void>;
}

const App = () => {
    const userContext = useContext(AuthContext);
    const notificationContext = useContext(NotificationContext);
    const { locales } = useContext(LanguageContext);
    const { COMMON, PROMPT } = language[locales];
    const [checkedToken, setCheckedToken] = useState<boolean>(false);
    const [showPrompt, setShowPrompt] = useState(false);
    const [promptEvent, setPromptEvent] = useState<BeforeInstallPromptEvent | null>(null);
    const token = useRef<string | null>();
    const [formId] = useState<string>(FORM_DEFAULT_ID);
    const [formVersion] = useState<string>(FORM_DEFAULT_VERSION);
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    const bodyElem = document.querySelector("body");
    if (isDemo) {
        bodyElem?.classList.add("app-demo-background");
    }

    async function getUser() {
        if (userContext.user || token.current) {
            return;
        }
        try {
            const res = await request("clients", "GET");
            const resUser = await res.json();
            userContext.setUser({
                id: resUser.id,
                auth: true,
                invitations: resUser.invitations,
            });
            setCheckedToken(true);
        } catch (_err) {
            setCheckedToken(true);
        }
    }

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (ev) => {
            ev.preventDefault();
            setPromptEvent(ev as BeforeInstallPromptEvent);
            setShowPrompt(true);
        });
        return () => {
            window.removeEventListener("beforeinstallprompt", () => {
                setPromptEvent(null);
            });
        };
    }, []);

    const handlePromptClick = () => {
        if (!promptEvent) {
            return;
        }
        promptEvent.prompt();
        promptEvent.userChoice.then(() => {
            setShowPrompt(false);
        });
    };

    useEffect(() => {
        // Removed in unsubscribeBackButton()
        CapacitorApp.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
            const path = event.url.split("ommej.se").pop();
            if (path) {
                navigate(path);
            } else {
                navigate("/");
            }
        });
    }, []);

    useEffect(() => {
        getUser();
        async function subscribeBackButton() {
            await CapacitorApp.addListener("backButton", ({ canGoBack }) => {
                if (canGoBack) {
                    window.history.back();
                } else {
                    CapacitorApp.exitApp();
                }
            });
        }
        subscribeBackButton();

        async function subscribePushNotifications() {
            try {
                await PushNotifications.addListener("pushNotificationReceived", () => {
                    notificationContext.received.setHaveNotification(true);
                });

                await PushNotifications.addListener("pushNotificationActionPerformed", (action) => {
                    switch (action.notification.data.type as PushTypes.Types) {
                        case "NEW_FORM":
                            track("notify-performed-new_form");
                            window.location.href = "/form";
                            break;
                        case "NEW_INVITATION":
                            track("notify-performed-invitation");
                            window.location.href = "/?fromNotification=1";
                            break;
                        default:
                            track(
                                `notify-performed-${
                                    action.notification.data?.type?.toLowerCase() || "unknown"
                                }`,
                            );
                            window.location.href = "/";
                            break;
                    }
                });
            } catch (_error: unknown) {
                // do nothing, probably no support for capacitor's push notifications
                // (e.g., this happens on the web)
                // eslint-disable-next-line no-console
                console.info("Push notifications not supported");
            }
        }
        subscribePushNotifications();

        async function subscribeLocalNotifications() {
            try {
                await LocalNotifications.addListener("localNotificationActionPerformed", () => {
                    // for now, assume all local notifications are from the mood check
                    track("notify-performed-mood_check");
                    navigate("/");
                });
            } catch (_error: unknown) {
                // eslint-disable-next-line no-console
                console.info("Local notifications not supported");
            }
        }
        subscribeLocalNotifications();

        if (queryParams.get("fromNotification")) {
            notificationContext.pressed.setHavePressedNotification(true);
        }

        return () => {
            async function unsubscribeBackButton() {
                await CapacitorApp.removeAllListeners();
            }
            unsubscribeBackButton();

            async function unsubscribePushNotifications() {
                try {
                    await PushNotifications.removeAllListeners();
                } catch (_error: unknown) {
                    // do nothing, it's OK, this will always happen in webapp
                }
            }
            unsubscribePushNotifications();

            async function unsubscribeLocalNotifications() {
                try {
                    await LocalNotifications.removeAllListeners();
                } catch (_err) {
                    // do nothing, it's OK, this will always happen in webapp
                }
            }
            unsubscribeLocalNotifications();
        };
    }, []);

    const handleErrorComponent = () => {
        console.error("You need to fix your network");
    };

    const isOnline = useOnLineStatus();

    if (!checkedToken) {
        return null;
    }

    if (!isOnline) {
        return (
            <ErrorComponent
                errorText={COMMON.OFFLINE}
                handleErrorComponent={handleErrorComponent}
            />
        );
    }

    // if logged in
    if (userContext.user) {
        return (
            <ProfileProvider>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="form" element={<FormHandler />} />
                    {/* AllQuestionDone is only shown for general:2, which is mutable */}
                    <Route path="form/done" element={<AllQuestionDone mutable />} />
                    <Route path="form/:formIdParam/:formVersionParam" element={<Form />} />
                    <Route
                        path="form/:formIdParam/:formVersionParam/:invitationId/:requestId/:questionId"
                        element={<Form />}
                    />
                    <Route
                        path="form/:formIdParam/:formVersionParam/:invitationId/:requestId"
                        element={<Form />}
                    />
                    <Route
                        path="form/:formIdParam/:formVersionParam/:questionId"
                        element={<Form />}
                    />
                    <Route path="profile" element={<Profile />} />
                    <Route path="avatarbuilder" element={<AvatarBuilder />} />
                    <Route path="home" element={<Home />} />
                    <Route path="code" element={<InvitationCode />} />
                    <Route path="beb" element={<Beb formId={formId} formVersion={formVersion} />} />
                    <Route
                        path="beb/requestform/:formId/:formVersion/:invitationId/:requestId"
                        element={<RequestForm />}
                    />
                    <Route
                        path="beb/share"
                        element={<SharedBeb formId={formId} formVersion={formVersion} />}
                    />
                    <Route
                        path="beb/:what"
                        element={<Beb formId={formId} formVersion={formVersion} />}
                    />
                    <Route path="myprofile" element={<MyProfile />} />
                    <Route path="deleteAccount" element={<DeleteAccount />} />
                    <Route path="support" element={<Support />} />
                    <Route path="support/:link" element={<Organizations />} />
                    <Route path="avatar" element={<AvatarPicker />} />
                    <Route path="birthyear" element={<BirthYearPicker />} />
                    <Route path="gender" element={<GenderPicker />} />
                    <Route path="houses" element={<HousePicker />} />
                    <Route path="accommodation" element={<AccommodationPicker />} />
                    <Route path="accommodation/:id" element={<AccommodationPicker />} />
                    <Route path="person" element={<ChangePersonPicker />} />
                    <Route path="invitations" element={<Invitations />} />
                    <Route path="moodchart" element={<MoodChartWrapper />} />
                    <Route path="mood/:type" element={<Mood />} />
                    <Route path="connect/:code" element={<Connect />} />
                    <Route path="settings" element={<SettingsView />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </ProfileProvider>
        );
    }

    // not logged in
    return (
        <div className="app-main-wrapper">
            {showPrompt && (
                <div className="app-prompt-wrapper">
                    <button
                        type="button"
                        className="app-prompt-cancel"
                        onClick={() => {
                            setShowPrompt(false);
                        }}
                    >
                        <img src={closeIcon} alt="close prompt" />
                    </button>
                    <img src={LogoImg} alt="install ommej app" />
                    <div className="app-text-wrapper">
                        <p className="bodyText14">{PROMPT.TEXT}</p>
                        <p className="bodyText12 text-grey">{PROMPT.SUB_TEXT}</p>
                    </div>
                    <button type="button" className="app-prompt-get" onClick={handlePromptClick}>
                        {COMMON.BUTTON_GET}
                    </button>
                </div>
            )}
            <Routes>
                <Route path="/" element={<Start />} />
                <Route path="login" element={<Login />} />
                <Route path="migrate" element={<Migrate />} />
                <Route path="create" element={<Login create />} />
                <Route path="resetpwd" element={<ResetPwd />} />
                <Route
                    path="shared"
                    element={<SharedBeb formId={formId} formVersion={formVersion} />}
                />
                <Route path="connect/:code" element={<Connect />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </div>
    );
};

export default App;
