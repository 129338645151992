import {
    type LocalNotificationDescriptor,
    type LocalNotificationSchema,
    LocalNotifications,
} from "@capacitor/local-notifications";
import { Settings } from "~/src/utils/storage";

const MOOD_HOUR_UTC_EARLIEST = 15;
const MOOD_HOUR_UTC_LATEST = 19;
const MOOD_NOTIFICATION_CHANNEL = "OMMEJ_MOODCHECK";

const MOODCHECK_MESSAGES = Object.freeze([
    // daily
    [
        { title: "Moodcheck", body: "Hur mår du?" },
        { title: "Moodcheck", body: "Hur är det idag?" },
        { title: "Moodcheck", body: "Hur mår du idag?" },
        { title: "Moodcheck", body: "Är allt OK?" },
        { title: "Moodcheck", body: "Fortsätt din moodstreak!" },
        { title: "Moodcheck", body: "Hur är läget?" },
        { title: "Moodcheck", body: "Berätta hur du mår." },
        { title: "Moodcheck", body: "Hur har din dag varit? Ta en minut och logga ditt mood! 🤩" },
        { title: "Moodcheck", body: "Har din dag varit 💩 eller 🌈? Berätta mer!" },
        {
            title: "Moodcheck",
            body: "Har det hänt något du vill berätta om idag? Glöm inte att logga ditt mood!",
        },
    ],
    // every other day
    [
        { title: "Moodcheck", body: "Det var ett tag sedan du berättade hur du mår." },
        { title: "Moodcheck", body: "Det var ett tag sedan du berättade hur det är." },
        { title: "Moodcheck", body: "Det var ett tag sedan du berättade, är allt OK?" },
        { title: "Moodcheck", body: "Det var ett tag sedan du berättade hur läget är." },
    ],
    // weekly
    [
        { title: "Moodcheck", body: "Det var länge sedan du berättade hur du mår." },
        { title: "Moodcheck", body: "Det var länge sedan du berättade hur det är." },
        { title: "Moodcheck", body: "Det var länge sedan du berättade, är allt OK?" },
        { title: "Moodcheck", body: "Det var länge sedan du berättade hur läget är." },
    ],
]);

function getRandomTime(from: Date, to: Date): Date {
    const fromTime = from.getTime();
    const toTime = to.getTime();
    return new Date(fromTime + Math.random() * (toTime - fromTime));
}

function getMessage(level: number): (typeof MOODCHECK_MESSAGES)[0][0] {
    const len = MOODCHECK_MESSAGES[level].length;
    return MOODCHECK_MESSAGES[level][Math.floor(Math.random() * len)];
}

function addNotification(
    notifications: LocalNotificationSchema[],
    now: Date,
    daysFromNow: number,
    message: (typeof MOODCHECK_MESSAGES)[0][0],
) {
    const from = new Date(now);
    from.setUTCDate(from.getDate() + daysFromNow);
    from.setUTCHours(MOOD_HOUR_UTC_EARLIEST);
    const to = new Date(from);
    to.setUTCHours(MOOD_HOUR_UTC_LATEST);
    const date = getRandomTime(from, to);
    const n: LocalNotificationSchema = {
        id: Math.round(Math.random() * 1000),
        title: message.title,
        body: message.body,
        schedule: { at: date },
        channelId: MOOD_NOTIFICATION_CHANNEL,
    };
    notifications.push(n);
}

function getNotifications(): LocalNotificationSchema[] {
    const notifications: LocalNotificationSchema[] = [];
    const now = new Date();

    // get for each day for the coming week
    for (let days = 1; days <= 7; days += 1) {
        const message = getMessage(0);
        addNotification(notifications, now, days, message);
    }

    // every other day for days 9-14
    for (let days = 9; days <= 14; days += 2) {
        const message = getMessage(1);
        addNotification(notifications, now, days, message);
    }

    // once a week for day 16-30
    for (let days = 16; days <= 30; days += 7) {
        const message = getMessage(2);
        addNotification(notifications, now, days, message);
    }

    return notifications;
}

async function cancelAll() {
    // cancel pending notifications
    const pending = await LocalNotifications.getPending();
    if (pending.notifications) {
        const pendingIds: LocalNotificationDescriptor[] = pending.notifications.map((p) => {
            return {
                id: p.id,
            };
        });
        if (pendingIds.length > 0) {
            await LocalNotifications.cancel({ notifications: pendingIds });
        }
    }
}

async function schedule(checkSetting = true) {
    const permRes = await LocalNotifications.requestPermissions();
    if (permRes.display === "denied") {
        return;
    }

    // remove previously scheduled notifications
    await cancelAll();

    if (checkSetting) {
        const settings = await Settings.get("mood_notify");
        if (typeof settings === "boolean" && settings === false) {
            // the user has requested to not get notifications for moodcheck, so
            // let's not schedule any new
            return;
        }
        // if the setting doesn't exist, set it to true as default
        await Settings.set("mood_notify", true);
    }

    try {
        await LocalNotifications.createChannel({
            id: MOOD_NOTIFICATION_CHANNEL,
            name: "Moodcheck",
        });
    } catch (_err) {
        // channels not implemented for web (or ios?), it's OK
    }

    // schedule new notifications
    const notifications = getNotifications();
    await LocalNotifications.schedule({ notifications });
}

async function isAllowed() {
    try {
        const channelsList = await LocalNotifications.listChannels();
        const moodChannel = channelsList.channels.find((channel) => {
            return channel.id === MOOD_NOTIFICATION_CHANNEL;
        });
        // @ts-expect-error 0 is valid in android but not defined in capacitor
        if (moodChannel?.importance === 0) {
            return false;
        }
    } catch (_err) {
        // channels not implemented for web (or ios?), it's OK
    }
    const state = await LocalNotifications.checkPermissions();
    return state.display === "granted";
}

export const MoodCheckNotifications = {
    cancelAll,
    schedule,
    isAllowed,
};
