import type { Profile } from "@ommej/types";
import * as React from "react";
import { reloadProfile } from "~/src/utils/profileUtils";

interface ProfileContextProps {
    children: React.ReactNode;
}

type ProfileContextType = {
    profile: Profile;
    setProfile: React.Dispatch<React.SetStateAction<Profile>>;
};

export const ProfileContext = React.createContext({} as ProfileContextType);

export const ProfileProvider = ({ children }: ProfileContextProps) => {
    const [profile, setProfile] = React.useState<Profile>({
        avatar: { type: "ID", data: "default" },
        birthYear: 0,
        gender: "",
        persons: {},
        houses: {},
        housePersonMap: {},
    });
    async function fetchProfile() {
        if (profile.birthYear === 0) {
            try {
                await reloadProfile(setProfile);
            } catch {
                // error logged in reloadProfile
            }
        }
    }
    fetchProfile();

    const value = React.useMemo(() => {
        return { profile, setProfile };
    }, [profile]);

    return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};
