import { useCallback, useContext, useEffect, useRef, useState } from "react";
import type * as React from "react";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import TextInput from "~/src/components/tools/inputs/textInput";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import ArrowWhiteIcon from "~/src/media/icons/arrow_white.svg";
import language from "~/src/utils/language";
import "./birthYear.css";

type TBirthyearProps = {
    handleBirthYearClicked: (birthYear: number) => void;
};

const BirthYear = ({ handleBirthYearClicked }: TBirthyearProps) => {
    const { profile } = useContext(ProfileContext);
    const { locales } = useContext(LanguageContext);
    const { COMMON, BIRTHYEAR } = language[locales];
    const [validBirthYear, setValidBirthYear] = useState(false);
    const birthYearRef = useRef<HTMLFormElement>(null);
    const defaultValue = profile.birthYear;
    const MAX_AGE = 110;

    useEffect(() => {
        if (birthYearRef.current && defaultValue) {
            birthYearRef.current.birthYear.value = defaultValue.toString();
            setValidBirthYear(true);
        }
    }, []);

    const handleChange = useCallback(() => {
        setValidBirthYear(birthYearRef?.current ? birthYearRef.current.checkValidity() : false);
    }, []);

    const sendBirthYear = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const birthYear = Number(e.target.birthYear.value);
        handleBirthYearClicked(birthYear);
    };

    return (
        <div className="birthday-content">
            <h2 className="font1 birthYear-header">
                {BIRTHYEAR.HEADING} <span>{BIRTHYEAR.HEADING_BORN}</span>
            </h2>
            <form ref={birthYearRef} onSubmit={sendBirthYear}>
                <fieldset className="birthYear-form">
                    <legend>{BIRTHYEAR.SUBHEADING}</legend>
                    <TextInput
                        type="number"
                        ariaLabel={COMMON.INPUT_EMAIL}
                        className="birthYear-input"
                        handleChange={handleChange}
                        name="birthYear"
                        onError={false}
                        placeholder="Fyll i ditt födelseår"
                        autoComplete="bday-year"
                        min={(new Date().getFullYear() - MAX_AGE).toString()}
                        max={new Date().getFullYear().toString()}
                    />

                    <ButtonPrimary
                        disabled={!validBirthYear}
                        icon={{ url: ArrowWhiteIcon, layout: "right" }}
                        size="medium"
                        type="submit"
                    >
                        {COMMON.BUTTON_READY}
                    </ButtonPrimary>
                </fieldset>
            </form>
        </div>
    );
};

export default BirthYear;
