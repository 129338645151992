import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import ButtonSecondary from "~/src/components/tools/buttons/buttonSecondary";
import { LanguageContext } from "~/src/contexts/languageContext";
import CloseIcon from "~/src/media/icons/close_blue.svg";
import StartBackgroundFallback from "~/src/media/jpg/start_bg.jpg";
import VideoOmmejImg from "~/src/media/png/video_ommej.png";
import StartBackground from "~/src/media/webp/start_bg.webp";
import language from "~/src/utils/language";
import "./start.css";

const Start = () => {
    const { locales } = useContext(LanguageContext);
    const { COMMON, START } = language[locales];
    const [fullscreen, setFullscreen] = useState(false);
    const navigate = useNavigate();

    if (fullscreen) {
        return (
            <div className="start-video-wrapper">
                <video
                    crossOrigin="anonymous"
                    poster="https://files.ommej.se/video/why_ommej.mp4.jpg"
                    preload="metadata"
                    autoPlay
                    controls
                    onPause={() => {
                        setFullscreen(false);
                    }}
                    style={{
                        width: "100%",
                    }}
                >
                    <source src="https://files.ommej.se/video/why_ommej.mp4" type="video/mp4" />
                    <track
                        label="Svenska"
                        kind="captions"
                        srcLang="sv"
                        src="https://files.ommej.se/video/why_ommej.mp4.vtt"
                        default
                    />
                </video>
                <ButtonSecondary
                    size="xs"
                    icon={{ url: CloseIcon, layout: "round" }}
                    onClick={() => {
                        setFullscreen(false);
                    }}
                />
            </div>
        );
    }

    return (
        <section className="start-ommej-wrapper">
            <picture>
                <source srcSet={StartBackground} type="image/webp" />
                <img src={StartBackgroundFallback} alt="" className="start-bg-img" />
            </picture>
            <section className="start-welcome-wrapper">
                <p className="start-welcome-paragraph">{COMMON.WHAT_IS_OMMEJ}</p>
                <button
                    type="button"
                    style={{ background: "transparent" }}
                    onClick={() => {
                        setFullscreen(true);
                    }}
                    aria-label={START.OMMEJ_VIDEO}
                >
                    <img src={VideoOmmejImg} alt="" className="start-video-img" />
                </button>

                <h1>
                    {START.HEADING} <span>{`${COMMON.OMMEJ}!`}</span>
                </h1>
                <p className="start-welcome-subheader">{START.SUBHEADER}</p>
            </section>
            <footer className="start-footer-wrapper">
                <ButtonPrimary
                    size="small"
                    onClick={() => {
                        navigate("/login");
                    }}
                    className="start-footer-button"
                >
                    {COMMON.BUTTON_LOGIN}
                </ButtonPrimary>
                <Link to="/create">
                    {START.ACCOUNT} <span>{`${START.CREATE}!`}</span>
                </Link>
            </footer>
        </section>
    );
};

export default Start;
