import { useContext } from "react";
import { LanguageContext } from "~/src/contexts/languageContext";
import Info from "~/src/media/svg/info.svg";
import language from "~/src/utils/language";
import "../profile/great.css";
import ButtonSecondary from "../tools/buttons/buttonSecondary";

type TMigratePopup = {
    handleButton?: () => void;
};

const MigratePopup = ({ handleButton }: TMigratePopup) => {
    const { locales } = useContext(LanguageContext);
    const { COMMON, MIGRATION } = language[locales];

    return (
        <div className="great-wrapper-background">
            <div className="great-message-wrapper">
                <img src={Info} alt="Important message" />
                <h1>{MIGRATION.HEADER}</h1>
                <p>{MIGRATION.TEXT1}</p>
                <p>
                    <b>{MIGRATION.TEXT2}</b>
                </p>
                <p>
                    <ButtonSecondary size="large" onClick={handleButton}>
                        {COMMON.OK.toUpperCase()}
                    </ButtonSecondary>
                </p>
            </div>
        </div>
    );
};

export default MigratePopup;
