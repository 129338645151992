import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import { request } from "~/src/utils/api";
import language from "~/src/utils/language";
import Header from "../header/header";
import BirthYear from "../profile/birthYear";
import ErrorComponent from "../tools/errorComponent/errorComponent";

const BirthYearPicker = () => {
    const { profile, setProfile } = useContext(ProfileContext);
    const { locales } = useContext(LanguageContext);
    const { BIRTHYEAR_PICKER } = language[locales];
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleBirthYearClicked = async (birthYear: number) => {
        const sendObject = {
            birthYear,
        };
        try {
            await request("clients/profile/birthyear", "PUT", sendObject);
            setProfile({ ...profile, birthYear });
            navigate(-1);
        } catch (error: unknown) {
            <ErrorComponent />;
            console.error("error:", error);
        }
    };

    return (
        <div className="content-wrapper">
            <Header handleBack={handleBack} header={BIRTHYEAR_PICKER.HEADING} />
            <BirthYear handleBirthYearClicked={handleBirthYearClicked} />
        </div>
    );
};

export default BirthYearPicker;
