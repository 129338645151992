import { MoodChartGraph } from "@ommej/componente";
import type { SerializedMood } from "@ommej/componente";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorComponent from "~/src/components/tools/errorComponent/errorComponent";
import { LanguageContext } from "~/src/contexts/languageContext";
import { request } from "~/src/utils/api";
import language from "~/src/utils/language";
import Header from "../header/header";
import "./moodChartWrapper.css";

const MoodChartWrapper = () => {
    const navigate = useNavigate();
    const { locales } = useContext(LanguageContext);
    const { ERROR, MOOD } = language[locales];
    const [moodData, setMoodData] = useState<Record<string, SerializedMood> | null>();
    const [apiError, setApiError] = useState("");
    const [doneLoading, setDoneLoading] = useState(false);

    useEffect(() => {
        const getMoods = async () => {
            try {
                const res = await request("clients/moods", "GET");
                if (res.status === 200) {
                    const resMoods = await res.json();
                    setMoodData(resMoods);
                }
            } catch (error) {
                console.error(error);
                setApiError(MOOD.WRAPPER_API);
            }
            setDoneLoading(true);
        };
        getMoods();
    }, []);

    if (apiError) {
        console.error(apiError);
        return (
            <ErrorComponent
                errorText={ERROR.GENERIC}
                handleErrorComponent={() => {
                    navigate("/");
                }}
            />
        );
    }

    if (!doneLoading) {
        return null;
    }

    return (
        <>
            <Header
                handleBack={() => {
                    return navigate("/home");
                }}
                header={MOOD.WRAPPER_HEADER}
            />
            {moodData ? (
                <div className="moodChartWrapper-wrapper">
                    <MoodChartGraph data={moodData} language="sv" dayInterval={7} />
                    <p className="moodChartWrapper-text">{MOOD.WRAPPER_TEXT}</p>
                </div>
            ) : (
                <p style={{ textAlign: "center", padding: "1.5rem" }}>{MOOD.EMPTY}</p>
            )}
        </>
    );
};

export default MoodChartWrapper;
