import { type MoodsLevel0, type MoodsLevel1, moodsLevel0, moodsLevel1 } from "@ommej/metadata";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import ButtonSecondary from "~/src/components/tools/buttons/buttonSecondary";
import ButtonText from "~/src/components/tools/buttons/buttonText";
import ErrorComponent from "~/src/components/tools/errorComponent/errorComponent";
import Modal from "~/src/components/tools/modal";
import { LanguageContext } from "~/src/contexts/languageContext";
import ArrowThinIcon from "~/src/media/icons/arrowThin.svg";
import ArrowLongIcon from "~/src/media/icons/arrow_long.svg";
import { request } from "~/src/utils/api";
import language from "~/src/utils/language";
import { capitalizeFirstLetter } from "~/src/utils/utils";
import { getSmiley } from "./home";
import "./mood.css";

type Moods = MoodsLevel0 | MoodsLevel1;

const Mood = () => {
    const params = useParams();
    const currentMood = params.type?.toUpperCase();
    const { locales } = useContext(LanguageContext);
    const { COMMON, MOOD } = language[locales];
    const [openModal, setOpenModal] = useState(false);
    const [chosenMood, setChosenMood] = useState<Set<Moods>>(new Set());
    const [tempMood, setTempMood] = useState<Set<Moods>>(new Set());
    const [data, setData] = useState<Moods[]>([]);
    const [apiError, setApiError] = useState<string>();
    const navigate = useNavigate();

    useEffect(() => {
        const subMood = moodsLevel0[currentMood as MoodsLevel0].prioritized.map(
            (item: MoodsLevel1) => {
                return item;
            },
        );
        setData(subMood);
    }, []);

    const handleClickedMood = (moodItem: Moods, setObj: Set<Moods>, currentSet: string) => {
        if (setObj.has(moodItem)) {
            setObj.delete(moodItem);
        } else {
            setObj.add(moodItem);
        }
        return currentSet === "temp"
            ? setTempMood(new Set(setObj))
            : setChosenMood(new Set(setObj));
    };

    const handleClose = () => {
        tempMood.clear();
        setOpenModal(false);
    };

    const handleDone = () => {
        const merged = new Set([...chosenMood, ...tempMood]);
        const filteredData: Moods[] = Array.from(tempMood).filter((item) => {
            return !data.includes(item);
        });
        setChosenMood(merged);
        setData([...data, ...filteredData]);
        handleClose();
    };

    const handleSendMood = async () => {
        const today = new Date().toLocaleDateString("sv-SE");
        const hasSubMood = chosenMood.size > 0;
        try {
            let apiRequest: Response;
            if (hasSubMood) {
                apiRequest = await request("clients/moods", "PUT", {
                    timestamp: today,
                    level0: currentMood,
                    level1: Array.from(chosenMood),
                });
            } else {
                apiRequest = await request("clients/moods", "PUT", {
                    timestamp: today,
                    level0: currentMood,
                });
            }
            const response = await apiRequest.json();
            if (response) {
                navigate("/home", { state: { from: "mood" } });
            }
        } catch (error) {
            console.error(error);
            setApiError(MOOD.API_ERROR);
        }
    };

    if (!currentMood) {
        navigate("/home");
        return null;
    }
    if (apiError) {
        return <ErrorComponent errorText={apiError} />;
    }
    return (
        <div className="content-wrapper">
            <header className="mood-header">
                <ButtonText
                    size="small"
                    ariaLabel={COMMON.BACK}
                    icon={{ layout: "round", url: ArrowLongIcon }}
                    onClick={() => {
                        navigate("/home");
                    }}
                />
            </header>
            <img src={getSmiley(currentMood)} alt="" className="mood-smiley" />
            <h2 className="font1">{`${MOOD.HEADER} ${moodsLevel0[currentMood].lang[locales]}!`}</h2>
            <p style={{ marginTop: "0.25rem" }}>{MOOD.SUBHEADER}</p>
            <div className="mood-content">
                <div className="mood-buttons">
                    {data.map((moodItem: Moods) => {
                        return (
                            <ButtonSecondary
                                size="xs"
                                key={moodItem}
                                className={chosenMood.has(moodItem) ? "b-secondary-selected" : ""}
                                onClick={() => {
                                    handleClickedMood(moodItem, chosenMood, "mood");
                                }}
                            >
                                {capitalizeFirstLetter(
                                    moodsLevel1[moodItem as keyof typeof moodsLevel1].lang[locales],
                                )}
                            </ButtonSecondary>
                        );
                    })}
                </div>
            </div>
            <div className="mood-footer">
                <button
                    type="button"
                    className="mood-show-button"
                    onClick={() => {
                        setOpenModal(true);
                    }}
                >
                    {COMMON.SHOW_MORE_ITEMS}
                    <img src={ArrowThinIcon} alt="" />
                </button>
                <ButtonPrimary
                    size="small"
                    onClick={() => {
                        handleSendMood();
                    }}
                >
                    {COMMON.BUTTON_DONE}
                </ButtonPrimary>
            </div>
            <Modal
                isOpen={openModal}
                footerWithButtons={false}
                handleClose={() => {
                    return setOpenModal(false);
                }}
            >
                <div className="mood-modal-header">
                    <ButtonText size="xs" onClick={handleClose}>
                        {COMMON.BUTTON_CANCEL}
                    </ButtonText>
                    <ButtonText size="xs" onClick={handleDone}>
                        {COMMON.BUTTON_READY}
                    </ButtonText>
                </div>
                <div className="mood-modal-content">
                    {Object.entries(moodsLevel1).map(([moodItem, metadata]) => {
                        return (
                            <ButtonSecondary
                                size="xs"
                                key={moodItem}
                                className={tempMood.has(moodItem) ? "b-secondary-selected" : ""}
                                onClick={() => {
                                    handleClickedMood(moodItem, tempMood, "temp");
                                }}
                            >
                                {capitalizeFirstLetter(metadata.lang[locales])}
                            </ButtonSecondary>
                        );
                    })}
                </div>
            </Modal>
        </div>
    );
};

export default Mood;
