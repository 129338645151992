import { GetAvatar } from "@ommej/componente";
import { genderTypes, personTypes } from "@ommej/metadata";
import type { Uuid } from "@ommej/types";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ErrorComponent from "~/src/components/tools/errorComponent/errorComponent";
import Spinner from "~/src/components/tools/spinner/spinner";
import { LanguageContext } from "~/src/contexts/languageContext";
import Logo from "~/src/media/svg/logo_full.svg";
import type { SharedBebModel, SharedBebObject } from "~/src/types";
import { isDemo, request, requestWithAuth } from "~/src/utils/api";
import language from "~/src/utils/language";
import { getHousePath, getPersonalQuestion } from "~/src/utils/utils";
import Header from "../header/header";
import ShareButton, { sharePossible } from "../tools/buttons/shareButton";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./sharedBeb.css";

interface SharedBebProps {
    formId: string;
    formVersion?: string;
}

const SharedBeb = ({ formId, formVersion }: SharedBebProps) => {
    const { locales } = useContext(LanguageContext);
    const { SHARED_BEB, BEB, COMMON } = language[locales];
    const [bebData, setBebData] = useState<SharedBebModel>();
    const [queryParams] = useSearchParams();
    const [token, setToken] = useState<string | null>();
    const [accordion, setAccordion] = useState(false);
    const [isSharePossible, setIsSharePossible] = useState<boolean>(true);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        async function run() {
            try {
                const val = await sharePossible();
                setIsSharePossible(val);

                const tokenParam = queryParams.get("token");
                setToken(tokenParam);

                let res;
                if (tokenParam) {
                    res = await requestWithAuth(
                        `shares/clients/beb/${formId}/${formVersion}`,
                        "GET",
                        tokenParam,
                    );
                } else {
                    res = await request(`shares/clients/beb/${formId}/${formVersion}`, "GET");
                }
                const data = await res.json();
                setBebData(data);
            } catch (err) {
                console.error(err);
                setShowError(true);
            }
        }
        run();
    }, []);

    if (showError) {
        return (
            <ErrorComponent
                handleErrorComponent={() => {
                    window.location.href = "/";
                }}
            />
        );
    }

    if (!bebData) {
        return (
            <div
                className={`content-wrapper ${isDemo ? "app-demo-background" : "content-dark"} ${
                    token && "sharedBeb-padding"
                }`}
            >
                <Spinner />
            </div>
        );
    }

    function getInfoContent(as: "child" | "viewer") {
        if (as === "child") {
            return (
                <>
                    <h2 className="font1" style={{ marginTop: 0, textAlign: "center" }}>
                        {SHARED_BEB.SHARE_HEADING}
                    </h2>
                    {/* empty div to avoid the "roundWrapper-wrapper > p" rule :/ */}
                    <div>
                        <p>{SHARED_BEB.SHARE_TEXT1}</p>
                        <p>{SHARED_BEB.SHARE_TEXT2}</p>
                        <p>{SHARED_BEB.SHARE_TEXT3}</p>
                    </div>
                    <div style={{ display: "grid", placeItems: "center", gap: "1.5rem" }}>
                        <ShareButton
                            buttonText={isSharePossible ? COMMON.BUTTON_SHARE : COMMON.BUTTON_COPY}
                            text={SHARED_BEB.SMS_TEXT}
                        />
                        {!isSharePossible && (
                            <p className="sharedBeb-text-center">{SHARED_BEB.SHARE_COPY}</p>
                        )}
                    </div>
                </>
            );
        }

        return (
            <>
                <h1 className="font1" style={{ textAlign: "center" }}>
                    {SHARED_BEB.PARENT_HEADING}
                </h1>
                <p style={{ textAlign: "left" }}>{SHARED_BEB.PARENT_INGRESS}</p>
                {!accordion && (
                    <button
                        type="button"
                        className="sharedBeb-accordion-button"
                        onClick={() => {
                            setAccordion(!accordion);
                        }}
                    >
                        {COMMON.SHOW_MORE}
                    </button>
                )}
                {accordion && (
                    <>
                        <p style={{ textAlign: "left" }}>{SHARED_BEB.PARENT_TEXT1}</p>
                        <p style={{ textAlign: "left" }}>
                            {SHARED_BEB.PARENT_TEXT4}
                            <a href="mailto:info@ommej.se?subject=Jag vill veta mer om verksamhetsstödet">
                                {SHARED_BEB.PARENT_LINK2}
                            </a>{" "}
                            {SHARED_BEB.PARENT_TEXT5}
                        </p>
                        <button
                            type="button"
                            className="sharedBeb-accordion-button"
                            onClick={() => {
                                setAccordion(!accordion);
                            }}
                        >
                            {COMMON.SHOW_LESS}
                        </button>
                    </>
                )}
            </>
        );
    }

    return (
        <div className={`content-wrapper ${isDemo ? "app-demo-background" : "content-dark"}`}>
            {token ? (
                <img src={Logo} alt={COMMON.OMMEJ_LOGO} className="sharedBeb-logo" />
            ) : (
                <Header
                    header={SHARED_BEB.HEADER}
                    handleBack={() => {
                        window.history.back();
                    }}
                />
            )}

            <RoundWrapper background="var(--color-sand-dark)" className="sharedBeb-info-section">
                {getInfoContent(token ? "viewer" : "child")}
            </RoundWrapper>

            <div className="sharedBeb-white-wrapper">
                {bebData.profile && (
                    <section className="sharedBeb-profile-section">
                        <GetAvatar avatarData={bebData?.profile.avatar} />
                        <p>{`${BEB.BORN} ${bebData?.profile.birthYear}`}</p>
                        <p>
                            {bebData.profile.gender &&
                                `${BEB.GENDER} ${genderTypes[bebData?.profile.gender].sv}`}
                        </p>
                    </section>
                )}
                <hr className="myProfile-hr" />
                <section className="sharedBeb-problems-wrapper">
                    <section className="sharedBeb-problem-section">
                        <h2 className="sharedBeb-header">{SHARED_BEB.HELP_HEADING}</h2>
                        {bebData?.HELP.map((entry: SharedBebObject) => {
                            return entry.answer.data.length > 0 ? (
                                entry.answer.data.map((personId: Uuid) => {
                                    const q = getPersonalQuestion(
                                        bebData.profile,
                                        entry?.question,
                                        personId,
                                    );
                                    return (
                                        <div
                                            key={entry.qid}
                                            className="sharedBeb-problem-card sharedBeb-help-answer"
                                        >
                                            {q}
                                        </div>
                                    );
                                })
                            ) : (
                                <div
                                    className="sharedBeb-problem-card sharedBeb-help-answer"
                                    key={entry.qid}
                                >
                                    {entry.question.text.sv}
                                </div>
                            );
                        })}
                    </section>
                    <section className="sharedBeb-problem-section">
                        <h2 className="sharedBeb-header">{SHARED_BEB.WORRIED_HEADING}</h2>
                        {bebData?.WORRIES.map((entry: SharedBebObject) => {
                            return entry.answer.data.length > 0 ? (
                                entry.answer.data.map((personId) => {
                                    const q = getPersonalQuestion(
                                        bebData.profile,
                                        entry?.question,
                                        personId,
                                    );
                                    return (
                                        <div
                                            key={entry.qid}
                                            className="sharedBeb-problem-card sharedBeb-worry-answer"
                                        >
                                            {q}
                                        </div>
                                    );
                                })
                            ) : (
                                <div
                                    key={entry.qid}
                                    className="sharedBeb-problem-card sharedBeb-worry-answer"
                                >
                                    {entry.question.text.sv}
                                </div>
                            );
                        })}
                    </section>
                    <section className="sharedBeb-problem-section">
                        <h2 className="sharedBeb-header">{SHARED_BEB.PROBLEM_HEADING}</h2>
                        {bebData?.PROBLEM.map((entry: SharedBebObject) => {
                            return entry.answer.data.length > 0 ? (
                                entry.answer.data.map((personId) => {
                                    const q = getPersonalQuestion(
                                        bebData.profile,
                                        entry?.question,
                                        personId,
                                    );
                                    return (
                                        <div
                                            key={entry.qid}
                                            className="sharedBeb-problem-card sharedBeb-problem-answer"
                                        >
                                            {q}
                                        </div>
                                    );
                                })
                            ) : (
                                <div
                                    key={entry.qid}
                                    className="sharedBeb-problem-card sharedBeb-problem-answer"
                                >
                                    {entry.question.text.sv}
                                </div>
                            );
                        })}
                    </section>
                </section>
                <hr className="myProfile-hr" />
                <section className="sharedBeb-persons-section">
                    <h2 style={{ width: "100%" }} className="sharedBeb-header">
                        {SHARED_BEB.PERSONS_HEADING}
                    </h2>
                    {bebData.profile.persons &&
                        Object.entries(bebData.profile.persons).map(([personId, person]) => {
                            return (
                                <div key={personId} className="sharedBeb-person-card">
                                    <GetAvatar avatarData={person.avatar} />
                                    <p>{person.type && personTypes[person.type].lang.sv}</p>
                                </div>
                            );
                        })}
                </section>
                <hr className="myProfile-hr" />
                <section className="sharedBeb-accommodation-section">
                    {bebData.profile.housePersonMap &&
                        Object.entries(bebData.profile.housePersonMap).map(
                            ([houseId, person], index) => {
                                const profileHouse = bebData?.profile?.houses?.[houseId];
                                return (
                                    <div key={houseId} className="sharedBeb-accommodation-card">
                                        <div
                                            style={{
                                                display: "grid",
                                                placeItems: "center",
                                                gap: "0.75rem",
                                            }}
                                        >
                                            <img
                                                src={getHousePath(profileHouse?.avatar)}
                                                alt={`${BEB.HOUSES_ACCOMMODATION} ${index + 1}`}
                                            />
                                            <h3 className="font3">{`${BEB.HOUSES_ACCOMMODATION} ${
                                                index + 1
                                            }`}</h3>
                                        </div>
                                        <div>
                                            {person.length > 0 ? (
                                                <>
                                                    <p className="sharedBeb-person-text">
                                                        {SHARED_BEB.ACCOMMODATION_LIVE}
                                                    </p>
                                                    <div className="sharedBeb-accommodation-persons">
                                                        {person.map((p) => {
                                                            const profilePerson =
                                                                bebData?.profile?.persons?.[p];
                                                            return (
                                                                <div
                                                                    key={p}
                                                                    className="sharedBeb-accommodation-person"
                                                                >
                                                                    <GetAvatar
                                                                        avatarData={
                                                                            profilePerson?.avatar
                                                                        }
                                                                    />
                                                                    <p>
                                                                        {profilePerson?.type &&
                                                                            personTypes[
                                                                                profilePerson.type
                                                                            ].lang.sv}
                                                                    </p>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </>
                                            ) : (
                                                <p className="sharedBeb-person-text">
                                                    {SHARED_BEB.ACCOMMODATION_NO}
                                                </p>
                                            )}
                                        </div>
                                        <hr className="myProfile-hr" />
                                    </div>
                                );
                            },
                        )}
                </section>
                <section className="sharedBeb-ommej-section">
                    <h2 style={{ width: "100%", marginTop: 0 }} className="sharedBeb-header">
                        {SHARED_BEB.OMMEJ_HEADING}
                    </h2>
                    {Object.entries(bebData.OMMEJ).map(([id, question]) => {
                        const questionEntry = question[0];
                        return (
                            <div key={id} className="sharedBeb-ommej-card">
                                <p>{questionEntry.question.text.sv}</p>
                                <p className="sharedBeb-ommej-answer">
                                    {questionEntry.answer.text.sv}
                                </p>
                            </div>
                        );
                    })}
                </section>
            </div>
        </div>
    );
};

export default SharedBeb;
