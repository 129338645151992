import type { ButtonCommon as ButtonCommonProps } from "~/src/types";
import { buttonClassNames } from "~/src/utils/utils";
import "./buttonCommon.css";

const ButtonCommon = ({
    children,
    className,
    size,
    color,
    disabled,
    onClick,
    onMouseOver,
    type = "button",
    buttonType,
    icon,
    testid,
    umamiEvent,
    ariaLabel,
}: ButtonCommonProps) => {
    return (
        <button
            data-testid={testid}
            data-umami-event={umamiEvent}
            aria-label={ariaLabel}
            className={`buttons-wrapper b-${buttonType} b-${size} ${color ? `pb-${color}` : ""} ${
                icon ? buttonClassNames(icon.layout, size) : ""
            } ${className || ""} `}
            // the rule doesn't allow not using static strings for type. But for us,
            // typescript makes sure we send in valid button types, so let's disable
            // the rule here.
            // eslint-disable-next-line react/button-has-type
            type={type}
            onClick={onClick}
            onFocus={onMouseOver}
            onMouseOver={onMouseOver}
            disabled={disabled}
        >
            {icon && icon.layout === "left" && <img src={icon.url} alt="" />}
            {children || (icon && <img src={icon.url} alt="" />)}
            {icon && icon.layout === "right" && <img src={icon.url} alt="" />}
        </button>
    );
};

export default ButtonCommon;
