import { Device } from "@capacitor/device";
import { PushNotifications } from "@capacitor/push-notifications";
import { PhoneInput } from "@ommej/componente";
import { useContext, useEffect, useRef, useState } from "react";
import type * as React from "react";
import { useNavigate } from "react-router-dom";
import Header from "~/src/components/header/header";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import UnderscoreButton from "~/src/components/tools/buttons/underscoreButton";
import PasswordInput from "~/src/components/tools/inputs/passwordInput";
import TextInput from "~/src/components/tools/inputs/textInput";
import { AuthContext } from "~/src/contexts/authContext";
import { LanguageContext } from "~/src/contexts/languageContext";
import ArrowWhiteIcon from "~/src/media/icons/arrow_white.svg";
import Logo from "~/src/media/svg/logo_full.svg";
import { request } from "~/src/utils/api";
import language from "~/src/utils/language";
import { MoodCheckNotifications } from "~/src/utils/moodcheckNotifications";
import Consent from "../profile/consent";
import "./login.css";

enum usernameTypes {
    email = "E-post",
    tel = "Mobilnummer",
}

const STORAGE_LOGIN_METHOD_KEY = "appLoginMethod";

type UsernameTypes = keyof typeof usernameTypes;

async function registerNotifications() {
    await PushNotifications.addListener("registration", async (token) => {
        try {
            const deviceId = await Device.getId();
            await request("clients/notifications/token", "PUT", {
                token: token.value,
                deviceId: deviceId.identifier,
            });
        } catch (e) {
            console.error("Failed to save notification token", e);
        }
    });

    await PushNotifications.addListener("registrationError", (err) => {
        console.error("Failed to register notifications", err.error);
    });

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== "granted") {
        // user do not allow push notifications
        return;
    }

    await PushNotifications.register();
}

type LoginProps = {
    create?: boolean;
};

const Login = ({ create = false }: LoginProps) => {
    const { locales } = useContext(LanguageContext);
    const { COMMON, LOGIN, ACCOUNT, ERROR } = language[locales];
    const userContext = useContext(AuthContext);
    const [consent, setConsent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | JSX.Element>("");
    const [currentUsernameType, setCurrentUsernameType] = useState<UsernameTypes>("tel");
    const eFormSignIn = useRef<HTMLFormElement>(null);
    const numberOfErrors = useRef<number>(0);
    const navigate = useNavigate();

    const resetError = () => {
        setErrorMessage("");
    };

    useEffect(() => {
        let loginMethod = currentUsernameType;
        try {
            const storedLoginMethod = localStorage.getItem(STORAGE_LOGIN_METHOD_KEY);
            if (storedLoginMethod && Object.keys(usernameTypes).includes(storedLoginMethod)) {
                loginMethod = storedLoginMethod as UsernameTypes;
                setCurrentUsernameType(loginMethod);
            }
        } catch (_err) {
            // We're not allowed to use localStorage, that's OK
        }
    }, []);

    const onClick = async (e: React.ChangeEvent<HTMLFormElement>) => {
        resetError();
        e.preventDefault();
        try {
            setLoading(true);
            // formattedValue is set if phone number (via TelInput) was used
            const username = e.target.username.formattedValue || e.target.username.value;
            const { password } = e.target;
            const body = {
                username,
                password: password.value,
            };
            const apiRequest = await request(create ? "clients" : "clients/signin", "POST", body);
            const response = await apiRequest.json();
            userContext.setUser({
                id: response.id,
                auth: true,
                invitations: response.invitations,
            });

            // register for notifications
            try {
                await registerNotifications();
            } catch (_error: unknown) {
                console.error("no notifications");
                // TODO: implement normal notifications
                // do nothing for now, OK do not get notifications
            }
            try {
                await MoodCheckNotifications.schedule();
            } catch (_error: unknown) {
                console.error("no local notifications");
            }
            navigate(create ? "/profile" : "/home");
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error.status >= 500) {
                setErrorMessage(ERROR.SERVER);
            } else if (error.status >= 400) {
                if (!create) {
                    numberOfErrors.current += 1;
                }
                if (!create && numberOfErrors.current > 2) {
                    const helpText = (
                        <>
                            {ERROR.CREDENTIALS_EXTRA}
                            <a href="/resetpwd" className="login-forgot-password">
                                &nbsp;{ERROR.HERE}.
                            </a>
                        </>
                    );
                    setErrorMessage(helpText);
                } else if (create) {
                    setErrorMessage(ERROR.GENERIC_CREATE);
                } else {
                    setErrorMessage(ERROR.CREDENTIALS);
                }
            } else {
                setErrorMessage(ERROR.GENERIC);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const getButtonContent = () => {
        if (loading) {
            return (
                <>
                    <span className="loader" />
                    <span style={{ color: "#fff" }}>{COMMON.LOADING}</span>
                </>
            );
        }
        if (create) {
            return COMMON.BUTTON_CREATE;
        }
        return COMMON.BUTTON_LOGIN;
    };

    if (!consent && create) {
        return (
            <>
                <Header handleBack={handleBack} header={COMMON.TERMS} />
                <Consent
                    handleConsent={() => {
                        setConsent(true);
                    }}
                />
            </>
        );
    }
    return (
        <>
            <Header
                handleBack={handleBack}
                header={create ? ACCOUNT.HEADER_TEXT : LOGIN.HEADER_TEXT}
            />
            {create ? (
                <div className="createAccount-text-wrapper">
                    <h2 className="font1 createAccount-header">{ACCOUNT.HEADING}</h2>
                    <p className="createAccount-text">{ACCOUNT.SUB_HEADING}</p>
                </div>
            ) : (
                <img className="login-logo" src={Logo} alt="Ommej logotype" />
            )}
            <div className="login-username-type-wrapper">
                {Object.entries(usernameTypes).map(([key, value]) => {
                    return (
                        <UnderscoreButton
                            key={key}
                            active={currentUsernameType === key}
                            onClick={() => {
                                setCurrentUsernameType(key as UsernameTypes);
                                resetError();
                                try {
                                    localStorage.setItem(STORAGE_LOGIN_METHOD_KEY, key);
                                } catch (_err) {
                                    // We're not allowed to use localStorage, that's OK
                                }
                            }}
                        >
                            {value}
                        </UnderscoreButton>
                    );
                })}
            </div>
            <form ref={eFormSignIn} onSubmit={onClick}>
                <fieldset className="login-form">
                    <legend>{`${COMMON.LOGIN_WITH} ${usernameTypes[currentUsernameType]}`}</legend>
                    {currentUsernameType === "tel" ? (
                        <PhoneInput
                            wrapperClass="login-input"
                            name="username"
                            error={!!errorMessage}
                        />
                    ) : (
                        <TextInput
                            ariaLabel={COMMON.INPUT_EMAIL}
                            className="login-input"
                            key="changeusername"
                            name="username"
                            onError={!!errorMessage}
                            placeholder={COMMON.INPUT_EMAIL}
                            type="email"
                            autoComplete="email"
                        />
                    )}
                    <PasswordInput error={!!errorMessage} create={create} />
                    <p className="login-error-text">{errorMessage}</p>
                    <ButtonPrimary
                        size="large"
                        type="submit"
                        className="login-button"
                        icon={{ url: ArrowWhiteIcon, layout: "right" }}
                        disabled={loading}
                    >
                        {getButtonContent()}
                    </ButtonPrimary>
                </fieldset>
            </form>
            {!create && (
                <a href="/resetpwd" className="login-forgot-password">
                    {LOGIN.FORGOT_PASSWORD}
                </a>
            )}
        </>
    );
};

export default Login;
