import type { Form } from "@ommej/types";

export type Theme = {
    color: string;
};

export const THEMES: Map<Form.QuestionTags, Theme> = new Map([
    [
        "BB_CARE",
        {
            color: "#094496",
        },
    ],
    [
        "BB_SAFETY",
        {
            color: "#504383",
        },
    ],
    [
        "BB_FEEL_GOOD",
        {
            color: "#ec5e9d",
        },
    ],
    [
        "BB_LEISURE",
        {
            color: "#e9466f",
        },
    ],
    [
        "BB_CONTEXT",
        {
            color: "#ef783d",
        },
    ],
    [
        "BB_RESPONSIBLE",
        {
            color: "#f5a831",
        },
    ],
    [
        "BB_RESPECT",
        {
            color: "#47b378",
        },
    ],
    [
        "BB_DEVELOPING",
        {
            color: "#2bb8bc",
        },
    ],
]);
