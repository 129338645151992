import * as React from "react";
import type { AuthUser } from "~/src/types";

interface AuthContextProps {
    children: React.ReactNode;
}

type UserContextType = {
    user: AuthUser | null;
    setUser: React.Dispatch<React.SetStateAction<AuthUser | null>>;
};

export const AuthContext = React.createContext({} as UserContextType);

export const AuthProvider = ({ children }: AuthContextProps) => {
    const [user, setUser] = React.useState<AuthUser | null>(null);

    const value = React.useMemo(() => {
        return { user, setUser };
    }, [user]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
