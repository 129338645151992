import { useContext, useState } from "react";
import { LanguageContext } from "~/src/contexts/languageContext";
import ErrorImage from "~/src/media/svg/errorImage.svg";
import language from "~/src/utils/language";
import ButtonSecondary from "../buttons/buttonSecondary";
import "./errorComponent.css";

type TErrorText = {
    errorText?: string;
    handleErrorComponent?: () => void;
};

const ErrorComponent = ({ errorText, handleErrorComponent }: TErrorText) => {
    const { locales } = useContext(LanguageContext);
    const { COMMON, ERROR_COMPONENT } = language[locales];
    const [show, setShow] = useState(true);

    const handleClick = () => {
        setShow(false);
    };

    return show ? (
        <div className="errorComponent-wrapper">
            <div className="errorComponent-message-wrapper">
                <h1 className="font1">{ERROR_COMPONENT.HEADING}</h1>
                <p>{errorText || ERROR_COMPONENT.SUB_HEADING}</p>
                <img src={ErrorImage} alt="People looking for error" />
                <ButtonSecondary size="medium" onClick={handleErrorComponent || handleClick}>
                    {COMMON.OK}
                </ButtonSecondary>
            </div>
        </div>
    ) : null;
};

export default ErrorComponent;
