import { AvatarGroups } from "@ommej/metadata";
import type { AvatarId, House, Uuid } from "@ommej/types";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import language from "~/src/utils/language";
import { mapPersonsToHouse, reloadProfile, updatePersons } from "~/src/utils/profileUtils";
import Header from "../header/header";
import Avatar from "../profile/avatar";
import Houses from "../profile/houses";
import Done from "../sprinkles/done";
import ErrorComponent from "../tools/errorComponent/errorComponent";

type TPersonState = {
    state: {
        personId: Uuid;
        ageGroup: AvatarGroups;
        type: string;
        avatar: AvatarId;
    };
};

const ChangePersonPicker = () => {
    const { profile, setProfile } = useContext(ProfileContext);
    const { locales } = useContext(LanguageContext);
    const { CHANGEPERSON_PICKER, ERROR, COMMON } = language[locales];
    const navigate = useNavigate();
    const location = useLocation();
    const [step, setStep] = useState<number>(0);
    const [error, setError] = useState<string | null>(null);
    const personState = location?.state as TPersonState | undefined;

    const handleAvatarClicked = async (avatar: AvatarId) => {
        if (personState && avatar !== personState.state.avatar) {
            const personObjects = [
                {
                    personId: personState.state.personId,
                    personData: { avatar, type: personState.state.type },
                },
            ];
            try {
                await updatePersons(personObjects);
                await reloadProfile(setProfile);
            } catch (err: unknown) {
                console.error("error:", err);
                setError(ERROR.UPDATE_AVATAR);
            }
        }
        setStep(step + 1);
    };

    const handleHouseClicked = async (house: House | undefined) => {
        if (personState && house) {
            const currentHouse = house.id && profile?.housePersonMap?.[house.id];
            const personArr = currentHouse && [...currentHouse, personState.state.personId];
            if (house.id && personArr) {
                try {
                    await mapPersonsToHouse(house.id, personArr);
                    await reloadProfile(setProfile);
                } catch (err: unknown) {
                    console.error("error:", err);
                    setError(ERROR.UPDATE_ACCOMMODATION);
                }
            }
        }
        setStep(step + 1);
    };

    const handleErrorComponent = () => {
        setError(null);
    };

    const stepper = [
        {
            component: (
                <Avatar
                    handleAvatarClicked={handleAvatarClicked}
                    state={{ ageGroup: personState?.state.ageGroup || AvatarGroups.ALL }}
                    avatarExists={
                        personState && {
                            avatarId: personState.state.avatar,
                            personId: personState.state.personId,
                        }
                    }
                />
            ),
            headerText: CHANGEPERSON_PICKER.HEADING,
        },
        {
            component: (
                <Houses
                    handleHouseClicked={handleHouseClicked}
                    houseExists
                    personId={personState?.state.personId}
                />
            ),
            headerText: CHANGEPERSON_PICKER.HEADING,
        },
        {
            component: <Done navigateUrl="/myprofile" text={COMMON.SAVED} />,
            headerText: "",
        },
    ];

    if (error) {
        return <ErrorComponent errorText={error} handleErrorComponent={handleErrorComponent} />;
    }
    return (
        <div className="content-wrapper">
            {step <= 1 && (
                <Header
                    header={stepper[step].headerText && stepper[step].headerText}
                    handleBack={() => {
                        return step > 0 ? setStep(step - 1) : navigate(-1);
                    }}
                />
            )}
            {stepper[step].component}
        </div>
    );
};

export default ChangePersonPicker;
