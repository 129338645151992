import * as React from "react";
import type { Language } from "~/src/types";

type LanguageContextProps = {
    children: React.ReactNode;
};

type LanguageContextType = {
    locales: Language;
    setLocales: React.Dispatch<React.SetStateAction<Language>>;
};

export const LanguageContext = React.createContext({} as LanguageContextType);

export const LanguageProvider = ({ children }: LanguageContextProps) => {
    const [locales, setLocales] = React.useState<Language>("sv");

    const value = React.useMemo(() => {
        return { locales, setLocales };
    }, [locales]);

    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};
