import { useEffect, useRef } from "react";
import type * as React from "react";
import ButtonPrimary from "./buttons/buttonPrimary";
import ButtonText from "./buttons/buttonText";
import "./modal.css";

type DialogProps = {
    children: React.ReactNode;
    isOpen: boolean;
    footerWithButtons?: boolean;
    buttonPositive?: string;
    buttonCancel?: string;
    onClickPositive?: () => void;
    handleClose: () => void;
};

const Modal = ({
    children,
    isOpen,
    handleClose,
    footerWithButtons = true,
    onClickPositive,
    buttonPositive,
    buttonCancel,
}: DialogProps) => {
    const dialogElement = useRef<HTMLDialogElement | null>(null);

    useEffect(() => {
        if (isOpen) {
            dialogElement.current?.showModal();
            document.body.classList.add("modal-prevent-scroll");
        } else {
            dialogElement.current?.close();
        }
        return () => {
            document.body.classList.remove("modal-prevent-scroll");
        };
    }, [isOpen]);

    const handleModalClose = () => {
        document.body.classList.remove("modal-prevent-scroll");
        handleClose();
    };

    if (!isOpen) {
        return null;
    }
    return (
        <dialog ref={dialogElement} onClose={handleModalClose}>
            <div className="modal-content">{children}</div>
            {footerWithButtons && (
                <footer className="modal-footer">
                    {buttonPositive && (
                        <ButtonPrimary size="medium" onClick={onClickPositive}>
                            {buttonPositive}
                        </ButtonPrimary>
                    )}
                    {buttonCancel && (
                        <ButtonText size="medium" onClick={handleClose}>
                            {buttonCancel}
                        </ButtonText>
                    )}
                </footer>
            )}
        </dialog>
    );
};

export default Modal;
