import { Preferences } from "@capacitor/preferences";

const STORAGE_KEY_SETTINGS = "ommej-app-settings";

export const Storage = {
    async set(key: string, value: string) {
        await Preferences.set({ key, value });
    },

    async get(key: string) {
        const { value } = await Preferences.get({ key });
        return value;
    },

    async remove(key: string) {
        await Preferences.remove({ key });
    },
};

export const Settings = {
    async set(key: string, value: string | boolean | number) {
        const current = await Storage.get(STORAGE_KEY_SETTINGS);
        try {
            const currentSettings = JSON.parse(current || "{}");
            currentSettings[key] = value;
            await Storage.set(STORAGE_KEY_SETTINGS, JSON.stringify(currentSettings));
        } catch (_e) {
            // synax error, let's clear all settings
            await Storage.remove(STORAGE_KEY_SETTINGS);
        }
    },

    get: (async (key?: string) => {
        let currentSettings: Record<string, string | boolean | number> = {};
        const current = await Storage.get(STORAGE_KEY_SETTINGS);
        try {
            currentSettings = JSON.parse(current || "{}");
        } catch (_e) {
            // synax error, let's clear all settings
            await Storage.remove(STORAGE_KEY_SETTINGS);
        }
        if (key) {
            return currentSettings[key];
        }
        return currentSettings;
    }) as {
        (key: string): Promise<string>;
        (key?: undefined): Promise<Record<string, string | boolean | number>>;
    },
};
