import type { ButtonSecondary as ButtonSecondaryProps } from "~/src/types";
import ButtonCommon from "./buttonCommon";
import "./buttonSecondary.css";

const ButtonSecondary = ({
    children,
    className,
    size,
    disabled,
    onClick,
    onMouseOver,
    type = "button",
    icon,
    ariaLabel,
    testid,
}: ButtonSecondaryProps) => {
    return (
        <ButtonCommon
            className={className}
            size={size}
            disabled={disabled}
            onClick={onClick}
            onMouseOver={onMouseOver}
            type={type}
            buttonType="secondary"
            icon={icon}
            testid={testid}
            ariaLabel={ariaLabel}
        >
            {children}
        </ButtonCommon>
    );
};

export default ButtonSecondary;
