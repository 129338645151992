import type { Form, Invitation, InvitationClient } from "@ommej/types";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorComponent from "~/src/components/tools/errorComponent/errorComponent";
import { AuthContext } from "~/src/contexts/authContext";
import { request } from "~/src/utils/api";
import { FORM_DEFAULT_ID, FORM_DEFAULT_VERSION } from "~/src/utils/constants";

type NextForm = {
    id: Form.Ref["id"];
    version: Form.Ref["version"];
    requestId?: string;
    invitationId?: string;
};

/*
   For each accepted invitation, we only add the latest form request for each
   form (defined as formId-formVersion) to the stack of forms that the user
   should answer.

   Example for one invitation:
     req1 = {
        created: x
        formA
     }
     req2 = {
        created: x + 1
        formB
     }
     req3 = {
        created: x + 2
        formA
     }

     Only req2.formB and req3.formA will be presented to the user, regardless
     of the state of req1.formA. req3.formA will be presented before req2.formB
     (i.e., last form added will be prioritized).
*/
function getNextForm(acceptedInvitations: InvitationClient[] | undefined): NextForm | undefined {
    let next;

    if (!acceptedInvitations) {
        return next;
    }

    const requests: Array<Invitation.FormRequest & { invitationId: string | undefined }> = [];
    for (const invitation of acceptedInvitations) {
        if (
            !invitation.forms ||
            invitation.status?.status === "paused" ||
            invitation.status?.status === "closed"
        ) {
            continue;
        }

        // keeps track of the latest request for each form
        const formLastRequest = new Map<string /* formKey */, Invitation.FormRequest>();
        for (const req of invitation.forms) {
            const formKey = `${req.form.id}-${req.form.version}`;
            if (!formLastRequest.has(formKey)) {
                formLastRequest.set(formKey, req);
            }

            if (req.created < (formLastRequest.get(formKey)?.created ?? 0)) {
                // if the current request is created before the latest request for this
                // form, then we should skip it. We don't care if the older request is
                // answered or not.
                continue;
            }
            formLastRequest.set(formKey, req);
        }

        for (const req of formLastRequest.values()) {
            if (req.done) {
                continue;
            }
            requests.push({ ...req, invitationId: invitation.id });
        }
    }

    const requestsSorted = requests.sort((reqA, reqB) => {
        if (reqA.created < reqB.created) {
            return 1;
        }
        if (reqA.created > reqB.created) {
            return -1;
        }
        return 0;
    });

    if (requestsSorted.length > 0) {
        const latest = requestsSorted[0];
        next = {
            id: latest.form.id,
            version: latest.form.version,
            requestId: latest.id,
            invitationId: latest.invitationId,
        };
    }
    return next;
}

const FormHandler = () => {
    const navigate = useNavigate();
    const userContext = useContext(AuthContext);
    const [showError, setShowError] = useState(false);

    async function load() {
        try {
            const res = await request("clients/invitations", "GET");
            const resInvitations = await res.json();
            if (!userContext.user) {
                return;
            }
            const currentUser = { ...userContext.user, invitations: resInvitations };
            userContext.setUser(currentUser);

            const nextForm = getNextForm(currentUser.invitations.accepted);
            if (nextForm) {
                navigate(
                    nextForm.invitationId
                        ? `/form/${nextForm.id}/${nextForm.version}/${nextForm.invitationId}/${nextForm.requestId}`
                        : `/form/${nextForm.id}/${nextForm.version}`,
                    { replace: true },
                );
            } else {
                navigate(`/form/${FORM_DEFAULT_ID}/${FORM_DEFAULT_VERSION}`, { replace: true });
            }
        } catch (err) {
            console.error(err);
            setShowError(true);
        }
    }

    useEffect(() => {
        load();
    }, []);

    if (showError) {
        return (
            <ErrorComponent
                handleErrorComponent={() => {
                    navigate("/");
                }}
            />
        );
    }

    return null;
};

export default FormHandler;
