import type { Uuid } from "@ommej/types";
import * as React from "react";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import { LanguageContext } from "~/src/contexts/languageContext";
import language from "~/src/utils/language";
import AnswersText from "./answersText";
import type { QuestionView } from "./form";
import "./form.css";
import FormFooter from "./formFooter";
import FormQuestion from "./formQuestion";

const QuestionSingle: React.FC<QuestionView> = ({ question, setAnswer, existingAnswer }) => {
    const { locales } = React.useContext(LanguageContext);
    const { COMMON, FORM } = language[locales];
    const onAnswerClick = (answerId: Uuid) => {
        setAnswer(question.id, [{ answer: answerId, data: [], timestamp: new Date() }]);
    };

    return (
        <div className="form-question-wrapper">
            <FormQuestion text={question.question.text.sv} />
            <div className="form-answers">
                {Object.entries(question.question.answers).map(([answerId, answer]) => {
                    switch (answer.type) {
                        case "text":
                            return (
                                <AnswersText
                                    testid="form-answer-alternative"
                                    key={answerId}
                                    text={answer.text.sv}
                                    onClick={() => {
                                        onAnswerClick(answerId);
                                    }}
                                    type={
                                        Object.keys(question.question.answers).length < 4
                                            ? "big"
                                            : "small"
                                    }
                                    marked={existingAnswer?.[0].answer === answerId}
                                />
                            );
                        case "none":
                            return (
                                <FormFooter key={answerId}>
                                    <ButtonPrimary
                                        size="large"
                                        testid="form-answer-alternative"
                                        onClick={() => {
                                            onAnswerClick(answerId);
                                        }}
                                    >
                                        {COMMON.BUTTON_NEXT}
                                    </ButtonPrimary>
                                </FormFooter>
                            );
                        default:
                            return <span key={answerId}>{FORM.UNKNOWN}</span>;
                    }
                })}
            </div>
        </div>
    );
};

export default QuestionSingle;
