{
    "COMMON": {
        "OMMEJ": "Ommej",
        "WHAT_IS_OMMEJ": "Vad är Ommej?",
        "BUTTON_CREATE": "Skapa konto",
        "BUTTON_LOGIN": "Logga in",
        "BUTTON_READY": "Klar",
        "BUTTON_DONE": "Färdig",
        "BUTTON_CLICK": "Tryck",
        "BUTTON_CHANGE": "Ändra",
        "BUTTON_ALL": "Se alla",
        "BUTTON_NEXT": "Nästa",
        "BUTTON_NONE": "Ingen",
        "BUTTON_NO_HOUSE": "Inget boende",
        "BUTTON_CONTINUE": "Fortsätt",
        "BUTTON_CONTINUE_ANSWER": "Fortsätt svara",
        "BUTTON_SITE": "Gå till sajten",
        "BUTTON_ADD": "Lägg till",
        "BUTTON_PREVIEW": "Förhandsgranska",
        "BUTTON_SEND": "Skicka",
        "BUTTON_SHARE": "Dela",
        "BUTTON_COPY": "Kopiera länk",
        "BUTTON_MYSTORY": "Min berättelse",
        "BUTTON_SUPPORT": "Få stöd",
        "BUTTON_JOIN": "Gå med",
        "BUTTON_CANCEL": "Avbryt",
        "BUTTON_JUMP": "Hoppa över",
        "BUTTON_ACCEPT": "Acceptera",
        "BUTTON_DECLINE": "Tacka nej",
        "BUTTON_GET": "Hämta",
        "BUTTON_OK": "Okej",
        "INPUT_MOBILE": "Telefonnummer",
        "INPUT_EMAIL": "E-postadress",
        "INPUT_USERNAME": "Användarnamn",
        "INPUT_PASSWORD": "Lösenord",
        "INPUT_CREATING_PASSWORD": "Lösenord (minst 6 tecken)",
        "INPUT_KEY": "Inbjudningslänk",
        "VIDEO": "Tyvärr stödjer inte din webbläsare inbäddade video.",
        "YES": "Ja",
        "NO": "Nej",
        "OK": "OK",
        "VIDEO_OMMEJ": "Vad är Ommej?",
        "VIDEO_CONVENTION": "Barnkonventionen",
        "CHANGE_AVATAR": "Ändra avatar",
        "CHANGE_BIRTHYEAR": "Ändra födelseår",
        "CHANGE_GENDER": "Ändra kön",
        "AVATAR": "Avatar",
        "LOGOUT": "Logga ut",
        "OMMEJ_LOGO": "Ommejs logotyp",
        "NO_CHOSEN": "Inget valt",
        "SAVED": "Sparat",
        "OFFLINE": "Ditt nätverk verkar vara nere och då fungerar inte appen som den ska. Så fort ditt nätverk är tillbaka kan du använda appen igen.",
        "READ_MORE": "Läs mer",
        "APPROVE": "Jag godkänner",
        "TERMS": "Villkor",
        "SHOW_MORE": "Visa mer",
        "SHOW_MORE_ITEMS": "Visa fler",
        "SHOW_LESS": "Visa mindre",
        "LOADING": "Laddar",
        "CHOOSE": "Välj",
        "INACTIVE_ANSWERS": "Tidigare svar",
        "INACTIVE_ANSWERS_INFO": "Här finns några av dina gamla svar som vi inte längre är säkra på stämmer för att din berättelse ändrats. Det kan till exempel vara för att du gått tillbaka och ändrat något svar. Om du fortsätter svara på frågor kan de komma tillbaka.",
        "CODE": "Kod",
        "INVALID_INVITATION": "Du har fått en inbjudan som är ogiltlig. Kontakta den som bjudit in dig för en ny inbjudan.",
        "INVALID_INVITATION_FULL": "Denna inbjudan har redan accepterats, antingen av dig eller någon annan. Kontakta den som bjudit in dig om något verkar fel.",
        "ARE_YOU_SURE": "Är du säker?",
        "BACK": "Tillbaka",
        "OR": "Eller",
        "HOME": "Hem",
        "SETTINGS": "Inställningar",
        "ALLOW_NOTIFICATIONS": "Tillåt notiser",
        "MOODCHECK": "Moodcheck",
        "NOTIFICATION_SYS_SETTINGS_1": "För att få notifieringar behöver du tillåta det i systeminställningarna.",
        "NOTIFICATION_SYS_SETTINGS_2": "Tryck här för att öppna inställningar.",
        "REMOVE": "Ta bort",
        "DAYS": "Dagar",
        "DAY": "Dag",
        "LOGIN_WITH": "Logga in med"
    },
    "PROMPT": {
        "TEXT": "Ommej",
        "SUB_TEXT": "Dela din värld med andra"
    },
    "ERROR": {
        "CREDENTIALS": "Fel användarnamn eller lösenord",
        "CREDENTIALS_EXTRA": "Fel användarnamn eller lösenord. Har du glömt lösenordet kan du återställa det",
        "HERE": "här",
        "AUTH": "Du har inte behörighet",
        "SERVER": "Vi får inte kontakt med vår server",
        "GENERIC": "Något gick tyvärr fel",
        "GENERIC_CREATE": "Något gick tyvärr fel. Kanske har du redan ett konto?",
        "PROFILE": "Något gick fel när vi försökte uppdatera profilen",
        "UPDATE_AVATAR": "Något gick fel när vi försökte uppdatera avataren",
        "UPDATE_ACCOMMODATION": "Något gick fel när vi försökte uppdatera boendet"
    },
    "ERROR_COMPONENT": {
        "HEADING": "Hoppsan!",
        "SUB_HEADING": "Det verkar som om något gick fel. Vi jobbar på att lösa problemet!",
        "IMG_TEXT": "Människor som fixar ett problem"
    },
    "START": {
        "HEADING": "Välkommen till",
        "SUBHEADER": "Logga in eller skapa ett nytt konto för att berätta din historia.",
        "ACCOUNT": "Har du inget konto?",
        "CREATE": "Skapa ett här",
        "OMMEJ_VIDEO": "Se video om Ommej!"
    },
    "LOGIN": {
        "HEADER_TEXT": "Logga in",
        "FORGOT_PASSWORD": "Glömt lösenordet?"
    },
    "RESET_PASSWORD": {
        "HEADING_CHANGE": "Ändra lösenord",
        "HEADING_RESET": "Återställ lösenord",
        "PASSWORD_AGAIN": "Upprepa lösenord",
        "CHECK_EMAIL": "Om vi har ett konto som använder emailadressen så kommer du att få ett email. Kolla din inkorg så får du hjälp att återställa ditt lösenord.",
        "CHECK_SMS": "Om vi har ett konto som använder mobilnumret så kommer du att få ett SMS. Kolla din telefon så får du hjälp att återställa ditt lösenord.",
        "ERROR_PASSWORD": "Lösenorden är inte giltiga eller stämmer inte överens, var vänlig försök igen"
    },
    "RESET_USERNAME": {
        "EXPLAIN": "För att gå vidare behöver du ange antingen din e-postadress eller till mobilnummer men du är fortfarande helt anonym. Vi kommer inte be dig om det här fler gånger. Detta kommer att bli ditt nya användarnamn. Tack!"
    },
    "MIGRATION": {
        "LOGIN_OLD": "Välkommen tillbaka till Ommej! Vi har gjort om appen så att den blir bättre, snyggare och roligare för dig. Logga in som vanligt så får du se!",
        "HEADER": "Viktigt meddelande!",
        "TEXT1": "Vi har gjort lite uppdateringar så att appen ska bli ännu bättre för dig.",
        "TEXT2": "Detta kan innebära att du får frågor som liknar något du redan har svarat på tidigare. Hoppas du vill svara igen!"
    },
    "ACCOUNT": {
        "HEADER_TEXT": "Skapa konto",
        "HEADING": "Välkommen till Ommej",
        "SUB_HEADING": "För att skapa ett konto, börja med att ange din email eller ditt telefonnummer samt ett lösenord"
    },
    "HOME": {
        "ANSWER_QUESTIONS": "Svara på frågor",
        "MY_STORY": "Min berättelse",
        "MY_INVITATIONS": "Kontakter",
        "MY_PROFILE": "Min profil",
        "MY_PROFILE_MAKE_READY": "Gör färdigt profil",
        "SUPPORT": "Få stöd",
        "REQUESTS_HEADER": "Frågor som skickats till dig",
        "MODAL_MOOD_HEADER": "Bra jobbat!",
        "MODAL_MOOD_TEXT1": "Du har loggat ditt mood",
        "MODAL_MOOD_IN_A_ROW": "i rad",
        "MODAL_MOOD_TEXT2": "Vill du fortsätta berätta om ditt liv?",
        "MODAL_MOOD_TEXT2_NO_PROFILE": "Färdigställ gärna din profil så vi kan börja med frågorna!",
        "BUTTON_YES": "Fortsätt berätta",
        "BUTTON_CANCEL": "Inte nu",
        "MOOD_TODAY": "Dagens mood",
        "MOODSTREAK": "Moodstreak"
    },
    "MOOD": {
        "HEADER": "Mitt mood idag är",
        "SUBHEADER": "Så här känner jag också...",
        "API_ERROR": "Något gick fel när vi försökte spara ditt mood!",
        "WRAPPER_HEADER": "Mina moods",
        "WRAPPER_API": "Något gick fel när vi skulle ladda dina moods",
        "WRAPPER_TEXT": "Dina moods visar ditt humör över tid. Här kan du se hur ditt humör har svängt upp och ner och kanske till och med förstå varför du varit extra ledsen eller glad.",
        "EMPTY": "Du har inga moods registrerade. Fyll i ditt första mood i hemvyn."
    },
    "SEE_ANSWERS": {
        "HEADER": "Får jag se dina svar?",
        "TEXT": " vill skapa kontakt med dig och se dina svar i Ommej-appen",
        "ADMIN": "En handläggare"
    },
    "AVATAR": {
        "HEADER_TEXT": "Skapa konto",
        "SUBHEADER": "Det här är min ",
        "CHOOSE": "Välj din avatar",
        "CREATE_OWN": "Skapa egen",
        "EDIT": "Ändra din avatar"
    },
    "BIRTHYEAR": {
        "HEADER_TEXT": "Skapa konto",
        "HEADING": "Vilket år ",
        "HEADING_BORN": "är du född?",
        "SUBHEADING": "Fyll i ditt födelseår (4 siffror)."
    },
    "GENDER": {
        "FEMALE": "Flicka",
        "MALE": "Pojke",
        "NEUTRAL": "Icke-binär",
        "HEADER_TEXT": "Skapa konto",
        "HEADING": "Jag är:",
        "VIDEO_TEXT": "Könsidentitet i Sverige"
    },
    "HOUSES": {
        "HEADER_TEXT": "Skapa konto",
        "HEADER": "Här bor jag",
        "SUBHEADER": "Du kan välja flera ställen, börja med ett",
        "SUBHEADER_HOUSEEXISTS": "Var bor din ",
        "HOUSE": "Boende",
        "DETACHED_HOUSE": "Villa",
        "APARTMENT": "Lägenhet",
        "ROW_HOUSE": "Radhus",
        "OTHER_HOUSE": "Annat",
        "BUTTON_NO": "Bor inte med mig"
    },
    "ACCOMMODATION": {
        "HEADER_TEXT": "Här bor jag med",
        "HOUSE": "Boende",
        "ADD_PERSON": "Lägg till person",
        "FAMILY": "Familj",
        "EXTENDED_FAMILY": "Förlängd familj",
        "RELATIVE": "Släktingar",
        "RELATIONS": "Relationer",
        "OTHERS": "Övriga"
    },
    "MOREACCOMMODATION": {
        "HEADER_TEXT": "Bor du på fler ställen?"
    },
    "GREAT": {
        "HEADING": "Bra jobbat!",
        "SUB_HEADING": "Din profil är klar. Är du redo för att börja svara på några frågor?",
        "IMG_TEXT": "Barn firar med konfetti"
    },
    "AVATAR_PICKER": {
        "HEADING": "Välj Avatar"
    },
    "BIRTHYEAR_PICKER": {
        "HEADING": "Välj födelseår"
    },
    "GENDER_PICKER": {
        "HEADING": "Välj ditt kön"
    },
    "HOUSE_PICKER": {
        "HEADING": "Välj ett nytt hus"
    },
    "ACCOMMODATION_PICKER": {
        "ADD_HOUSE": "Lägg till ett boende",
        "ADD_PERSONS": "Välj vem du bor med",
        "PERSON_CREATED": "Person skapad",
        "CHANGED": "Boendet ändrat",
        "CREATED": "Boende tillagt"
    },
    "CHANGEPERSON_PICKER": {
        "HEADING": "Ändra person"
    },
    "MY_PROFILE": {
        "HEADER": "Min profil",
        "BORN": "Jag är född år",
        "GENDER": "Jag är",
        "NO_GENDER": "Du har inte valt kön",
        "DELETE_PERSON": "Ta bort person",
        "LIVING": "Här bor jag",
        "PERSONS_LIVING": "Personer som bor här",
        "ACCOMMODATION": "Boende",
        "ADD_ACCOMMODATION": "Lägg till boende",
        "PERSONS": "Andra personer i mitt liv",
        "DELETE_ACCOUNT": "Radera mitt konto"
    },
    "DELETE_ACCOUNT": {
        "HEADER": "Radera konto",
        "HEADING_SURE": "Är du säker på att du vill radera ditt konto?",
        "HEADING_KEEP_ANSWER": "Får vi behålla dina svar?",
        "HEADING_DELETED": "Ditt konto är raderat!",
        "TEXT_SURE": "Du kan inte ångra dig senare. När du tagit bort ditt konto behöver du skapa ett nytt konto för att kunna använda Ommej igen.",
        "TEXT_KEEP_ANSWER": "Ommej vill gärna använda dina svar så att vi kan hjälpa fler barn, är det OK? Svaren kan inte kopplas till dig på något sätt.",
        "BUTTON_CONTINUE": "Ja, fortsätt",
        "BUTTON_KEEP": "Det går bra!",
        "BUTTON_ERASE": "Nej, ta bort allt"
    },
    "FORM": {
        "UNKNOWN": "Något blev fel med frågan",
        "MULTIPLE": "(Du kan välja flera alternativ)",
        "CHOOSE_ACCOMMODATION": "(Markera de boende som passar in)",
        "LIVES_WITH": "Här bor jag med",
        "LAST_ANSWER_DATE": "Du svarade på den här frågan",
        "AGREE": "Stämmer väldigt bra",
        "DISAGREE": "Stämmer inte alls",
        "TO_QUESTIONS": "Berätta",
        "HEADER": "Frågor från"
    },
    "SUPPORT": {
        "HEADER": "Få stöd",
        "SOS": "Ring SOS alarm",
        "STORY": "Dela min berättelse med någon jag känner",
        "CONTACT": "Vi gör vårt bästa för att se till att alla öppettider i listan stämmer men ibland kan något ändras innan vi hinner märka det. Om du vill får du gärna meddela oss om du finner ett fel:"
    },
    "BEB": {
        "HEADER": "Min berättelse",
        "NO_DATA": "Här kommer din berättelse att visas när du har svarat på några frågor.",
        "I_AM": "Jag är",
        "YEAR": "år",
        "BORN": "Jag föddes",
        "GENDER": "Min könsidentitet är",
        "GOOD_THINGS": "Bra saker i mitt liv",
        "IMPORTANT_THINGS": "Det här är viktigt för mej",
        "ANSWER_PERSON": "Svar om personer",
        "PERSON_IMG": "personer i mitt liv",
        "ANSWER": "Svar",
        "MYSELF": "Om mej",
        "SCHOOL": "Om skolan",
        "FAMILY": "Om min familj",
        "LEISURE": "Om min fritid",
        "HOUSES": "Om boende",
        "BASE": "Övriga frågor och svar",
        "TAG_MYSELF": "Mej själv",
        "TAG_SCHOOL": "Skola",
        "TAG_FAMILY": "Familj",
        "TAG_LEISURE": "Fritid",
        "TAG_BASE": "Övrigt",
        "CHANGE_QUESTION": "Ändra svar på fråga",
        "REPLACE": "Den eller dessa ",
        "GOOD_HEADER": "Bra saker i mitt liv",
        "GOOD_IMG": "Människor som firar",
        "GOOD_NOTHING": "Inget här än! Men det kommer.",
        "GOOD_TEXT": "<p>Även om inte livet alltid är lätt finns det flera saker som är bra i ditt liv och som är viktiga för att du ska ha det bra.</p><br /><p class=\"bold-text\">Här är några bra och viktiga saker som du berättat om!</p>",
        "IMPORTANT_BUTTON": "Till stödsidan",
        "IMPORTANT_HEADER": "Detta är viktigt för mej",
        "IMPORTANT_IMG": "Flicka som är orolig",
        "IMPORTANT_NOTHING": "Vi har inte hittat något som gör dig orolig. Vad bra!",
        "IMPORTANT_TEXT": "<p>Här nedan står alla saker som du har sagt att du är orolig för eller vill ha hjälp med.</p><br /><p class=\"bold-text\">I Få stöd hittar du massor av tips och stöd om vart du kan vända dig.</p>",
        "PERSONS_HEADER": "Om personer i mitt liv",
        "PERSONS_IMG": "Avatar",
        "HOUSES_HEADER": "Om boende",
        "HOUSES_ACCOMMODATION": "Boende",
        "BUTTON_SHARE": "Dela min berättelse",
        "SHARE_TRUST": "Vill du dela din berättelse med någon du känner och litar på?",
        "SHARE_TELL": "Tänk på att den du delar med inte får se ditt namn, så det kan vara bra att berätta för dem när du skickat.",
        "SHARE_IMG": "Människor som skickar brev",
        "START_OVER_HEADER": "Vill du börja om?",
        "START_OVER_BUTTON": "Börja om",
        "START_OVER_TEXT_1": "Du kan välja att rensa alla dina svar ovan för att bygga din berättelse från början igen.",
        "START_OVER_TEXT_2": "Dina personer, boenden, avatarer samt nedanstående svar försvinner inte.",
        "START_OVER_CONFIRM_TEXT": "Du kan inte ångra dig senare. Dina svar rensas och du får börja om från början igen.",
        "START_OVER_DONE": "Dina svar är rensade!",
        "FORMS_ANSWER_HEADER": "Övriga svar",
        "FORMS_ANSWER_INFO": "Här visas de svar som dina kontakter har bett om, dessa kan du inte ändra eller ta bort"
    },
    "SHARED_BEB": {
        "HEADER": "Dela min berättelse",
        "SHARE_HEADING": "Berätta din historia",
        "SHARE_TEXT1": "Här kan du dela din berättelse med någon du känner och litar på. Personen du delar med kommer kunna se dina svar i två veckor.",
        "SHARE_TEXT2": "Tänk på att den du delar med inte får se ditt namn så det kan vara bra att berätta för dem när du skickat.",
        "SHARE_TEXT3": "Nedanför ser du en förhandsvisning av hur din berättelse kommer se ut för den du skickar till. Tänk på att om du ändrar dina svar så kommer även den delade berättelsen att uppdateras. Om allt är OK, tryck på knappen nedan.",
        "SHARE_COPY": "Dela den här länken med någon du vill ska se dina svar.",
        "HELP_HEADING": "Jag vill ha hjälp med",
        "WORRIED_HEADING": "Jag är orolig för",
        "PROBLEM_HEADING": "Jag upplever",
        "PERSONS_HEADING": "Dessa personer finns i mitt liv",
        "ACCOMMODATION_LIVE": "Personer som bor här",
        "ACCOMMODATION_NO": "Ingen som bor här",
        "OMMEJ_HEADING": "Vad jag tycker om Ommej",
        "PARENT_HEADING": "Ett barn vill berätta något för dig!",
        "PARENT_INGRESS": "Nedan ser du en sammanställning av vad ett barn har berättat om i Ommej att hen är orolig för eller vill ha hjälp med i sitt liv, och vad hen sagt är \"lugnt\". Barnet har valt att dela den här information med dig eftersom du är en viktig person i deras liv.",
        "PARENT_TEXT1": "Ommej är en app där barn och unga kan berätta om hur de mår, i alla olika delar av sina liv, få information och lotsas till stöd och hjälp. Svaren du ser reflekterar barnets upplevelse just nu. Om barnet uppdaterar svaren i Ommej-appen kommer sidan att uppdateras.",
        "PARENT_TEXT2": "I en kommande app för vuxna kommer du som valts ut av barnet att kunna se exakt vad barnet har svarat på varje enskild fråga i Ommej - om barnet så önskar. Där kommer också att finnas råd och information samt tips på sätt att stötta barnet och mycket mer. Är du nyfiken på appen för föräldrar, vårdnadshavare och andra viktiga vuxna? ",
        "PARENT_LINK1": "Skriv upp dig här ",
        "PARENT_TEXT3": "för att bli informerad om när den släpps eller  få vara med och förhandstesta appen och tycka till. Det du gör kan hjälpa många barn.",
        "PARENT_TEXT4": "Kommer du från en verksamhet som arbetar med barn? För dig finns det en admin-app som kan kopplas samman med barnappen och som möjliggör större delaktighet för barnen, systematisk uppföljning, kvalitetssäkring och sömlös samverkan inom och mellan verksamheter, bland mycket annat. ",
        "PARENT_LINK2": "Kontakta oss här",
        "PARENT_TEXT5": "för en gratis demo.",
        "SMS_TEXT": "Grattis! Du är en viktig person för ett barn som vill skicka dig sin berättelse om hur de har det via appen Ommej."
    },
    "REQUEST_FORM": {
        "HEADER": "Färdiga formulär"
    },
    "ALLQUESTION": {
        "HEADING": "Grymt jobbat!",
        "SUBHEADING": "Nu har du svarat på alla frågor som finns just nu. Du kan vara riktigt stolt över dig själv!",
        "IMG": "Människor firar",
        "CHANGE_ANSWER": "Om du vill ändra vissa svar eller dela dem med någon kan du göra det i",
        "SUPPORT1": "Glöm inte att du alltid kan",
        "SUPPORT2": "söka stöd"
    },
    "REQUESTS": {
        "HEADER": "Kontakter",
        "ACCEPTED": "Kan se mina svar",
        "PENDING": "Vill se mina svar",
        "ACCEPT": "Godkänn",
        "DECLINE": "Avböj",
        "CREATED": "Skapad",
        "NO_INVITATIONS": "Inga förfrågningar",
        "PAUSED": "Den här inbjudan är för tillfället pausad och din handläggare kollar just nu inte dina svar.",
        "CLOSED": "Den här inbjudan är stängd och handläggaren kollar inte längre på svaren."
    },
    "CODE": {
        "HEADER": "Skriv in kod",
        "HEADER_NORMAL": "Har du fått en kod?",
        "SUBHEADER_NORMAL": "Skriv in koden du fått för att tacka ja till inbjudan.",
        "HEADER_SUCCESS": "Toppen!",
        "SUBHEADER_SUCCESS": "Du är nu ansluten till",
        "HEADER_FAILURE": "Oops!",
        "SUBHEADER_FAILURE": "Vi hittade tyvärr ingen inbjudan med koden du angav. Prova att testa igen, annars kontakta den som bjöd in dig!",
        "INPUT_CODE": "Skriv in kod"
    },
    "AVATAR_BUILDER": {
        "HEADER": "Skapa min avatar",
        "HAIR": "Välj frisyr och hårfärg",
        "CLOTHES": "Välj kläder och klädesfärg",
        "HEADS": "Välj huvud och hudton",
        "EYES": "Välj ögon",
        "MOUTHS": "Välj mun",
        "NOSES": "Välj näsa",
        "BEARDS": "Välj skägg och skäggton",
        "EYEWEARS": "Välj ögonutsmyckning",
        "HEADWEARS": "Välj huvudbonad",
        "ACCESSORIES": "Välj accessoarer"
    },
    "AVATAR_BUILDER_TABS": {
        "HAIR": "Hår",
        "CLOTHES": "Kläder",
        "HEADS": "Huvud",
        "EYES": "Ögon",
        "MOUTHS": "Mun",
        "NOSES": "Näsa",
        "BEARDS": "Skägg",
        "EYEWEARS": "Ögonutsmyckning",
        "HEADWEARS": "Huvudbonad",
        "ACCESSORIES": "Accessoarer"
    },
    "FORM_TAGS": {
        "BB_CARE": "Omsorg",
        "BB_SAFETY": "Trygghet",
        "BB_FEEL_GOOD": "Må bra",
        "BB_LEISURE": "Fritid",
        "BB_CONTEXT": "Tillhörighet",
        "BB_RESPONSIBLE": "Ansvarstagande",
        "BB_RESPECT": "Respekteras",
        "BB_DEVELOPING": "Utvecklas"
    }
}
