import type * as React from "react";

type TFormFooterProps = {
    children: React.ReactNode;
};

const FormFooter = ({ children }: TFormFooterProps) => {
    return <div className="formFooter-wrapper">{children}</div>;
};

export default FormFooter;
