import { useContext, useState } from "react";
import { LanguageContext } from "~/src/contexts/languageContext";
import IconHide from "~/src/media/icons/eye_closed.svg";
import IconShow from "~/src/media/icons/eye_open.svg";
import { minLength } from "~/src/utils/constants";
import language from "~/src/utils/language";
import "./textInput.css";

interface IPasswordInput {
    autoComplete?: string;
    error: boolean;
    name?: string;
    onChange?: () => void;
    placeholder?: string;
    create?: boolean;
}

const PasswordInput = ({
    autoComplete,
    onChange,
    error,
    name,
    placeholder,
    create,
}: IPasswordInput) => {
    const { locales } = useContext(LanguageContext);
    const { COMMON } = language[locales];
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const placeholderText = create
        ? placeholder || COMMON.INPUT_CREATING_PASSWORD
        : placeholder || COMMON.INPUT_PASSWORD;

    return (
        <label htmlFor={name} aria-label={placeholderText}>
            <input
                autoComplete={
                    create ? autoComplete || "new-password" : autoComplete || "current-password"
                }
                className={`login-input ${error ? "textInput-error" : ""}`}
                key="password"
                minLength={create ? minLength.creatingPassword : minLength.password}
                id={name || "password"}
                name={name || "password"}
                onChange={onChange}
                placeholder={placeholderText}
                required
                style={{ padding: "0.75rem 2.8rem 0.75rem 1.5rem" }}
                type={showPassword ? "text" : "password"}
            />
            <button
                type="button"
                className="textInput-password-show-password"
                style={{}}
                onClick={() => {
                    setShowPassword(!showPassword);
                }}
            >
                <img src={showPassword ? IconHide : IconShow} alt="Show/hide password" />
            </button>
        </label>
    );
};

export default PasswordInput;
