import { useContext } from "react";
import { LanguageContext } from "~/src/contexts/languageContext";
import language from "~/src/utils/language";
import ButtonSecondary from "../tools/buttons/buttonSecondary";
import "./moreAccommodation.css";

type TMoreAccommodationProps = {
    handleMoreAccommodationClicked: (done: boolean) => void;
};

const MoreAccommodations = ({ handleMoreAccommodationClicked }: TMoreAccommodationProps) => {
    const { locales } = useContext(LanguageContext);
    const { COMMON, MOREACCOMMODATION } = language[locales];

    const handleClick = (done: boolean) => {
        handleMoreAccommodationClicked(done);
    };

    return (
        <div className="moreAccommodation-wrapper">
            <h1 className="font1">{MOREACCOMMODATION.HEADER_TEXT}</h1>
            <div className="moreAccommodation-button-wrapper">
                <ButtonSecondary
                    size="medium"
                    className="moreAccommodation-button"
                    onClick={() => {
                        handleClick(false);
                    }}
                >
                    {COMMON.YES}
                </ButtonSecondary>
                <ButtonSecondary
                    size="medium"
                    className="moreAccommodation-button"
                    onClick={() => {
                        handleClick(true);
                    }}
                >
                    {COMMON.NO}
                </ButtonSecondary>
            </div>
        </div>
    );
};

export default MoreAccommodations;
