import { GetAvatar } from "@ommej/componente";
import { personTypes } from "@ommej/metadata";
import { getQuestion } from "@ommej/pregunta";
import type { BebMentions, Form, Uuid } from "@ommej/types";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import PencilIcon from "~/src/media/icons/pencil.svg";
import { isDemo } from "~/src/utils/api";
import language from "~/src/utils/language";
import { getPersonalQuestion } from "~/src/utils/utils";
import Header from "../header/header";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./persons.css";

type PersonsProps = {
    data: Record<Uuid, BebMentions>;
    inactiveData: Record<Uuid, BebMentions>;
    form: Form.FormData;
    close: () => void;
};

const Persons = ({ data, inactiveData, form, close }: PersonsProps) => {
    const { profile } = useContext(ProfileContext);
    const { locales } = useContext(LanguageContext);
    const { COMMON, BEB } = language[locales];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function getListItems(mentionedData: BebMentions, personId: Uuid, editable: boolean) {
        return mentionedData.mentioned?.map((mentionedIn: { question: Uuid; answer: Uuid }) => {
            const question = getQuestion(form, undefined, mentionedIn.question);
            const q = getPersonalQuestion(profile, question?.question, personId);
            return (
                <li className="bodyText14" key={personId + mentionedIn.answer}>
                    <div className="persons-question-wrapper">
                        <p>{`${q.replace(BEB.REPLACE, "")}`}</p>
                        {editable && (
                            <Link
                                className="beb-edit-answer-link"
                                to={`/form/${form.metadata.id}/${form.metadata.version}/${mentionedIn.question}`}
                                state={{ back: "/beb/persons", next: "/beb" }}
                            >
                                <img src={PencilIcon} alt={BEB.CHANGE_QUESTION} />
                            </Link>
                        )}
                    </div>
                </li>
            );
        });
    }

    // handles showing persons that only have inactive answers
    function getInactiveLeftovers(
        active: Record<Uuid, BebMentions>,
        inactive: Record<Uuid, BebMentions>,
    ) {
        const onlyInInactive = Object.keys(inactive).filter((key) => {
            return !active[key];
        });
        return onlyInInactive.map((personId) => {
            const personData = inactive[personId];
            return (
                <RoundWrapper key={personId} className="inactive-answers persons-persons-wrapper">
                    <h3>
                        {`${COMMON.INACTIVE_ANSWERS} ${
                            personTypes[profile?.persons?.[personId].type as string].lang.sv
                        }`}
                    </h3>
                    <GetAvatar avatarData={profile?.persons?.[personId].avatar} />
                    <ul style={{ opacity: 1 }} className="inactive-answers">
                        {getListItems(personData, personId, false)}
                    </ul>
                </RoundWrapper>
            );
        });
    }

    return (
        <div className={`content-wrapper ${isDemo ? "app-demo-background" : "content-dark"}`}>
            <Header
                handleBack={() => {
                    close();
                }}
                header={BEB.PERSONS_HEADER}
            />
            {Object.entries(data).map(([personId, personData]) => {
                return (
                    <RoundWrapper key={personId} className="persons-persons-wrapper">
                        <div style={{ display: "grid", placeItems: "center", gap: "0.75rem" }}>
                            <GetAvatar avatarData={profile?.persons?.[personId].avatar} />
                            <h2 className="font3">
                                {`${
                                    personTypes[profile?.persons?.[personId].type as string].lang.sv
                                }`}
                            </h2>
                        </div>
                        <ul>{getListItems(personData, personId, true)}</ul>
                        {inactiveData[personId] && (
                            <details className="inactive-answers-details">
                                <summary>
                                    <h3 className="summary-header">{COMMON.INACTIVE_ANSWERS}</h3>
                                </summary>
                                <p className="details-info">{COMMON.INACTIVE_ANSWERS_INFO}</p>
                                <ul className="inactive-answers">
                                    {getListItems(inactiveData[personId], personId, false)}
                                </ul>
                            </details>
                        )}
                    </RoundWrapper>
                );
            })}
            {getInactiveLeftovers(data, inactiveData)}
        </div>
    );
};

export default Persons;
