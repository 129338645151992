import { GetAvatar } from "@ommej/componente";
import { AvatarGroups, avatars, personTypes } from "@ommej/metadata";
import type { AvatarId, Uuid } from "@ommej/types";
import { useContext, useEffect, useState } from "react";
import type * as React from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import ArrowThinIcon from "~/src/media/icons/arrowThin.svg";
import ArrowWhiteIcon from "~/src/media/icons/arrow_white.svg";
import PencilIcon from "~/src/media/icons/justPencil.svg";
import CreateAvatarImg from "~/src/media/svg/createAvatar.svg";
import PlusBigImg from "~/src/media/svg/plusBig.svg";
import language from "~/src/utils/language";
import "./avatar.css";

type TAvatarProps = {
    handleAvatarClicked: (avatar: AvatarId) => void;
    createAvatarClicked?: () => void;
    state:
        | {
              ageGroup: AvatarGroups;
              avatarChildSelection?: boolean;
          }
        | undefined;
    showHeader?: boolean;
    footerStyle?: React.CSSProperties;
    avatarExists?: {
        avatarId: AvatarId;
        personId: Uuid;
    };
};

const Avatar = ({
    handleAvatarClicked,
    createAvatarClicked,
    state,
    showHeader = false,
    footerStyle,
    avatarExists,
}: TAvatarProps) => {
    const avatarChildSelection = state?.avatarChildSelection;
    const { locales } = useContext(LanguageContext);
    const { profile } = useContext(ProfileContext);
    const { COMMON, AVATAR } = language[locales];
    const avatarsPerPage = avatarChildSelection ? 8 : 10;
    const { length } = avatars[state?.ageGroup || AvatarGroups.ALL];
    const totalPages = Math.ceil(length / avatarsPerPage);
    const [chosenAvatar, setChosenAvatar] = useState<AvatarId>();
    const [avatarsPerPageTopValue, setAvatarsPerPageTopValue] = useState(avatarsPerPage);
    const [page, setPage] = useState(0);
    const [avatarArr, setAvatarArr] = useState(avatars[state?.ageGroup || AvatarGroups.ALL]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const currentAvatar = profile.avatar ?? undefined;

    useEffect(() => {
        if (avatarExists) {
            setAvatarArr(
                avatars[state?.ageGroup || AvatarGroups.ALL].sort((first) => {
                    if (first === avatarExists.avatarId) {
                        return -1;
                    }
                    return 0;
                }),
            );
            setChosenAvatar(avatarExists.avatarId);
        }
        setLoading(false);
    }, []);

    const nextSlide = () => {
        setAvatarsPerPageTopValue(avatarsPerPageTopValue + avatarsPerPage);
        setPage(page + 1);
    };

    const prevSlide = () => {
        setAvatarsPerPageTopValue(avatarsPerPageTopValue - avatarsPerPage);
        setPage(page - 1);
    };

    const handleDots = (index: number) => {
        setPage(index);
        setAvatarsPerPageTopValue((index + 1) * avatarsPerPage);
    };

    const handleAvatar = async (e: React.MouseEvent<HTMLButtonElement>, avatar: AvatarId) => {
        e.preventDefault();
        setChosenAvatar(avatar);
    };

    const navigationDots = Array.from({ length: totalPages }, (_item, index) => {
        return index + 1;
    });

    const handleCreateAvatar = () => {
        if (createAvatarClicked) {
            return createAvatarClicked();
        }
        return navigate("/avatarbuilder");
    };

    const sendAvatar = () => {
        if (chosenAvatar) {
            handleAvatarClicked(chosenAvatar);
        }
    };

    if (loading) {
        return null;
    }
    return (
        <>
            {showHeader && <h1 className="font1 avatar-header">{AVATAR.CHOOSE}</h1>}
            {avatarExists && (
                <h2 className="avatar-subheader">{`${AVATAR.SUBHEADER} ${
                    personTypes[profile.persons?.[avatarExists.personId]?.type as string]?.lang.sv
                }`}</h2>
            )}
            {avatarChildSelection && (
                <>
                    <button
                        className="avatar-create-wrapper"
                        type="button"
                        onClick={handleCreateAvatar}
                        aria-label={AVATAR.CREATE_OWN}
                    >
                        {currentAvatar &&
                        typeof currentAvatar !== "string" &&
                        currentAvatar.type === "PARTS" ? (
                            <div className="avatar-create-parts">
                                <GetAvatar avatarData={currentAvatar} />
                                <span>
                                    <img src={PencilIcon} alt={AVATAR.EDIT} />
                                </span>
                            </div>
                        ) : (
                            <>
                                <img src={CreateAvatarImg} alt="" />
                                <div className="avatar-create-content">
                                    <img className="plus" src={PlusBigImg} alt="" />
                                    <p>{AVATAR.CREATE_OWN}</p>
                                </div>
                            </>
                        )}
                    </button>
                    <div className="avatar-hr-wrapper">
                        <hr />
                        <p>{`${COMMON.OR} ${COMMON.CHOOSE.toLowerCase()}`}</p>
                    </div>
                </>
            )}
            <div className="avatar-wrapper">
                {avatarArr
                    .slice(page * avatarsPerPage, avatarsPerPageTopValue)
                    .map((avatar, index) => {
                        return (
                            <button
                                className={`avatar-button ${
                                    avatar === chosenAvatar
                                        ? "avatar-button-selected"
                                        : "avatar-button-unselected"
                                }`}
                                type="button"
                                aria-label={`avatar ${index + 1}`}
                                key={avatar}
                                onClick={(e) => {
                                    handleAvatar(e, avatar);
                                }}
                            >
                                <GetAvatar avatarData={avatar} />
                            </button>
                        );
                    })}
                {page >= 1 && (
                    <button className="avatar-button-left" type="button" onClick={prevSlide}>
                        <img src={ArrowThinIcon} alt={COMMON.BACK} />
                    </button>
                )}
                {page <= totalPages - 2 && (
                    <button className="avatar-button-right" type="button" onClick={nextSlide}>
                        <img src={ArrowThinIcon} alt={COMMON.BUTTON_NEXT} />
                    </button>
                )}
            </div>
            <footer className="avatar-footer-wrapper" style={footerStyle}>
                {chosenAvatar ? (
                    <ButtonPrimary
                        size="large"
                        icon={{ url: ArrowWhiteIcon, layout: "right" }}
                        onClick={sendAvatar}
                    >
                        {COMMON.BUTTON_READY}
                    </ButtonPrimary>
                ) : (
                    <div className="avatar-dots-wrapper">
                        {navigationDots.map((dots, index) => {
                            return (
                                <button
                                    key={dots}
                                    className={
                                        page === index
                                            ? "avatar-dots avatar-dots-active"
                                            : "avatar-dots"
                                    }
                                    aria-label={`page ${page + 1}`}
                                    type="button"
                                    tabIndex={0}
                                    onClick={() => {
                                        handleDots(index);
                                    }}
                                />
                            );
                        })}
                    </div>
                )}
            </footer>
        </>
    );
};

export default Avatar;
