import type { Uuid } from "@ommej/types";
import * as React from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import { LanguageContext } from "~/src/contexts/languageContext";
import ArrowWhiteIcon from "~/src/media/icons/arrow_white.svg";
import language from "~/src/utils/language";
// will be handled by a plugin for parcel
// eslint-disable-next-line import/no-unresolved
import * as images from "../../media/svg/form-*.svg";
import ErrorComponent from "../tools/errorComponent/errorComponent";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import type { QuestionView } from "./form";
import "./form.css";
import FormFooter from "./formFooter";

const Article: React.FC<QuestionView> = ({ question, setAnswer }) => {
    const { locales } = React.useContext(LanguageContext);
    const { COMMON, FORM } = language[locales];

    if (question.question.type !== "article_text") {
        return <ErrorComponent />;
    }

    const onAnswerClick = (answerId: Uuid) => {
        setAnswer(question.id, [{ answer: answerId, data: [], timestamp: new Date() }]);
    };

    return (
        <div
            className={`form-article-background ${
                question.question.tags?.includes("MOTIVATIONAL")
                    ? "form-article-background-motivational"
                    : "form-article-background-default"
            }`}
        >
            <RoundWrapper className="form-article">
                {question.question.header?.sv && (
                    <h1 className="form-question">{question.question.header.sv}</h1>
                )}
                {question.question.video && (
                    <video
                        className="form-article-video"
                        controls
                        poster={`https://files.ommej.se/video/${question.question.video}/${question.question.video}_hd.mp4.jpg`}
                    >
                        <source
                            src={`https://files.ommej.se/video/${question.question.video}/${question.question.video}_hd.mp4`}
                            type="video/mp4"
                        />
                        {COMMON.VIDEO}
                    </video>
                )}
                {/* biome-ignore lint/security/noDangerouslySetInnerHtml: we control the input */}
                <h4 dangerouslySetInnerHTML={{ __html: question.question.text.sv }} />
                {question.question.image && (
                    <img src={images[question.question.image]} alt="en bild" />
                )}
                {question.question.link && (
                    <Link to={question.question.link.href} className="article-link-button">
                        {question.question.link.text.sv}
                    </Link>
                )}
                <div className="form-answers">
                    {Object.entries(question.question.answers).map(([answerId, answer]) => {
                        switch (answer.type) {
                            case "none":
                                return (
                                    <FormFooter key={answerId}>
                                        <ButtonPrimary
                                            size="large"
                                            testid="form-answer-alternative"
                                            icon={{ url: ArrowWhiteIcon, layout: "right" }}
                                            onClick={() => {
                                                onAnswerClick(answerId);
                                            }}
                                        >
                                            {COMMON.BUTTON_CONTINUE_ANSWER}
                                        </ButtonPrimary>
                                    </FormFooter>
                                );
                            default:
                                return <span key={answerId}>{FORM.UNKNOWN}</span>;
                        }
                    })}
                </div>
            </RoundWrapper>
        </div>
    );
};

export default Article;
