import * as React from "react";

interface IStepper {
    children: React.ReactNode;
    currentStep: number;
    goToNextStep: () => void;
    goToPreviousStep: () => void;
}

const Stepper = ({ children, currentStep, goToNextStep, goToPreviousStep }: IStepper) => {
    return (
        <>
            {React.Children.map(children, (child, index) => {
                return (
                    React.isValidElement(child) &&
                    // type assertion: the isValidElement checks that it's a ReactElement
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    React.cloneElement(child as React.ReactElement<any>, {
                        isActive: index === currentStep,
                        goToPreviousStep: () => {
                            goToPreviousStep();
                        },
                        goToNextStep: () => {
                            goToNextStep();
                        },
                    })
                );
            })}
        </>
    );
};

export default Stepper;
