import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./contexts/authContext";
import { LanguageProvider } from "./contexts/languageContext";
import { NotificationProvider } from "./contexts/notificationContext";
import { isDemo, isDev } from "./utils/api";

const container = document.getElementById("root");
// container won't be null here
// biome-ignore lint/style/noNonNullAssertion: we know roughly what we're doing here
const root = createRoot(container!);
root.render(
    <BrowserRouter>
        {isDemo && <div className="app-demo-wrapper">TESTMILJÖ</div>}
        {isDev && <div className="app-demo-wrapper app-dev-wrapper">DEV</div>}
        <AuthProvider>
            <LanguageProvider>
                <NotificationProvider>
                    <App />
                </NotificationProvider>
            </LanguageProvider>
        </AuthProvider>
    </BrowserRouter>,
);
