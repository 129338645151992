import { AvatarGroups, type PersonType, type PersonTypes, personTypes } from "@ommej/metadata";
import type { AvatarId, Uuid } from "@ommej/types";
import { useContext, useEffect, useState } from "react";
import * as React from "react";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import AddIcon from "~/src/media/icons/plusCircle.svg";
import DummyAvatar from "~/src/media/svg/dummy_avatar.svg";
import type { TPersonObject } from "~/src/types";
import language from "~/src/utils/language";
import { persistNewPersons, reloadProfile } from "~/src/utils/profileUtils";
import Avatar from "./avatar";
import "./newPerson.css";

interface NewPersonState {
    newPersonId: Uuid | undefined;
    avatarAgeGroup: AvatarGroups;
    relation: keyof PersonTypes | undefined;
}

const NewPerson = ({
    newPersonCreated,
}: {
    newPersonCreated: (person: Uuid | undefined) => void;
}) => {
    const { profile, setProfile } = useContext(ProfileContext);
    const { locales } = React.useContext(LanguageContext);
    const { ACCOMMODATION } = language[locales];

    const initialNewPersonState: NewPersonState = {
        newPersonId: undefined,
        avatarAgeGroup: AvatarGroups.CHILD,
        relation: undefined,
    };
    const [newPersonState, setNewPersonState] = useState<NewPersonState>(initialNewPersonState);

    const handleAvatarClicked = async (avatarId: AvatarId) => {
        const person: TPersonObject = {
            avatar: avatarId,
            type: newPersonState.relation,
        };
        try {
            const personIds = await persistNewPersons([person]);
            // We know it's always one element, see line above
            const stateUpdate = { newPersonId: personIds[0] };
            setNewPersonState({ ...newPersonState, ...stateUpdate });
        } catch (_e) {
            // What to do? At least call out to caller and indicate
            // nothing done.
            // TODO: show error dialog
            newPersonCreated(undefined);
            console.error("Error when saving new Person");
        }
    };

    const handleRelationshipClicked = (relation: keyof PersonTypes, relationObject: PersonType) => {
        const { avatarAgeGroups } = relationObject.data;
        const stateUpdate = { relation, avatarAgeGroup: avatarAgeGroups[0] };
        setNewPersonState({ ...newPersonState, ...stateUpdate });
    };

    useEffect(() => {
        const asyncReloadProfile = async () => {
            try {
                await reloadProfile(setProfile);
            } catch (_e) {
                // What to do? At least call out to caller and indicate
                // nothing done.
                // TODO: show error dialog
                newPersonCreated(undefined);
                console.error("Error when reloading profile");
            }
        };
        if (newPersonState.newPersonId) {
            asyncReloadProfile();
        }
    }, [newPersonState]);

    useEffect(() => {
        if (
            profile.persons &&
            newPersonState.newPersonId &&
            Object.keys(profile.persons).includes(newPersonState.newPersonId)
        ) {
            newPersonCreated(newPersonState.newPersonId);
        }
    }, [profile]);

    return (
        <div className="new-person-wrapper">
            <h2 style={{ marginBottom: "1rem" }}>{ACCOMMODATION.ADD_PERSON}</h2>
            {!newPersonState.relation ? (
                <div className="new-person-relation-type-wrapper">
                    {Object.entries(personTypes).map(([relation, meta]) => {
                        return (
                            <button
                                className="bodyText14"
                                type="button"
                                key={relation}
                                onClick={() => {
                                    handleRelationshipClicked(relation, meta);
                                }}
                            >
                                <img src={DummyAvatar} alt="" />
                                <img src={AddIcon} alt="" className="new-person-add-icon" />
                                <p>{meta.lang[locales]}</p>
                            </button>
                        );
                    })}
                </div>
            ) : (
                <div className="new-person-avatar-wrapper">
                    <Avatar
                        handleAvatarClicked={handleAvatarClicked}
                        state={{ ageGroup: newPersonState.avatarAgeGroup }}
                        footerStyle={{ bottom: "3rem" }}
                    />
                </div>
            )}
        </div>
    );
};

export default NewPerson;
