export const consentContent = {
    HEADER: {
        sv: "Viktigt",
        en: "Important",
    },
    INTRODUCTION: {
        sv: "Innan du kan gå vidare måste vi kolla att du är okej med att vi sparar information om hur du använder vår app. Detta gör vi för kunna förbättra appen. Du bestämmer själv om du vill gå med på detta och du behöver inte dina föräldrars godkännande.",
        en: "Innan du kan gå vidare måste vi kolla att du är okej med att vi sparar information om hur du använder vår app. Detta gör vi för kunna förbättra appen. Du bestämmer själv om du vill gå med på detta och du behöver inte dina föräldrars godkännande.",
    },
    WHAT_INFORMATION: {
        SUBHEADER: {
            sv: "Vilken information sparar vi?",
            en: "What information do we save?",
        },
        CONTENT: {
            sv: [
                "För att gå vidare skriver du in din emailadress eller ditt telefonnummer. Sen pseudonymiserar vi det. Det betyder att vi gör om informationen så att ingen, inte ens vi, kan läsa den. Den kan ändå användas för att skicka dig ett nytt lösenord om du får problem med att logga in någon gång.",
                "Vi sparar dina svar i appen. Det kan handla om sådant som hur du mår, vad du oroar dig för, eller saker du eller andra gör eller har gjort. Allt vi sparar är anonymt och ingen, inte ens vi på Ommej, vet vem du är.",
                "Vi kollar vilken typ av mobil, platta eller dator du använder och om något går fel kollar vi var i appen du var när det gick fel.",
                "Vi kollar hur länge du stannar på olika sidor i appen.",
            ],
            en: [
                "För att gå vidare skriver du in din emailadress eller ditt telefonnummer. Sen pseudonymiserar vi det. Det betyder att vi gör om informationen så att ingen, inte ens vi, kan läsa den. Den kan ändå användas för att skicka dig ett nytt lösenord om du får problem med att logga in någon gång.",
                "Vi sparar dina svar i appen. Det kan handla om sådant som hur du mår, vad du oroar dig för, eller saker du eller andra gör eller har gjort. Allt vi sparar är anonymt och ingen, inte ens vi på Ommej, vet vem du är.",
                "Vi kollar vilken typ av mobil, platta eller dator du använder och om något går fel kollar vi var i appen du var när det gick fel.",
                "Vi kollar hur länge du stannar på olika sidor i appen.",
            ],
        },
    },
    USE_INFORMATION: {
        SUBHEADER: {
            sv: "Vad använder vi informationen till?",
            en: "What do we use the information for?",
        },
        CONTENT: {
            sv: [
                "Fixa tekniska fel.",
                "Lära oss mer om vad våra användare gillar och inte gillar, så att vi kan göra mer av det som är bra och ta bort det som ni inte gillar.",
                "Lära oss mer om hur barn och unga mår och hur deras liv ser ut. När vi vet det kan vi hjälpa dem som jobbar med att ge stöd till barn och unga att göra ett riktigt bra jobb.",
            ],
            en: [
                "Fixa tekniska fel.",
                "Lära oss mer om vad våra användare gillar och inte gillar, så att vi kan göra mer av det som är bra och ta bort det som ni inte gillar.",
                "Lära oss mer om hur barn och unga mår och hur deras liv ser ut. När vi vet det kan vi hjälpa dem som jobbar med att ge stöd till barn och unga att göra ett riktigt bra jobb.",
            ],
        },
    },
    APPROVE: {
        sv: "Går du med på detta?",
        en: "Do you agree to this?",
    },
};
