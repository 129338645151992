import { type PersonTypes, personTypes } from "@ommej/metadata";
import {
    getNextQuestion,
    getNextUnanswered,
    getPreviousQuestion,
    getQuestion,
    getStats,
} from "@ommej/pregunta";
import type { Answers, Form as FormType, Profile, SingleAnswer, Uuid } from "@ommej/types";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "~/src/components/header/header";
import Spinner from "~/src/components/tools/spinner/spinner";
import { AuthContext } from "~/src/contexts/authContext";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import type { TPersonObject } from "~/src/types";
import { request } from "~/src/utils/api";
import { FORM_DEFAULT_ID } from "~/src/utils/constants";
import language, { type ILanguage } from "~/src/utils/language";
import ErrorComponent from "../tools/errorComponent/errorComponent";
import Article from "./article";
import "./form.css";
import FormIntro from "./formIntro";
import QuestionFrequency from "./questionFrequency";
import QuestionMultiple from "./questionMultiple";
import QuestionMultiplePerson from "./questionMultiplePerson";
import QuestionPersonsAccommodations from "./questionPersonsAccommodations";
import QuestionRate from "./questionRate";
import QuestionSingle from "./questionSingle";
import QuestionSinglePersonAccommodation from "./questionSinglePersonAccommodation";
import { THEMES, type Theme } from "./theming";

export function getPersonsInHouse(
    languageStrings: ILanguage,
    profile: Profile,
    houseId: Uuid | undefined,
) {
    if (!profile?.housePersonMap || !houseId) {
        return "";
    }
    const maxToShow = 3;
    const personIds = profile.housePersonMap[houseId];
    const persons: string[] = [];
    for (const personId of personIds.slice(0, maxToShow)) {
        if (profile?.persons?.[personId]?.type) {
            persons.push(personTypes[profile.persons[personId].type as keyof PersonTypes].lang.sv);
        }
    }

    let personStr = `${languageStrings.FORM.LIVES_WITH}: ${persons
        .map((person) => {
            return person.toLowerCase();
        })
        .join(", ")}`;
    if (persons.length === 0) {
        personStr = `${
            languageStrings.FORM.LIVES_WITH
        }: ${languageStrings.COMMON.BUTTON_NONE.toLowerCase()}`;
    } else if (personIds.length > maxToShow) {
        personStr = `${personStr}...`;
    }
    return personStr;
}

export type QuestionView = {
    question: Omit<FormType.NextQuestion, "question" | "id"> & {
        question: FormType.FormQuestion;
        id: Uuid;
    };
    setAnswer: (questionId: Uuid, answer: SingleAnswer[]) => void;
    existingAnswer: SingleAnswer[] | undefined;
};
export type QuestionViewResource = QuestionView & {
    resources: {
        [key: Uuid]: TPersonObject;
    };
};
interface FormProps {
    formId?: string;
    formVersion?: string;
}

export type FormNavigateState = {
    // string: back button will navigate the provided string
    // undefined: back button will navigate to previous question
    back: string | undefined;

    // string: next button will navigate to the provided string
    // undefined: next button will navigate to next question
    next: string | undefined;
};

const Form = ({ formId, formVersion }: FormProps) => {
    const { locales } = React.useContext(LanguageContext);
    const { FORM, FORM_TAGS } = language[locales];
    const form = useRef<FormType.FormData>();
    // keep tracks of answers for all questions
    const answers = useRef<Answers>({ answers: {}, inactive: {} });
    const [currentQuestion, setCurrentQuestion] = useState<FormType.NextQuestion>();
    const { profile } = useContext(ProfileContext);
    const params = useParams();
    const showSpecificQuestion = useRef<Uuid | undefined>();
    const [formIdState, setFormIdState] = useState(formId);
    const [formVersionState, setFormVersionState] = useState(formVersion);
    const [invitationIdState, setInvitationIdState] = useState<string | undefined>();
    const [requestIdState, setRequestIdState] = useState<string | undefined>();
    const [existingAnswer, setExistingAnswer] = useState<SingleAnswer[] | undefined>();
    const [showIntro, setShowIntro] = useState(true);
    const [error, setError] = useState<string | null>();
    const navigate = useNavigate();
    const location = useLocation();
    const navigationState: FormNavigateState | undefined = location.state as
        | FormNavigateState
        | undefined;
    const stats = useRef<FormType.Stats>();
    const userContext = React.useContext(AuthContext);
    const [formRequestInfo, setFormRequestInfo] = useState<{
        owner: string | undefined;
        unit: string | undefined;
    }>();

    useEffect(() => {
        if (!formIdState || !formVersionState) {
            setFormIdState(params.formIdParam);
            setFormVersionState(params.formVersionParam);

            setInvitationIdState(params.invitationId);
            setRequestIdState(params.requestId);
            if (params.questionId) {
                showSpecificQuestion.current = params.questionId;
            }

            if (params.requestId) {
                const reqInvitation = userContext.user?.invitations.accepted.find((inv) => {
                    return inv.forms?.find((req) => {
                        return req.id === params.requestId;
                    });
                });
                if (reqInvitation) {
                    setFormRequestInfo({
                        owner: reqInvitation.owner,
                        unit: reqInvitation.unit,
                    });
                }
            }
        }
    }, []);

    async function getForm() {
        try {
            const res = await request(`forms/${formIdState}/${formVersionState}`, "GET");
            const resForm = await res.json();
            form.current = resForm;
        } catch (_err) {
            console.error("Failed to get form");
        }
    }

    async function sendAnswers() {
        const url =
            invitationIdState && requestIdState
                ? `clients/answers/${formIdState}/${formVersionState}/${invitationIdState}/${requestIdState}`
                : `clients/answers/${formIdState}/${formVersionState}`;
        await request(url, "PUT", answers.current);
    }

    async function getAnswers() {
        try {
            const url =
                invitationIdState && requestIdState
                    ? `clients/answers/${formIdState}/${formVersionState}/${invitationIdState}/${requestIdState}`
                    : `clients/answers/${formIdState}/${formVersionState}`;
            const res = await request(url, "GET");
            const resAnswers = await res.json();
            answers.current = resAnswers;
        } catch (_err) {
            // OK, probably no answers in backend yet
        }
    }

    const saveImmutableAnswers = useCallback(async () => {
        const url = `clients/answers/${formIdState}/${formVersionState}/${invitationIdState}/${requestIdState}/save`;
        await request(url, "PUT", {});
    }, [formIdState, formVersionState, invitationIdState, requestIdState]);

    useEffect(() => {
        if (currentQuestion && !currentQuestion.question && !form.current?.metadata.mutable) {
            saveImmutableAnswers();
        }

        setExistingAnswer(
            currentQuestion?.id ? answers.current.answers[currentQuestion.id] : undefined,
        );

        if (currentQuestion && !currentQuestion.question) {
            // default form should always be the last form, so when it's done let's
            // go to the done view
            if (formIdState === FORM_DEFAULT_ID) {
                navigate("/form/done", { replace: true });
            } else {
                navigate("/form", { replace: true });
            }
        }
    }, [currentQuestion]);

    async function load() {
        await Promise.all([getForm(), getAnswers()]);

        if (!form.current) {
            // TODO show error component
            return;
        }

        // resume from last time if we have answers, otherwise start from the start
        let question;
        if (showSpecificQuestion.current) {
            question = getQuestion(form.current, answers.current, showSpecificQuestion.current);
            showSpecificQuestion.current = undefined;
        } else if (answers.current) {
            question = getNextUnanswered(form.current, answers.current);
        } else {
            question = getNextQuestion(form.current, { answers: {}, inactive: {} });
        }

        setCurrentQuestion(question);

        stats.current = getStats(form.current, undefined);
    }

    useEffect(() => {
        if (!formIdState) {
            return;
        }
        load();
    }, [formIdState]);

    const handleErrorComponent = () => {
        setError(null);
    };

    const setAnswer = async (questionId: Uuid, answer: SingleAnswer[]) => {
        if (!questionId || !form.current) {
            return;
        }
        answers.current.answers[questionId] = answer;
        try {
            const nextQuestion = getNextUnanswered(form.current, answers.current);
            // NOTE: getNextQuestion will remove answers that are not relevant
            // anymore, therefore, we shouldn't persist the answers until after that
            // is done. I.e., sendAnswers should be done after getNextQuestion.
            await sendAnswers();
            if (navigationState?.next) {
                navigate(navigationState.next);
            }
            setCurrentQuestion(nextQuestion);
            stats.current = getStats(form.current, answers.current);
        } catch (err) {
            console.error("Failed to send answers", err);
            setError(
                "Det verkar som du har nätverksproblem! Vi får inte kontakt med vår server. Du kan prova att trycka på ett svar igen för att se om det fungerar",
            );
        }
    };

    function setPreviousQuestion() {
        if (!currentQuestion || !form.current) {
            return;
        }
        const question = currentQuestion.id
            ? getPreviousQuestion(form.current, answers.current, currentQuestion.id)
            : getPreviousQuestion(form.current, answers.current);
        setCurrentQuestion(question);
    }

    function handleBackClick() {
        if (navigationState?.back) {
            navigate(navigationState.back);
        } else if (
            currentQuestion &&
            !currentQuestion.question &&
            !form.current?.metadata.mutable
        ) {
            navigate("/home");
        } else {
            setPreviousQuestion();
        }
    }

    function getQuestionDOM() {
        if (showIntro && !params.questionId && form.current?.metadata.intro && locales === "sv") {
            return (
                <FormIntro
                    introData={form.current.metadata.intro}
                    locales={locales}
                    owner={formRequestInfo?.owner}
                    unit={formRequestInfo?.unit}
                    onButtonClick={() => {
                        setShowIntro(false);
                    }}
                />
            );
        }

        if (!currentQuestion?.question) {
            return <Spinner />;
        }

        const currentQuestionChecked = currentQuestion as QuestionView["question"];
        switch (currentQuestion.question.type) {
            case "article_text":
                return (
                    <Article
                        question={currentQuestionChecked}
                        setAnswer={setAnswer}
                        existingAnswer={existingAnswer}
                    />
                );
            case "single":
                return (
                    <QuestionSingle
                        key={currentQuestion?.id}
                        question={currentQuestionChecked}
                        setAnswer={setAnswer}
                        existingAnswer={existingAnswer}
                    />
                );
            case "rate":
                return (
                    <QuestionRate
                        key={currentQuestion?.id}
                        question={currentQuestionChecked}
                        setAnswer={setAnswer}
                        existingAnswer={existingAnswer}
                    />
                );
            case "accommodations":
                return (
                    profile.houses && (
                        <QuestionPersonsAccommodations
                            key={currentQuestion?.id}
                            question={currentQuestionChecked}
                            setAnswer={setAnswer}
                            resources={profile.houses}
                            existingAnswer={existingAnswer}
                        />
                    )
                );
            case "persons":
                return (
                    profile.persons && (
                        <QuestionPersonsAccommodations
                            key={currentQuestion?.id}
                            question={currentQuestionChecked}
                            setAnswer={setAnswer}
                            resources={profile.persons}
                            existingAnswer={existingAnswer}
                        />
                    )
                );
            case "single_accommodation":
                return (
                    profile.houses && (
                        <QuestionSinglePersonAccommodation
                            key={currentQuestion?.id}
                            question={currentQuestionChecked}
                            setAnswer={setAnswer}
                            resources={profile.houses}
                            existingAnswer={existingAnswer}
                        />
                    )
                );
            case "single_person":
                return (
                    profile.persons && (
                        <QuestionSinglePersonAccommodation
                            key={currentQuestion?.id}
                            question={currentQuestionChecked}
                            setAnswer={setAnswer}
                            resources={profile.persons}
                            existingAnswer={existingAnswer}
                        />
                    )
                );
            case "frequency":
                return (
                    <QuestionFrequency
                        key={currentQuestion?.id}
                        question={currentQuestionChecked}
                        setAnswer={setAnswer}
                        existingAnswer={existingAnswer}
                    />
                );
            case "multiple":
                return (
                    <QuestionMultiple
                        key={currentQuestion?.id}
                        question={currentQuestionChecked}
                        setAnswer={setAnswer}
                        existingAnswer={existingAnswer}
                    />
                );
            case "multiple_person":
                return (
                    profile.persons && (
                        <QuestionMultiplePerson
                            key={currentQuestion?.id}
                            question={currentQuestionChecked}
                            setAnswer={setAnswer}
                            resources={profile.persons}
                            existingAnswer={existingAnswer}
                        />
                    )
                );
            default:
                return <ErrorComponent />;
        }
    }

    let lastExistingAnswerTimestamp = new Date(0);
    if (existingAnswer) {
        for (const answer of existingAnswer) {
            const answerDate = new Date(answer.timestamp);
            if (answerDate > lastExistingAnswerTimestamp) {
                lastExistingAnswerTimestamp = answerDate;
            }
        }
    }

    function getTheme(
        question: FormType.NextQuestion | undefined,
    ): (Theme & { tag: FormType.QuestionTags }) | null {
        if (!form.current?.metadata.useTheme || !question?.question?.tags) {
            return null;
        }

        for (const tag of question.question.tags) {
            if (THEMES.has(tag)) {
                const theme = THEMES.get(tag);
                if (theme) {
                    return { ...theme, tag };
                }
                return null;
            }
        }

        return null;
    }

    const theme = getTheme(currentQuestion);

    return error ? (
        <ErrorComponent errorText={error} handleErrorComponent={handleErrorComponent} />
    ) : (
        <div className="form-wrapper">
            <Header
                marginBottom="0"
                handleBack={() => {
                    if (showIntro) {
                        navigate(-1);
                    } else {
                        handleBackClick();
                    }
                }}
                header={
                    form.current?.metadata.id !== "general" ? (
                        <div style={{ display: "grid", placeItems: "center" }}>
                            {formRequestInfo?.owner && `${FORM.HEADER} ${formRequestInfo?.owner}`}
                            <progress
                                style={{ width: "15rem" }}
                                max={stats.current?.total}
                                value={stats.current?.answered}
                            />
                        </div>
                    ) : (
                        ""
                    )
                }
                rightIcon="HOME"
            />
            {
                (theme && (
                    <div
                        style={{
                            display: "grid",
                            color: "white",
                            placeItems: "center",
                            width: "100%",
                            height: "3rem",
                            backgroundColor: theme.color,
                            marginTop: "1.5rem",
                        }}
                    >
                        {FORM_TAGS[theme.tag] || ""}
                    </div>
                )) || <div /> /* add empty element to fill the grid row */
            }
            {
                (existingAnswer && currentQuestion?.question?.type !== "article_text" && (
                    <span style={{ alignSelf: "center", height: "1.5rem", margin: "0.5rem 1rem" }}>
                        {FORM.LAST_ANSWER_DATE}{" "}
                        {new Intl.DateTimeFormat("sv", { dateStyle: "medium" }).format(
                            lastExistingAnswerTimestamp,
                        )}
                    </span>
                )) || <span /> /* add empty element to fill the grid row */
            }
            {getQuestionDOM()}
        </div>
    );
};

export default Form;
