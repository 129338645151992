import { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ErrorComponent from "~/src/components/tools/errorComponent/errorComponent";
import { LanguageContext } from "~/src/contexts/languageContext";
import ExitIcon from "~/src/media/icons/exit.svg";
import language from "~/src/utils/language";
import Header from "../header/header";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./organizations.css";
import { supportContent } from "./supportConstants";

const Organizations = () => {
    const { locales } = useContext(LanguageContext);
    const { COMMON, SUPPORT } = language[locales];
    const navigate = useNavigate();
    const { CATEGORY, INFORMATION } = supportContent;

    const param = useParams();
    const category = param.link;
    let content = Object.values(CATEGORY.SECTIONS).find((section) => {
        return section.LINK === category;
    });
    // slightly ugly doing it like this, but would need to change structure for
    // supportContent otherwise, and this works, so...
    if (!content) {
        content = Object.values(INFORMATION.SECTIONS).find((section) => {
            return section.LINK === category;
        });
    }

    if (!content) {
        return (
            <ErrorComponent
                handleErrorComponent={() => {
                    navigate("/support");
                }}
            />
        );
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const createMarkup = (text: string) => {
        return { __html: text };
    };

    return (
        <div className="content-wrapper">
            <Header
                header={SUPPORT.HEADER}
                handleBack={() => {
                    navigate("/support");
                }}
            />
            <div className="organization-content-wrapper">
                <h2 className="font2">{content.PAGE_HEADER}</h2>
                <ul>
                    {content.CONTENT.map(({ HEADING, TEXT, URL }) => {
                        return (
                            <li key={HEADING}>
                                <RoundWrapper
                                    background="var(--color-sand-light)"
                                    className="organizations-roundWrapper"
                                >
                                    <h3
                                        style={{ textAlign: "center", marginBottom: "1.5rem" }}
                                        className="font2"
                                    >
                                        <Link to={URL}>{HEADING}</Link>
                                    </h3>
                                    {/* biome-ignore lint/security/noDangerouslySetInnerHtml: we control the input */}
                                    <span dangerouslySetInnerHTML={createMarkup(TEXT)} />
                                    <div className="organizations-button-wrapper">
                                        <Link
                                            to={URL}
                                            className="buttons-wrapper b-secondary link-with-icon"
                                        >
                                            {COMMON.BUTTON_SITE}
                                            <img src={ExitIcon} alt="Lämnar nuvarande site" />
                                        </Link>
                                    </div>
                                </RoundWrapper>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Organizations;
