let apiUrl: string;
let isDemoEnvironment = false;
let isDevEnvironment = false;
if (window.location.hostname === "localhost") {
    apiUrl = "http://localhost:9090/";
} else if (window.location.hostname.startsWith("dev-app")) {
    apiUrl = "https://dev-api.ommej.se/";
    isDevEnvironment = true;
} else if (window.location.hostname.startsWith("demo-app")) {
    apiUrl = "https://demo-api.ommej.se/";
    isDemoEnvironment = true;
} else {
    apiUrl = "https://api.ommej.se/";
}

export const isLocal = window.location.hostname === "localhost";
export const isDemo = isDemoEnvironment;
export const isDev = isDevEnvironment;

export const request = async (path: string, method: string, payload?: unknown) => {
    const body = payload ? JSON.stringify(payload as string) : undefined;
    const response = await fetch(apiUrl + path, {
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        method,
        body,
    });
    if (response.ok) {
        return response;
    }
    throw response;
};

export const requestWithAuth = async (
    path: string,
    method: string,
    token: string,
    payload?: unknown,
) => {
    const body = payload ? JSON.stringify(payload as string) : undefined;
    const response = await fetch(apiUrl + path, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        credentials: "include",
        method,
        body,
    });
    if (response.ok) {
        return response;
    }
    throw response;
};
