import confetti from "canvas-confetti";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import Celebrate from "~/src/media/svg/good_celebrate.svg";
import language from "~/src/utils/language";
import ButtonSecondary from "../tools/buttons/buttonSecondary";
import "./great.css";

const Great = () => {
    const navigate = useNavigate();
    const { locales } = useContext(LanguageContext);
    const { COMMON, GREAT } = language[locales];
    confetti();

    const handleNavigation = () => {
        navigate("/home");
    };

    return (
        <div className="great-wrapper-background">
            <div className="great-message-wrapper">
                <h1 className="font1">{GREAT.HEADING}</h1>
                <p>{GREAT.SUB_HEADING}</p>
                <img src={Celebrate} alt={GREAT.IMG_TEXT} />
                <ButtonSecondary size="large" className="great-button" onClick={handleNavigation}>
                    {COMMON.YES.toUpperCase()}
                </ButtonSecondary>
            </div>
        </div>
    );
};

export default Great;
