import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DoneImg from "~/src/media/png/done.png";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./done.css";

type TDone = {
    navigateUrl: string;
    text: string;
};

const Done = ({ navigateUrl, text }: TDone) => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate(navigateUrl);
        }, 1400);
    }, []);

    return (
        <div className="done-wrapper">
            <RoundWrapper className="done-content-wrapper">
                <h1 className="font1">{text}</h1>
                <img src={DoneImg} alt="people looking on done mark" />
            </RoundWrapper>
        </div>
    );
};

export default Done;
