import confetti from "canvas-confetti";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import { LanguageContext } from "~/src/contexts/languageContext";
import Cheer from "~/src/media/svg/cheer.svg";
import language from "~/src/utils/language";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./allQuestionDone.css";

const AllQuestionDone = ({ mutable }: { mutable: boolean | undefined }) => {
    const { locales } = useContext(LanguageContext);
    const { COMMON, ALLQUESTION } = language[locales];
    const navigate = useNavigate();
    const duration = 5 * 1000;
    const animationEnd = Date.now() + duration;
    const colors = ["#4956CA", "#E18D95", "#DFE1EC"];
    const defaults = { startVelocity: 20, spread: 90, ticks: 100, zIndex: 0 };

    const randomInRange = (min: number, max: number) => {
        return Math.random() * (max - min) + min;
    };

    const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();
        if (timeLeft <= 0) {
            clearInterval(interval);
            return;
        }
        const particleCount = 50 * (timeLeft / duration);
        confetti({
            ...defaults,
            colors,
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        });
        confetti({
            ...defaults,
            colors,
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        });
    }, 250);

    return (
        <div className="allQuestionDone-wrapper">
            <RoundWrapper className="allQuestionDone-content-wrapper">
                <h1 className="font1">{ALLQUESTION.HEADING}</h1>
                <p>{ALLQUESTION.SUBHEADING}</p>
                <img src={Cheer} alt={ALLQUESTION.IMG} />
                {mutable && (
                    <>
                        <p className="bodyText14">
                            {ALLQUESTION.CHANGE_ANSWER}
                            &nbsp;
                            <Link to="/beb">{COMMON.BUTTON_MYSTORY.toLowerCase()}</Link>.
                        </p>
                        <p className="bodyText14">
                            {ALLQUESTION.SUPPORT1}
                            &nbsp;
                            <Link to="/support">{ALLQUESTION.SUPPORT2}</Link>.
                        </p>
                    </>
                )}
                <ButtonPrimary
                    testid="form-done-all"
                    size="medium"
                    className="allQuestionDone-button"
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    {COMMON.OK}
                </ButtonPrimary>
            </RoundWrapper>
        </div>
    );
};

export default AllQuestionDone;
