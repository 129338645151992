import { GetAvatar } from "@ommej/componente";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import ArrowWhiteIcon from "~/src/media/icons/arrow_white.svg";
import JustPencilIcon from "~/src/media/icons/justPencil.svg";
import language from "~/src/utils/language";
import ButtonSecondary from "../tools/buttons/buttonSecondary";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./avatarDone.css";

type AvatarDoneProps = {
    setToggleComponent: (arg0: boolean) => void;
};

const AvatarDone = ({ setToggleComponent }: AvatarDoneProps) => {
    const { locales } = useContext(LanguageContext);
    const { profile } = useContext(ProfileContext);
    const navigate = useNavigate();
    const { COMMON } = language[locales];

    return (
        <div className="avatarDone-wrapper">
            <RoundWrapper>
                <h2 className="font1">Cool Avatar!</h2>
                <p>Är du redo på att svara på några frågor?</p>
                <div className="avatarDone-avatar-wrapper">
                    <GetAvatar avatarData={profile.avatar} />
                    <ButtonSecondary
                        ariaLabel={COMMON.CHANGE_AVATAR}
                        size="small"
                        icon={{ url: JustPencilIcon, layout: "round" }}
                        onClick={() => {
                            setToggleComponent(false);
                        }}
                    />
                </div>
                <div className="avatarDone-button-wrapper">
                    <ButtonPrimary
                        size="large"
                        icon={{ url: ArrowWhiteIcon, layout: "right" }}
                        onClick={() => {
                            navigate("home");
                        }}
                    >
                        Färdig
                    </ButtonPrimary>
                </div>
            </RoundWrapper>
        </div>
    );
};

export default AvatarDone;
