import type * as React from "react";
import ButtonSecondary from "../tools/buttons/buttonSecondary";

interface Interface {
    text: string;
    onClick: () => void;
    type?: "big" | "small";
    marked?: boolean;
    testid?: string;
}

const AnswerText: React.FC<Interface> = ({ text, onClick, type, testid, marked = false }) => {
    return type === "big" ? (
        <ButtonSecondary
            testid={testid}
            onClick={onClick}
            className={
                marked
                    ? "form-answer-alternative-bigbutton-marked"
                    : "form-answer-alternative-bigbutton"
            }
            size="large"
        >
            {text}
        </ButtonSecondary>
    ) : (
        <ButtonSecondary
            size="small"
            testid={testid}
            onClick={onClick}
            className={
                marked ? "form-answer-alternative-button-marked" : "form-answer-alternative-button"
            }
        >
            {text}
        </ButtonSecondary>
    );
};

export default AnswerText;
