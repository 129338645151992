import { Capacitor } from "@capacitor/core";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";
import { Fragment, useContext, useEffect, useId, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "~/src/components/header/header";
import RoundWrapper from "~/src/components/tools/roundWrapper/roundWrapper";
import { LanguageContext } from "~/src/contexts/languageContext";
import { isDemo } from "~/src/utils/api";
import language from "~/src/utils/language";
import { MoodCheckNotifications } from "~/src/utils/moodcheckNotifications";
import { Settings } from "~/src/utils/storage";
import { track } from "~/src/utils/utils";
import "./settings.css";

const SettingsView = () => {
    const { locales } = useContext(LanguageContext);
    const { COMMON } = language[locales];
    const navigate = useNavigate();
    const eContent = useRef<HTMLDivElement>(null);
    const [showInfoMessage, setShowInfoMessage] = useState(false);

    const SETTINGS: Array<{
        label: string;
        name: string;
        getInitValue: () => Promise<boolean>;
        cb: (name: string, checked: boolean) => void;
    }> = [
        {
            label: COMMON.MOODCHECK,
            name: "mood_notify",
            getInitValue: async (): Promise<boolean> => {
                const allowed = await MoodCheckNotifications.isAllowed();
                if (!allowed) {
                    return false;
                }
                const value = await Settings.get("mood_notify");
                if (typeof value === "boolean") {
                    return value;
                }
                return false;
            },
            cb: async (name: string, checked: boolean) => {
                track(`settings-notify-mood_check-${checked ? "on" : "off"}`);
                await Settings.set(name, checked);
                setShowInfoMessage(false);
                if (!checked) {
                    await MoodCheckNotifications.cancelAll();
                } else {
                    await MoodCheckNotifications.schedule(false);
                    const allowed = await MoodCheckNotifications.isAllowed();
                    if (!allowed) {
                        await Settings.set(name, false);
                        setShowInfoMessage(true);
                    }
                }
            },
        },
    ];

    useEffect(() => {
        async function load() {
            const eInputs = eContent.current?.querySelectorAll("input");
            // biome-ignore lint/complexity/noForEach: probably easier to use forEach when async
            eInputs?.forEach(async (eInput) => {
                const setting = SETTINGS.find((s) => {
                    return s.name === eInput.name;
                });
                let initValue = false;
                if (setting) {
                    initValue = await setting.getInitValue();
                }
                // stupido
                // eslint-disable-next-line no-param-reassign
                eInput.checked = initValue;
            });
        }
        load();
    }, []);

    return (
        <div className={`content-wrapper ${isDemo ? "app-demo-background" : "content-dark"}`}>
            <Header
                handleBack={() => {
                    return navigate("/myprofile");
                }}
                header={COMMON.SETTINGS}
            />
            <RoundWrapper>
                <h2 style={{ textAlign: "left", marginBottom: "1.5rem", marginTop: "0.5rem" }}>
                    {COMMON.ALLOW_NOTIFICATIONS}
                </h2>
                <div className="settings-content" ref={eContent}>
                    {SETTINGS.map((setting) => {
                        const id = useId();
                        return (
                            <Fragment key={id}>
                                {/* htmlFor specifies the label for the input... */}
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label htmlFor={id}>{setting.label}</label>
                                <input
                                    type="checkbox"
                                    id={id}
                                    name={setting.name}
                                    onChange={(e) => {
                                        setting.cb(e.target.name, e.target.checked);
                                    }}
                                />
                            </Fragment>
                        );
                    })}
                    {showInfoMessage && Capacitor.isNativePlatform() && (
                        <p className="bodyText12">
                            <button
                                style={{
                                    textAlign: "left",
                                    background: "none",
                                    color: "var(--color-red-danger)",
                                }}
                                type="button"
                                onClick={() => {
                                    NativeSettings.open({
                                        optionAndroid: AndroidSettings.ApplicationDetails,
                                        optionIOS: IOSSettings.App,
                                    });
                                }}
                            >
                                {`${COMMON.NOTIFICATION_SYS_SETTINGS_1} ${COMMON.NOTIFICATION_SYS_SETTINGS_2}`}
                            </button>
                        </p>
                    )}
                    {showInfoMessage && !Capacitor.isNativePlatform() && (
                        <p className="bodyText12" style={{ color: "var(--color-red-danger)" }}>
                            {COMMON.NOTIFICATION_SYS_SETTINGS_1}
                        </p>
                    )}
                </div>
            </RoundWrapper>
        </div>
    );
};
export default SettingsView;
