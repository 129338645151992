import { houseStrings, houses, personTypes } from "@ommej/metadata";
import type { AvatarId, House, HouseTypes, Uuid } from "@ommej/types";
import { useContext, useState } from "react";
import type * as React from "react";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import language from "~/src/utils/language";
import { getHousePath } from "~/src/utils/utils";
import ButtonSecondary from "../tools/buttons/buttonSecondary";
import "./houses.css";

type THousesProps = {
    handleHouseClicked: (house: House | undefined) => void;
    picker?: boolean;
    houseExists?: boolean;
    personId?: Uuid;
};

const Houses = ({ handleHouseClicked, picker, houseExists, personId }: THousesProps) => {
    const { locales } = useContext(LanguageContext);
    const { COMMON, HOUSES } = language[locales];
    const { profile } = useContext(ProfileContext);
    const [chosenHouse, setChosenHouse] = useState<House>();

    const handleHouse = (
        e: React.MouseEvent<HTMLButtonElement>,
        type: HouseTypes,
        avatar: AvatarId,
        id?: Uuid,
    ) => {
        e.preventDefault();
        const house = { avatar, type, id };
        setChosenHouse(house);
    };

    const handleNoHouse = () => {
        handleHouseClicked(undefined);
    };

    const getHouseIndex = () => {
        if (profile.houses) {
            return Object.keys(profile.houses).length + 1;
        }
        return 1;
    };

    const sendHouse = () => {
        if (chosenHouse) {
            handleHouseClicked(chosenHouse);
        }
    };

    return (
        <>
            {houseExists ? (
                <>
                    {personId && (
                        <h2 className="houses-header">
                            {`${HOUSES.SUBHEADER_HOUSEEXISTS} ${
                                personTypes[profile?.persons?.[personId]?.type as string]?.lang[
                                    locales
                                ]
                            }`}
                        </h2>
                    )}
                    <div className="houses-wrapper">
                        {profile.houses &&
                            Object.entries(profile.houses).map(([id, houseObj]) => {
                                return (
                                    <button
                                        key={id}
                                        type="button"
                                        onClick={(e) => {
                                            // "as HouseTypes": https://github.com/microsoft/TypeScript/issues/35101
                                            handleHouse(
                                                e,
                                                houseObj.type as HouseTypes,
                                                houseObj.avatar,
                                                id,
                                            );
                                        }}
                                    >
                                        <img
                                            src={getHousePath(houseObj.avatar)}
                                            alt=""
                                            className={
                                                chosenHouse?.avatar === houseObj.avatar
                                                    ? "houses-selected"
                                                    : ""
                                            }
                                        />
                                        <p className="bodyText14">{`${
                                            HOUSES.HOUSE
                                        } ${getHouseIndex()}`}</p>
                                    </button>
                                );
                            })}
                    </div>
                    <ButtonSecondary
                        size="small"
                        className="houses-noHouse-button"
                        onClick={() => {
                            handleNoHouse();
                        }}
                    >
                        {HOUSES.BUTTON_NO}
                    </ButtonSecondary>
                </>
            ) : (
                <>
                    <h1 className="font1 houses-header">{HOUSES.HEADER}</h1>
                    {!picker && <h4 className="houses-subheader">{HOUSES.SUBHEADER}</h4>}
                    {!picker && (
                        <p className="bodyText14">{`${HOUSES.HOUSE} ${getHouseIndex()}`}</p>
                    )}
                    <div className="houses-wrapper">
                        {Object.entries(houses)
                            .filter(([type]) => {
                                return type !== "UNKNOWN";
                            })
                            .map(([type, avatars]) => {
                                return avatars.map((avatar) => {
                                    return (
                                        <button
                                            key={avatar}
                                            type="button"
                                            onClick={(e) => {
                                                // "as HouseTypes": https://github.com/microsoft/TypeScript/issues/35101
                                                handleHouse(e, type as HouseTypes, avatar);
                                            }}
                                        >
                                            <img
                                                src={getHousePath(avatar)}
                                                alt=""
                                                className={
                                                    chosenHouse?.avatar === avatar
                                                        ? "houses-selected"
                                                        : ""
                                                }
                                            />
                                            <p className="bodyText14">
                                                {houseStrings[type as HouseTypes].lang[locales]}
                                            </p>
                                        </button>
                                    );
                                });
                            })}
                    </div>
                </>
            )}
            {chosenHouse && (
                <footer className="houses-footer-wrapper">
                    <ButtonSecondary
                        className="houses-ready-button"
                        size="medium"
                        onClick={() => {
                            sendHouse();
                        }}
                    >
                        {COMMON.BUTTON_READY}
                    </ButtonSecondary>
                </footer>
            )}
        </>
    );
};

export default Houses;
