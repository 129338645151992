import type { Form } from "@ommej/types";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import RoundWrapper from "~/src/components/tools/roundWrapper/roundWrapper";
import language from "~/src/utils/language";
// will be handled by a plugin for parcel
// eslint-disable-next-line import/no-unresolved
import * as images from "../../media/svg/form-*.svg";

const FormIntro = ({
    introData,
    owner,
    unit,
    locales,
    onButtonClick,
}: {
    introData: Required<Form.Metadata>["intro"];
    owner?: string | undefined;
    unit?: string | undefined;
    locales: "sv";
    onButtonClick: () => void;
}) => {
    const { FORM } = language[locales];

    return (
        <div className="done-wrapper">
            <RoundWrapper className="form-article form-intro-wrapper">
                <div style={{ display: "grid", placeItems: "center", margin: "0rem 1rem" }}>
                    {introData.image && images[introData.image] && (
                        <img
                            style={{ marginTop: "1.5rem", maxHeight: "20rem" }}
                            src={images[introData.image]}
                            alt=""
                        />
                    )}
                    <h2 className="font1" style={{ marginTop: "2rem", marginBottom: "0.5rem" }}>
                        {introData.header[locales]
                            .replaceAll("%owner%", owner || "okänd")
                            .replaceAll("%unit%", unit || "okänd")}
                    </h2>
                    <div
                        className="form-intro-text"
                        style={{}}
                        /* eslint-disable-next-line react/no-danger */
                        // biome-ignore lint/security/noDangerouslySetInnerHtml: we have control over the inout
                        dangerouslySetInnerHTML={{
                            __html: introData.text[locales]
                                .replaceAll("%owner%", owner || "okänd")
                                .replaceAll("%unit%", unit || "okänd"),
                        }}
                    />
                    <ButtonPrimary
                        size="large"
                        testid="form-answer-alternative"
                        onClick={() => {
                            onButtonClick();
                        }}
                    >
                        {FORM.TO_QUESTIONS}
                    </ButtonPrimary>
                </div>
            </RoundWrapper>
        </div>
    );
};

export default FormIntro;
