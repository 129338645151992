import { useCallback, useContext, useEffect, useState } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import { AuthContext } from "~/src/contexts/authContext";
import { LanguageContext } from "~/src/contexts/languageContext";
import CheckIcon from "~/src/media/icons/check.svg";
import DeleteIconWhite from "~/src/media/icons/delete_white.svg";
import DummyAvatar from "~/src/media/svg/dummy_avatar.svg";
import type { Invitations as InvitationsType } from "~/src/types";
import { isDemo, request } from "~/src/utils/api";
import language from "~/src/utils/language";
import Header from "../header/header";
import ErrorComponent from "../tools/errorComponent/errorComponent";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./invitations.css";

const Invitations = () => {
    const userContext = React.useContext(AuthContext);
    const navigate = useNavigate();
    const { locales } = useContext(LanguageContext);
    const { REQUESTS, ERROR } = language[locales];
    const [errorMessage, setError] = useState<string>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [invitations, setInvitations] = useState<InvitationsType | undefined>(
        userContext.user?.invitations,
    );

    const getAllInvitations = async () => {
        try {
            const res = await request("clients", "GET");
            const resUser = await res.json();
            setInvitations(resUser.invitations);
            setIsLoaded(true);
        } catch (_err) {
            setError(ERROR.GENERIC);
        }
    };

    useEffect(() => {
        if (!userContext.user?.invitations?.accepted[0]?.owner) {
            getAllInvitations();
        } else {
            setIsLoaded(true);
        }
    }, []);

    const reloadInvitations = useCallback(async () => {
        if (!userContext.user) {
            return;
        }
        const res = await request("clients/invitations", "GET");
        const inv = await res.json();
        setInvitations(inv);
        userContext.setUser({ ...userContext.user, invitations: inv });
    }, []);

    const onAcceptClick = useCallback(async (invitationId: string | undefined) => {
        if (!invitationId) {
            console.error("No invitation!");
            setError(ERROR.GENERIC);
            return;
        }
        try {
            await request("clients/invitations/accepted", "PUT", {
                invitation: invitationId,
                username: " ", // TODO: not needed? see comment in backend
            });
            reloadInvitations();
        } catch (_err) {
            setError(ERROR.GENERIC);
        }
    }, []);

    const onDeclineClick = useCallback(async (invitationId: string | undefined) => {
        if (!invitationId) {
            console.error("No invitation!");
            setError(ERROR.GENERIC);
            return;
        }
        try {
            await request(`clients/invitations/pending/${invitationId}`, "DELETE", {});
            reloadInvitations();
        } catch (_err) {
            setError(ERROR.GENERIC);
        }
    }, []);

    if (!invitations) {
        return (
            <div className={`content-wrapper ${isDemo ? "app-demo-background" : "content-dark"}`}>
                <Header
                    header={REQUESTS.HEADER}
                    handleBack={() => {
                        navigate("/home");
                    }}
                />
                {REQUESTS.NO_INVITATIONS}
            </div>
        );
    }
    if (!isLoaded) {
        return null;
    }
    return (
        <div className={`content-wrapper ${isDemo ? "app-demo-background" : "content-dark"}`}>
            <Header
                header={REQUESTS.HEADER}
                handleBack={() => {
                    navigate("/home");
                }}
            />
            {invitations.pending?.length !== 0 && (
                <>
                    <h2 className="font4">{REQUESTS.PENDING}</h2>
                    <ul>
                        {invitations.pending?.map((invitation) => {
                            return (
                                <li key={invitation.id}>
                                    <RoundWrapper className="invitations-wrapper">
                                        <div>
                                            <h3 className="invitations-heading">
                                                {invitation.owner}
                                            </h3>
                                            <p className="invitations-subheading">
                                                {invitation.unit}
                                            </p>
                                            <p className="invitations-date">
                                                {invitation.created &&
                                                    `${REQUESTS.CREATED} ${new Date(
                                                        invitation.created,
                                                    ).toLocaleDateString()}`}
                                            </p>
                                        </div>
                                        <div className="invitations-buttons">
                                            <ButtonPrimary
                                                size="xs"
                                                icon={{ url: CheckIcon, layout: "left" }}
                                                className="invitations-accept-button"
                                                onClick={() => {
                                                    onAcceptClick(invitation.id);
                                                }}
                                            >
                                                {REQUESTS.ACCEPT}
                                            </ButtonPrimary>
                                            <button
                                                className="invitations-decline-button"
                                                type="button"
                                                onClick={() => {
                                                    onDeclineClick(invitation.id);
                                                }}
                                            >
                                                <img
                                                    src={DeleteIconWhite}
                                                    alt="delete invitation"
                                                />
                                            </button>
                                        </div>
                                    </RoundWrapper>
                                </li>
                            );
                        })}
                    </ul>
                    <hr className="invitations-group-divider" />
                </>
            )}
            {invitations.accepted?.length !== 0 && (
                <>
                    <h2 className="font4">{REQUESTS.ACCEPTED}</h2>
                    <ul>
                        {invitations.accepted?.map((invitation) => {
                            const { status } = invitation;
                            return (
                                <li key={invitation.id}>
                                    <RoundWrapper
                                        className={`invitations-accepted-wrapper ${
                                            status?.status === "paused" ||
                                            status?.status === "closed"
                                                ? "invitation-noAdmin"
                                                : ""
                                        } `}
                                    >
                                        <div className="invitation-profile-wrapper">
                                            <img src={DummyAvatar} alt="avatar" />
                                            <div className="invitation-text-wrapper">
                                                <h3 className="invitations-heading">
                                                    {invitation.owner}
                                                </h3>
                                                <p className="invitations-subheading">
                                                    {invitation.unit}
                                                </p>
                                                <p className="invitations-date">
                                                    {invitation.created &&
                                                        `${REQUESTS.CREATED} ${new Date(
                                                            invitation.created,
                                                        ).toLocaleDateString()}`}
                                                </p>
                                            </div>
                                        </div>
                                        {status?.status === "paused" && (
                                            <p className="invitation-noAdmin-text">
                                                {REQUESTS.PAUSED}
                                            </p>
                                        )}
                                        {status?.status === "closed" && (
                                            <p className="invitation-noAdmin-text">
                                                {REQUESTS.CLOSED}
                                            </p>
                                        )}
                                    </RoundWrapper>
                                </li>
                            );
                        })}
                    </ul>
                </>
            )}

            {errorMessage && <ErrorComponent errorText={errorMessage} />}
        </div>
    );
};

export default Invitations;
