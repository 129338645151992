import type { House } from "@ommej/types";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import type { HouseState } from "~/src/types";
import { request } from "~/src/utils/api";
import language from "~/src/utils/language";
import { reloadProfile } from "~/src/utils/profileUtils";
import Header from "../header/header";
import Houses from "../profile/houses";

const HousePicker = () => {
    const { setProfile } = useContext(ProfileContext);
    const { locales } = useContext(LanguageContext);
    const { HOUSE_PICKER } = language[locales];
    const navigate = useNavigate();
    const location = useLocation();
    const houseState = location.state as HouseState | undefined;
    const handleBack = () => {
        navigate(-1);
    };

    const handleHouseClicked = async (chosenHouse: House | undefined) => {
        if (!chosenHouse || !houseState) {
            navigate("/");
            return;
        }
        const houseId = Object.keys(houseState.state.house);
        const sendObject = {
            avatar: chosenHouse.avatar,
            type: chosenHouse.type,
        };
        try {
            await request(`clients/profile/houses/${houseId[0]}`, "PATCH", sendObject);
            await reloadProfile(setProfile);
            navigate(-1);
        } catch (error: unknown) {
            console.error("error:", error);
        }
    };

    return (
        <div className="content-wrapper">
            <Header handleBack={handleBack} header={HOUSE_PICKER.HEADING} />
            <Houses handleHouseClicked={handleHouseClicked} picker />
        </div>
    );
};

export default HousePicker;
