import type { ButtonPrimary as ButtonPrimaryProps } from "~/src/types";
import ButtonCommon from "./buttonCommon";
import "./buttonPrimary.css";

const ButtonPrimary = ({
    children,
    className,
    size,
    color = "blue",
    disabled,
    onClick,
    type = "button",
    icon,
    testid,
    umamiEvent,
}: ButtonPrimaryProps) => {
    return (
        <ButtonCommon
            className={className}
            size={size}
            color={color}
            disabled={disabled}
            onClick={onClick}
            type={type}
            buttonType="primary"
            icon={icon}
            testid={testid}
            umamiEvent={umamiEvent}
        >
            {children}
        </ButtonCommon>
    );
};

export default ButtonPrimary;
