import { useContext } from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import { LanguageContext } from "~/src/contexts/languageContext";
import ExitIcon from "~/src/media/icons/exit.svg";
import language from "~/src/utils/language";
import "./consent.css";
import { consentContent } from "./consentConstants";

type TConsent = {
    handleConsent: () => void;
};

const Consent = ({ handleConsent }: TConsent) => {
    const { locales } = useContext(LanguageContext);
    const { HEADER, INTRODUCTION, USE_INFORMATION, WHAT_INFORMATION, APPROVE } = consentContent;
    const { COMMON } = language[locales];

    return (
        <div className="consent-wrapper">
            <div className="consent-heading-wrapper">
                <h1 className="font1">{HEADER[locales]}!</h1>
                <strong className="bodyText14">{INTRODUCTION[locales]}</strong>
            </div>
            <div className="consent-list-wrapper">
                <h2 className="font2 bodyText14">{WHAT_INFORMATION.SUBHEADER[locales]}</h2>
                <ul>
                    {WHAT_INFORMATION.CONTENT[locales].map((content) => {
                        return <li key={content}>{content}</li>;
                    })}
                </ul>
                <h2 className="font2 bodyText14">{USE_INFORMATION.SUBHEADER[locales]}</h2>
                <ul>
                    {USE_INFORMATION.CONTENT[locales].map((content) => {
                        return <li key={content}>{content}</li>;
                    })}
                </ul>
            </div>
            <div className="consent-approve-wrapper">
                <h2 className="font1">{APPROVE[locales]}</h2>
                <ButtonPrimary size="large" onClick={handleConsent}>
                    {COMMON.APPROVE}
                </ButtonPrimary>
                <Link to="https://ommej.se/om-ommej/sakerhet/">
                    {COMMON.READ_MORE} <img src={ExitIcon} alt="Lämnar nuvarande site" />
                </Link>
            </div>
        </div>
    );
};

export default Consent;
