import type { Uuid } from "@ommej/types";
import { useState } from "react";
import * as React from "react";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import { LanguageContext } from "~/src/contexts/languageContext";
import SmileyBad from "~/src/media/svg/smiley_bad.svg";
import SmileyCrappy from "~/src/media/svg/smiley_crappy.svg";
import SmileyGood from "~/src/media/svg/smiley_good.svg";
import SmileyGreat from "~/src/media/svg/smiley_great.svg";
import SmileyMeh from "~/src/media/svg/smiley_meh.svg";
import language from "~/src/utils/language";
import type { QuestionView } from "./form";
import FormFooter from "./formFooter";
import FormQuestion from "./formQuestion";

const QuestionRate: React.FC<QuestionView> = ({ question, setAnswer, existingAnswer }) => {
    const { locales } = React.useContext(LanguageContext);
    const { COMMON, FORM } = language[locales];
    const [currentAnswer, setCurrentAnswer] = useState<Uuid>();
    const onAnswerClick = (answerId: Uuid) => {
        setCurrentAnswer(answerId);
        setTimeout(() => {
            setAnswer(question.id, [{ answer: answerId, data: [], timestamp: new Date() }]);
        }, 300);
    };

    const smileys = [SmileyCrappy, SmileyBad, SmileyMeh, SmileyGreat, SmileyGood];

    function getClasses(answerId: Uuid) {
        const classes: Array<string> = ["form-answer-alternative-rate"];

        if (
            (currentAnswer && currentAnswer === answerId) ||
            (!currentAnswer && answerId === existingAnswer?.[0]?.answer)
        ) {
            classes.push("form-answer-alternative-rate-marked");
        } else if (currentAnswer || existingAnswer?.[0]?.answer) {
            classes.push("form-answer-alternative-rate-faded");
        }

        return classes.join(" ");
    }

    return (
        <div className="form-question-wrapper">
            <FormQuestion text={question.question.text.sv} />
            <div id="form-answers-rate" className="form-answers-rate">
                {Object.entries(question.question.answers)
                    // sort according to weight to make ios app happy
                    .sort(([answerIdA], [answerIdB]) => {
                        return (
                            (question.question.answers[answerIdA].weight ?? 0) -
                            (question.question.answers[answerIdB].weight ?? 0)
                        );
                    })
                    .map(([answerId, answer]) => {
                        switch (answer.type) {
                            case "text":
                                return (
                                    <button
                                        type="button"
                                        key={answerId}
                                        data-testid="form-answer-alternative"
                                        onClick={() => {
                                            onAnswerClick(answerId);
                                        }}
                                        className={getClasses(answerId)}
                                    >
                                        <img
                                            src={smileys[(answer.weight ?? 1) - 1]}
                                            alt={answer.text.sv}
                                        />
                                    </button>
                                );
                            case "none":
                                return (
                                    <FormFooter key={answerId}>
                                        <ButtonPrimary
                                            size="large"
                                            testid="form-answer-alternative"
                                            onClick={() => {
                                                onAnswerClick(answerId);
                                            }}
                                        >
                                            {COMMON.BUTTON_NEXT}
                                        </ButtonPrimary>
                                    </FormFooter>
                                );
                            default:
                                return <span key={answerId}>{FORM.UNKNOWN}</span>;
                        }
                    })}
            </div>
            <div className="form-answers-rate">
                <span className="text-grey" style={{ marginLeft: "0.5rem", width: "5.2rem" }}>
                    {FORM.DISAGREE}
                </span>
                <span
                    className="text-grey"
                    style={{ marginRight: "0.5rem", width: "5.2rem", textAlign: "right" }}
                >
                    {FORM.AGREE}
                </span>
            </div>
        </div>
    );
};

export default QuestionRate;
