import type { ButtonText as ButtonTextProps } from "~/src/types";
import ButtonCommon from "./buttonCommon";
import "./buttonText.css";

const ButtonText = ({
    children,
    className,
    size,
    disabled,
    onClick,
    type = "button",
    icon,
    testid,
    ariaLabel,
}: ButtonTextProps) => {
    return (
        <ButtonCommon
            className={className}
            size={size}
            disabled={disabled}
            onClick={onClick}
            type={type}
            buttonType="text"
            icon={icon}
            testid={testid}
            ariaLabel={ariaLabel}
        >
            {children}
        </ButtonCommon>
    );
};

export default ButtonText;
