import * as React from "react";

type NotificationContextProps = {
    children: React.ReactNode;
};

export type NotificationContextType = {
    received: {
        haveNotification: boolean;
        setHaveNotification: React.Dispatch<React.SetStateAction<boolean>>;
    };
    pressed: {
        havePressedNotification: boolean;
        setHavePressedNotification: React.Dispatch<React.SetStateAction<boolean>>;
    };
};

export const NotificationContext = React.createContext({} as NotificationContextType);

export const NotificationProvider = ({ children }: NotificationContextProps) => {
    const [haveNotification, setHaveNotification] = React.useState(false);
    const [havePressedNotification, setHavePressedNotification] = React.useState(false);

    const value = React.useMemo(() => {
        return {
            received: { haveNotification, setHaveNotification },
            pressed: { havePressedNotification, setHavePressedNotification },
        };
    }, [haveNotification, havePressedNotification]);

    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
