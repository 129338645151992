import * as React from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { NotificationContext } from "~/src/contexts/notificationContext";
import ArrowLongIcon from "~/src/media/icons/arrow_long.svg";
import CheckBlueIcon from "~/src/media/icons/check-blue.svg";
import HomeIcon from "~/src/media/icons/home.svg";
import ShareIcon from "~/src/media/icons/share_indigo.svg";
import SettingsIcon from "~/src/media/svg/settings.svg";
import language from "~/src/utils/language";
import "./header.css";

type THeaderProps = {
    handleBack?: () => void;
    buttonText?: string;
    header: string | React.ReactNode;
    rightIcon?: "HOME" | "DONE" | "SHARE" | "SETTINGS";
    handleHeaderClick?: () => void;
    marginBottom?: string;
};

const Header = ({
    handleBack,
    buttonText,
    header,
    rightIcon,
    handleHeaderClick,
    marginBottom = "2rem",
}: THeaderProps) => {
    const { locales } = React.useContext(LanguageContext);
    const {
        received: { haveNotification },
    } = React.useContext(NotificationContext);
    const { COMMON } = language[locales];
    const handleRightIcon = (iconString: string) => {
        switch (iconString) {
            case "HOME":
                return (
                    <Link to="/home" className="start-link header-button-right">
                        <img src={HomeIcon} alt="home-button" />
                        {haveNotification && <div className="notification-dot">&nbsp;</div>}
                    </Link>
                );
            case "SHARE":
                return (
                    <Link to="/beb/share" className="start-link header-button-right">
                        <img
                            src={ShareIcon}
                            alt="share button"
                            style={{ transform: "rotate(180deg)" }}
                        />
                        {COMMON.BUTTON_SHARE}
                    </Link>
                );
            case "SETTINGS":
                return (
                    <Link to="/settings" className="start-link header-button-right">
                        <img width="22" src={SettingsIcon} alt="settings button" />
                    </Link>
                );
            default:
                return (
                    <button
                        type="button"
                        className="header-button-right"
                        onClick={handleHeaderClick}
                    >
                        <img src={CheckBlueIcon} alt="check icon" />
                        {COMMON.BUTTON_READY}
                    </button>
                );
        }
    };
    return (
        <header className="header-wrapper" style={{ marginBottom }}>
            <div className="header-content">
                <button
                    aria-label={COMMON.BACK}
                    type="button"
                    className="header-button-left"
                    onClick={handleBack}
                >
                    <img
                        src={ArrowLongIcon}
                        alt={COMMON.BACK}
                        style={{ transform: "rotate(180deg)" }}
                    />
                    {buttonText && buttonText}
                </button>
                <h1 className="font2" style={{ textAlign: "center", lineHeight: "2.1rem" }}>
                    {header}
                </h1>
                {rightIcon && handleRightIcon(rightIcon)}
            </div>
        </header>
    );
};

export default Header;
