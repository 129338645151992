import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { STORAGE_KEY_CODES } from "../utils/constants";

const Connect = () => {
    const params = useParams();
    const { code } = params;

    useEffect(() => {
        if (!code) {
            window.location.href = "/";
            return;
        }

        const newCodes: string[] = [];

        const existingCodes = localStorage.getItem(STORAGE_KEY_CODES);
        if (existingCodes) {
            try {
                const codes: string[] = JSON.parse(existingCodes);
                newCodes.push(...codes);
                if (!newCodes.includes(code)) {
                    newCodes.push(code);
                }
            } catch (e) {
                console.error(e);
                // something is not right with the saved value, let's remove it
                localStorage.removeItem(STORAGE_KEY_CODES);
            }
        } else {
            newCodes.push(code);
        }

        try {
            localStorage.setItem(STORAGE_KEY_CODES, JSON.stringify(newCodes));
        } catch (e: unknown) {
            console.error(e);
        }

        window.location.href = "/";
    }, []);

    return null;
};

export default Connect;
