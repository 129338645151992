import { AnswersViewer } from "@ommej/componente";
import type { AnswersOnQuestions, Form } from "@ommej/types";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import { isDemo, request } from "~/src/utils/api";
import language from "~/src/utils/language";
import Header from "../header/header";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import Spinner from "../tools/spinner/spinner";

const RequestForm = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { profile } = useContext(ProfileContext);
    const { locales } = useContext(LanguageContext);
    const { REQUEST_FORM } = language[locales];
    const [form, setForm] = useState<Form.FormData>();
    const [formAnswers, setFormAnswers] = useState<AnswersOnQuestions>();
    const [loadDone, setLoadDone] = useState(false);

    const getForm = useCallback(async () => {
        try {
            const res = await request(`forms/${params.formId}/${params.formVersion}`, "GET");
            const resForm = await res.json();
            setForm(resForm);
        } catch (_err) {
            console.error("Failed to get form");
        }
    }, []);

    const getAnswers = useCallback(async () => {
        try {
            const url = `clients/answers/${params.formId}/${params.formVersion}/${params.invitationId}/${params.requestId}`;
            const res = await request(url, "GET");
            const resAnswers = await res.json();
            setFormAnswers(resAnswers.answers);
        } catch (_err) {
            console.error("Failed to get answers");
            // OK, probably no answers in backend yet
        }
    }, []);

    useEffect(() => {
        async function load() {
            await Promise.all([getForm(), getAnswers()]);
            setLoadDone(true);
        }
        load();
        return () => {
            setLoadDone(false);
        };
    }, []);

    if (!loadDone || !params) {
        return (
            <div className="content-wrapper">
                <Header
                    header={REQUEST_FORM.HEADER}
                    handleBack={() => {
                        navigate("/beb");
                    }}
                />
                <Spinner />
            </div>
        );
    }
    if (!form || !formAnswers) {
        return null;
    }
    return (
        <div className={`content-wrapper ${isDemo ? "app-demo-background" : "content-dark"}`}>
            <Header
                header={REQUEST_FORM.HEADER}
                handleBack={() => {
                    navigate("/beb");
                }}
            />
            <RoundWrapper>
                <AnswersViewer
                    form={form}
                    answersData={{ format: "answers", data: formAnswers }}
                    profile={profile}
                />
            </RoundWrapper>
        </div>
    );
};

export default RequestForm;
