import { houses, personTypes } from "@ommej/metadata";
import type { Form, Profile, Uuid } from "@ommej/types";
import { request } from "~/src/utils/api";
import type { ButtonLayout, ButtonSize } from "../types";

export function getHousePath(id: string | undefined): string {
    if (!id) {
        return `/assets/houses/${houses.UNKNOWN[0]}.svg`;
    }
    return `/assets/houses/${id}.svg`;
}

export function getPersonalQuestion(
    profile: Profile,
    question: Partial<Form.FormQuestion> | undefined,
    personId: Uuid,
): string {
    if (!question || !question.text) {
        return "";
    }
    const q = profile.persons?.[personId]?.type
        ? question.text.sv.replaceAll(
              "%data%",
              personTypes[profile.persons[personId].type as string].lang.sv,
          )
        : question.text.sv;
    return q;
}
export async function getFormMetadata(ref: Form.Ref): Promise<Form.Metadata | undefined> {
    try {
        const res = await request(`forms/${ref.id}/${ref.version}/metadata`, "GET");
        return res.json();
    } catch (_err) {
        console.error("Failed to get form metadata");
        return undefined;
    }
}

export const getFormName = (formMeta: Form.Metadata[], id: string, version: string) => {
    const metaObj = formMeta.find((meta) => {
        return meta.id === id && meta.version === version;
    });
    return metaObj?.name;
};

export const buttonClassNames = (layout?: ButtonLayout, size?: ButtonSize) => {
    if (size === "xs") {
        return `b-xs-${layout}`;
    }
    return `b-${layout}`;
};

export const capitalizeFirstLetter = (str: string) => {
    if (!str) {
        return "";
    }
    return str[0].toUpperCase() + str.slice(1);
};

export function track(event: string) {
    try {
        umami.track(event);
    } catch (_error: unknown) {
        // OK to not being tracked
    }
}
