import type { ReactNode } from "react";
import * as React from "react";
import { LanguageContext } from "~/src/contexts/languageContext";
import language from "~/src/utils/language";
import "./videoComponent.css";

type TVideoComponentProps = {
    videoUrl: string;
    captions?: boolean;
    heading: ReactNode;
    subheading?: string;
    videoStyle?: React.CSSProperties;
};

const VideoComponent = ({
    videoUrl,
    captions,
    heading,
    subheading,
    videoStyle,
}: TVideoComponentProps) => {
    const { locales } = React.useContext(LanguageContext);
    const { COMMON } = language[locales];

    let style = videoStyle;
    if (!style) {
        style = { width: "12rem" };
    }

    return (
        <div className="videoComponent-wrapper">
            <div className="videoComponent-text-wrapper">
                {heading}
                {subheading && <p className="bodyText12">{subheading}</p>}
            </div>
            <video
                crossOrigin="anonymous"
                poster={`${videoUrl}.jpg`}
                preload="metadata"
                style={style}
                controls
            >
                <source src={videoUrl} type="video/mp4" />
                {captions && (
                    <track
                        label="Svenska"
                        kind="captions"
                        srcLang="sv"
                        src={`${videoUrl}.vtt`}
                        default
                    />
                )}
                {COMMON.VIDEO}
            </video>
        </div>
    );
};

export default VideoComponent;
