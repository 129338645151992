import { Device } from "@capacitor/device";
import { GetAvatar } from "@ommej/componente";
import { AvatarGroups, type MoodsLevel0, avatars, moodsLevel0 } from "@ommej/metadata";
import { useCallback, useContext, useEffect, useState } from "react";
import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ErrorComponent from "~/src/components/tools/errorComponent/errorComponent";
import Modal from "~/src/components/tools/modal";
import { AuthContext } from "~/src/contexts/authContext";
import { LanguageContext } from "~/src/contexts/languageContext";
import { NotificationContext } from "~/src/contexts/notificationContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import ArrowLongIcon from "~/src/media/icons/arrow_long.svg";
import CheckRoundIcon from "~/src/media/icons/check_round.svg";
import PencilIcon from "~/src/media/icons/justPencil.svg";
import LogoutIcon from "~/src/media/icons/logout.svg";
import SmileyBad from "~/src/media/svg/smiley_bad.svg";
import SmileyCrappy from "~/src/media/svg/smiley_crappy.svg";
import SmileyGood from "~/src/media/svg/smiley_good.svg";
import SmileyMeh from "~/src/media/svg/smiley_meh.svg";
import SmileyOk from "~/src/media/svg/smiley_ok.svg";
import SmileySuper from "~/src/media/svg/smiley_super.svg";
import type { AvatarState } from "~/src/types";
import { request } from "~/src/utils/api";
import { STORAGE_KEY_CODES } from "~/src/utils/constants";
import language from "~/src/utils/language";
import { track } from "~/src/utils/utils";
import SeeAnswers from "../sprinkles/seeAnswers";
import ButtonSecondary from "../tools/buttons/buttonSecondary";
import "./home.css";

export const getSmiley = (mood: MoodsLevel0) => {
    switch (mood) {
        case "SUPER":
            return SmileySuper;
        case "GOOD":
            return SmileyGood;
        case "OK":
            return SmileyOk;
        case "MEH":
            return SmileyMeh;
        case "BAD":
            return SmileyBad;
        case "CRAPPY":
            return SmileyCrappy;
        default:
            return null;
    }
};

async function handleLogout() {
    try {
        const deviceId = await Device.getId();
        await request("clients/logout", "POST", { deviceId: deviceId.identifier });
        window.location.href = "/";
    } catch (err) {
        console.error("Failed to logout", err);
    }
}

const Home = () => {
    const userContext = React.useContext(AuthContext);
    const { profile } = useContext(ProfileContext);
    const [profileReady, setProfileReady] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [showPendingInvitation, setShowPendingInvitation] = useState(false);
    const { locales } = React.useContext(LanguageContext);
    const { COMMON, HOME, CODE } = language[locales];
    const navigate = useNavigate();
    const currentAvatar = profile.avatar ?? avatars.DEFAULT[0];
    const notificationContext = useContext(NotificationContext);
    const [showInvitationError, setShowInvitationError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [chosenMood, setChosenMood] = useState<MoodsLevel0>("");
    const [moodStreak, setMoodStreak] = useState<number>(0);
    const location = useLocation();

    const getClient = async () => {
        try {
            const res = await request("clients", "GET");
            const resUser = await res.json();
            userContext.setUser({ id: resUser.id, invitations: resUser.invitations, auth: true });
            setIsLoaded(true);
        } catch (err) {
            console.error(err);
        }
    };

    const getInvitations = useCallback(async () => {
        try {
            const res = await request("clients/invitations", "GET");
            const resInvitations = await res.json();
            if (!userContext.user) {
                return;
            }
            const currentUser = { ...userContext.user, invitations: resInvitations };
            userContext.setUser(currentUser);
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        const getMood = async () => {
            try {
                const today = new Date().toLocaleDateString("sv-SE");
                const res = await request(`clients/moods?date=${today}`, "GET");
                if (res.status === 200) {
                    const resMoods = await res.json();
                    if (resMoods) {
                        setChosenMood(resMoods.level0);
                        setMoodStreak(resMoods.currentStreak);
                        if (
                            resMoods.currentStreak > 0 &&
                            location.state &&
                            location.state.from === "mood"
                        ) {
                            window.history.replaceState(null, "");
                            setOpenModal(true);
                        }
                    }
                }
            } catch (error) {
                console.error(error);
            }
        };
        getMood();
    }, []);

    useEffect(() => {
        if (notificationContext.received.haveNotification) {
            // clear notifications
            notificationContext.received.setHaveNotification(false);

            window.location.reload();
        }
    }, [notificationContext.received.haveNotification]);

    useEffect(() => {
        if (notificationContext.pressed.havePressedNotification) {
            // clear state
            notificationContext.pressed.setHavePressedNotification(false);

            if (!userContext?.user?.invitations.pending?.length) {
                setShowInvitationError(true);
            }
        }
    }, [notificationContext.pressed.havePressedNotification]);

    useEffect(() => {
        if (!userContext.user) {
            getClient();
        } else {
            getInvitations();
            setIsLoaded(true);
        }

        const codes = localStorage.getItem(STORAGE_KEY_CODES);
        if (codes) {
            try {
                const codeArray: string[] = JSON.parse(codes);
                if (codeArray.length > 0) {
                    setShowPendingInvitation(true);
                }
            } catch (e) {
                console.error(e);
                // something is not right with the saved value, let's remove it
                localStorage.removeItem(STORAGE_KEY_CODES);
            }
        }
    }, []);

    const handleNavigate = (url: string, state?: AvatarState) => {
        navigate(`/${url}`, state && { state });
    };

    useEffect(() => {
        if (profile?.persons && profile.houses && profile.housePersonMap) {
            const ready =
                Object.keys(profile.persons).length !== 0 &&
                Object.keys(profile.houses).length !== 0 &&
                Object.keys(profile.housePersonMap).length !== 0;
            setProfileReady(ready);
        }
    }, [profile]);

    const getProfileLink = () => {
        if (
            !profile.avatar ||
            (typeof profile.avatar === "object" &&
                profile.avatar.type === "ID" &&
                profile.avatar.data === "default")
        ) {
            return {
                link: "avatar",
                state: { ageGroup: AvatarGroups.CHILD, avatarChildSelection: true },
            };
        }
        if (!profile.birthYear || profile.birthYear === 0) {
            return { link: "birthyear" };
        }
        if (!profile.gender) {
            return { link: "gender" };
        }
        // there is two steps before we save house/persons to the profile
        if (!profile.houses || !profile.persons) {
            return { link: "accommodation" };
        }
        return { link: "myprofile" };
    };

    useEffect(() => {
        if (userContext.user?.invitations) {
            const { pending } = userContext.user.invitations;
            if (pending.length > 0) {
                setShowPendingInvitation(true);
            }
        }
    }, [userContext.user]);

    const handleMood = (mood: MoodsLevel0) => {
        setChosenMood(mood);
        return handleNavigate(`mood/${mood.toLowerCase()}`);
    };

    if (!isLoaded) {
        return null;
    }

    if (showInvitationError) {
        return (
            <ErrorComponent
                errorText={COMMON.INVALID_INVITATION}
                handleErrorComponent={() => {
                    setShowInvitationError(false);
                }}
            />
        );
    }

    if (showPendingInvitation) {
        return <SeeAnswers />;
    }

    const moodstreakDaysString =
        moodStreak === 1 ? COMMON.DAY.toLowerCase() : COMMON.DAYS.toLocaleLowerCase();
    const inARowString = moodStreak === 1 ? "." : ` ${HOME.MODAL_MOOD_IN_A_ROW}.`;

    return (
        <div className="content-wrapper">
            <Modal
                isOpen={openModal}
                buttonPositive={profileReady ? HOME.BUTTON_YES : undefined}
                buttonCancel={profileReady ? HOME.BUTTON_CANCEL : COMMON.BUTTON_OK}
                onClickPositive={
                    profileReady
                        ? () => {
                              track("mood_check-done-form");
                              handleNavigate("form");
                          }
                        : undefined
                }
                handleClose={() => {
                    track(
                        profileReady ? "mood_check-done-close" : "mood_check-done-close-no-profile",
                    );
                    setOpenModal(false);
                }}
            >
                <div className="home-moodstreak-img">{moodStreak}</div>
                <h1 className="font1 home-moodstreak-header">{HOME.MODAL_MOOD_HEADER}</h1>
                {profileReady ? (
                    <p>{`${HOME.MODAL_MOOD_TEXT1} ${moodStreak} ${moodstreakDaysString}${inARowString} ${HOME.MODAL_MOOD_TEXT2}`}</p>
                ) : (
                    <p>{`${HOME.MODAL_MOOD_TEXT1} ${moodStreak} ${moodstreakDaysString}${inARowString} ${HOME.MODAL_MOOD_TEXT2_NO_PROFILE}`}</p>
                )}
            </Modal>
            <div className="home-avatar-wrapper">
                <GetAvatar avatarData={currentAvatar} />
                <ButtonSecondary
                    ariaLabel={COMMON.CHANGE_AVATAR}
                    size="xs"
                    icon={{ url: PencilIcon, layout: "round" }}
                    onClick={() => {
                        handleNavigate("avatar", {
                            state: { ageGroup: AvatarGroups.CHILD, avatarChildSelection: true },
                        });
                    }}
                />
            </div>
            <div className="home-mood-wrapper">
                <div className="home-mood-header">
                    <div>
                        <h2 className="font2">{HOME.MOOD_TODAY}</h2>
                        {moodStreak > 0 && (
                            <p className="home-mood-text">
                                {HOME.MOODSTREAK}: {`${moodStreak} ${moodstreakDaysString}`}
                            </p>
                        )}
                    </div>
                    <Link to="/moodchart" className="buttons-wrapper b-text b-small b-round">
                        <img src={ArrowLongIcon} alt="to moodchart" />
                    </Link>
                </div>
                <div className="home-mood-content">
                    {Object.entries(moodsLevel0).map(([mood, moodMetadata]) => {
                        return (
                            <div className="home-mood-card" key={mood}>
                                <button
                                    type="button"
                                    className={`${
                                        chosenMood && chosenMood !== mood
                                            ? "home-button-opacity"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        return handleMood(mood);
                                    }}
                                >
                                    <img src={getSmiley(mood)} alt={moodMetadata.lang[locales]} />
                                    {chosenMood === mood && (
                                        <img
                                            src={CheckRoundIcon}
                                            alt={`checked mood is ${chosenMood}`}
                                            className="home-mood-checked"
                                        />
                                    )}
                                </button>
                                <p className="home-mood-text">{moodMetadata.lang[locales]}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
            {profileReady ? (
                <Link
                    to="/form/"
                    className="buttons-wrapper pb-blue b-primary b-medium home-navigation-buttons"
                >
                    {HOME.ANSWER_QUESTIONS}
                </Link>
            ) : (
                <Link
                    to={getProfileLink().link}
                    // intentionally nested state objects, that's what the rest of the code expects
                    state={{ state: getProfileLink().state }}
                    className="buttons-wrapper pb-blue b-primary b-medium home-navigation-buttons"
                >
                    {HOME.MY_PROFILE_MAKE_READY}
                </Link>
            )}
            <Link
                to="/beb"
                className="buttons-wrapper b-secondary b-medium home-navigation-buttons"
            >
                {HOME.MY_STORY}
            </Link>
            {profileReady && (
                <Link
                    to="/myprofile"
                    className="buttons-wrapper b-secondary b-medium home-navigation-buttons"
                >
                    {HOME.MY_PROFILE}
                </Link>
            )}
            {((userContext.user?.invitations?.accepted &&
                userContext.user.invitations.accepted.length !== 0) ||
                (userContext.user?.invitations?.pending &&
                    userContext.user.invitations.pending.length !== 0)) && (
                <Link
                    to="/invitations"
                    className="buttons-wrapper b-secondary b-medium home-navigation-buttons"
                >
                    {HOME.MY_INVITATIONS}

                    {userContext.user?.invitations?.pending &&
                        userContext.user.invitations.pending.length !== 0 && (
                            <span className="home-invitation-contacts">
                                <span>{userContext.user?.invitations.pending.length}</span>
                            </span>
                        )}
                </Link>
            )}
            <Link
                to="/support"
                className="buttons-wrapper b-secondary b-medium home-navigation-buttons"
            >
                {HOME.SUPPORT}
            </Link>
            <Link to="/code" className="buttons-wrapper b-text b-medium home-code-button">
                {CODE.HEADER_NORMAL}
            </Link>
            <ButtonSecondary
                size="small"
                icon={{ url: LogoutIcon, layout: "left" }}
                onClick={handleLogout}
            >
                {COMMON.LOGOUT}
            </ButtonSecondary>
        </div>
    );
};

export default Home;
