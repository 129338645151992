import type { InvitationClient } from "@ommej/types";
import { useCallback, useContext, useEffect, useState } from "react";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import { AuthContext } from "~/src/contexts/authContext";
import { LanguageContext } from "~/src/contexts/languageContext";
import SeeAnswersImg from "~/src/media/svg/seeAnswer.svg";
import type { Invitations } from "~/src/types";
import { request } from "~/src/utils/api";
import { STORAGE_KEY_CODES } from "~/src/utils/constants";
import language from "~/src/utils/language";
import ErrorComponent from "../tools/errorComponent/errorComponent";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./seeAnswers.css";

function removeCodeFromLocalStorage(code: string): void {
    const codes = localStorage.getItem(STORAGE_KEY_CODES);
    if (codes) {
        try {
            const codeArray: string[] = JSON.parse(codes);
            const index = codeArray.indexOf(code);
            if (index > -1) {
                codeArray.splice(index, 1);
            }
            localStorage.setItem(STORAGE_KEY_CODES, JSON.stringify(codeArray));
        } catch (e) {
            console.error(e);
            // something is not right with the saved value, let's remove it
            localStorage.removeItem(STORAGE_KEY_CODES);
        }
    }
}

const SeeAnswers = () => {
    const { locales } = useContext(LanguageContext);
    const { COMMON, SEE_ANSWERS } = language[locales];
    const userContext = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState<string | boolean>();
    const [pendingInvitations, setPendingInvitations] = useState<
        Invitations["pending"] | undefined
    >(userContext.user?.invitations?.pending);

    useEffect(() => {
        // if we have a code, convert it to an invitation that can be used in the
        // rest of the component.
        async function codeToInvitation() {
            const codes = localStorage.getItem(STORAGE_KEY_CODES);
            if (codes) {
                try {
                    const codeArray: string[] = JSON.parse(codes);
                    const code = codeArray.pop();

                    if (code) {
                        const res = await request(`clients/invitations?code=${code}`, "GET");
                        const invitation: InvitationClient = await res.json();
                        setPendingInvitations([invitation]);
                    }
                } catch (e) {
                    console.error(e);
                    // something is not right with the saved value, let's remove it
                    localStorage.removeItem(STORAGE_KEY_CODES);
                    setErrorMessage(COMMON.INVALID_INVITATION);
                }
            }
        }
        codeToInvitation();
    }, []);

    const onAcceptClick = useCallback(async ({ invitation }: { invitation: InvitationClient }) => {
        try {
            await request("clients/invitations/accepted", "PUT", {
                code: invitation.code,
            });
            removeCodeFromLocalStorage(invitation.code);
            window.location.href = "/";
        } catch (err: any) {
            console.error(err);
            // something went wrong, let's remove the code so we don't end up in a
            // continuous loop of smackebonks. The user can try again by clicking
            // the link or enter the code manually.
            removeCodeFromLocalStorage(invitation.code);
            if (err.status === 409) {
                setErrorMessage(COMMON.INVALID_INVITATION_FULL);
                return;
            }
            setErrorMessage(true);
        }
    }, []);

    const onDeclineClick = useCallback(async ({ invitation }: { invitation: InvitationClient }) => {
        // remove the denied code from localStorage, if it's there
        removeCodeFromLocalStorage(invitation.code);

        try {
            await request(`clients/invitations/pending/${invitation.id}`, "DELETE", {});
        } catch (err) {
            console.error(err);
            // If we got the invitation from code, the invitation might not be among
            // our pending invitations (if it is, we now can decline a pending
            // invitation via code \o/) and then we'll get an error trying to remove
            // it from pending. If this fail for valid reasons (i.e., we fail to
            // remove an existing pending invitation), the user will get a smackebonk
            // again, and again, and again. What can we do? We can show an error
            // message, but the smackebonks will still also be shown.
            //
            // We redirect to home where invitations will be reloaded, let's hope
            // that will solve trying to decline a non-existing pending invitation.
        }
        window.location.href = "/";
    }, []);

    return (
        <div className="seeAnswers-wrapper">
            {pendingInvitations?.slice(0, 1).map((inv) => {
                return (
                    <RoundWrapper className="seeAnswers-content" key={inv.id}>
                        <img src={SeeAnswersImg} alt="People with phones and computers" />
                        <h1 className="font1">{SEE_ANSWERS.HEADER}</h1>
                        <p>
                            <span>{inv.owner || SEE_ANSWERS.ADMIN}</span>
                            {SEE_ANSWERS.TEXT}
                        </p>
                        <p>{`${COMMON.CODE}: ${inv.code}`}</p>
                        <ButtonPrimary
                            size="large"
                            onClick={() => {
                                onAcceptClick({ invitation: inv });
                            }}
                        >
                            {COMMON.BUTTON_ACCEPT}
                        </ButtonPrimary>
                        <button
                            className="seeAnswers-button"
                            type="button"
                            onClick={() => {
                                onDeclineClick({ invitation: inv });
                            }}
                        >
                            {COMMON.BUTTON_DECLINE}
                        </button>
                    </RoundWrapper>
                );
            })}
            {errorMessage && (
                <ErrorComponent
                    errorText={errorMessage === true ? undefined : (errorMessage as string)}
                    handleErrorComponent={() => {
                        // make a hard reload to get the latest invitations
                        window.location.href = "/";
                    }}
                />
            )}
        </div>
    );
};

export default SeeAnswers;
