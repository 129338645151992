import { GetAvatar } from "@ommej/componente";
import { type Houses, houseStrings, personTypes } from "@ommej/metadata";
import type { AvatarId, SingleAnswer, Uuid } from "@ommej/types";
import { useContext, useEffect, useRef, useState } from "react";
import * as React from "react";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import language from "~/src/utils/language";
import { getHousePath } from "~/src/utils/utils";
import AnswersText from "./answersText";
import { type QuestionViewResource, getPersonsInHouse } from "./form";
import "./form.css";
import FormQuestion from "./formQuestion";

type Qst = {
    text: string;
    avatar: AvatarId;
    id: Uuid | undefined;
};

const QuestionSinglePersonAccommodation: React.FC<QuestionViewResource> = ({
    question,
    setAnswer,
    resources,
    existingAnswer,
}) => {
    const { locales } = React.useContext(LanguageContext);
    const { FORM } = language[locales];
    const { profile } = useContext(ProfileContext);
    const [currentQuestion, setCurrentQuestion] = useState<Qst>();
    const [templateData, setTemplateData] = useState([...question.data]);
    const answers = useRef<Map<Uuid, SingleAnswer>>(new Map<Uuid, SingleAnswer>());
    const currentData = useRef<Uuid | undefined>();

    function markAnswers(data: Uuid | undefined) {
        if (!existingAnswer || !data) {
            return;
        }
        for (const existing of existingAnswer) {
            // do we have an existing answer for the current person? if so, it should
            // be added to answers
            if (existing.data.includes(data)) {
                answers.current.set(data, {
                    answer: existing.answer,
                    data: [data],
                    timestamp: existing.timestamp,
                });
            }
        }
    }
    const onAnswerClick = (answerId: Uuid) => {
        // currentData.currentData should never be undefined here
        if (currentData.current) {
            answers.current.set(currentData.current, {
                answer: answerId,
                data: [currentData.current],
                timestamp: new Date(),
            });
        }
        const newTemplateData = [...templateData];
        const data = newTemplateData.shift();
        currentData.current = data;
        setTemplateData(newTemplateData);
        markAnswers(data);
        if (data) {
            const resource = resources[data];
            if (!resource) {
                return;
            }
            if (question.question.type === "single_accommodation") {
                setCurrentQuestion({
                    text: resource.type
                        ? question.question.text.sv.replaceAll(
                              "%data%",
                              houseStrings[resource.type as keyof Houses].lang.sv,
                          )
                        : "unknown",
                    avatar: resource.avatar,
                    id: resource.id,
                });
            } else {
                setCurrentQuestion({
                    text: resource.type
                        ? question.question.text.sv.replaceAll(
                              "%data%",
                              personTypes[resource.type].lang.sv,
                          )
                        : "unknown", // should never happen
                    avatar: resource.avatar,
                    id: resource.id,
                });
            }
        } else {
            setAnswer(question.id, Array.from(answers.current.values()));
        }
    };

    useEffect(() => {
        answers.current.clear();
        const newTemplateData = [...question.data];
        const data = newTemplateData.shift();
        currentData.current = data;
        setTemplateData(newTemplateData);
        markAnswers(data);
        if (data) {
            const resource = resources[data];
            if (!resource) {
                return;
            }
            if (question.question.type === "single_accommodation") {
                setCurrentQuestion({
                    text: resource.type
                        ? question.question.text.sv.replaceAll(
                              "%data%",
                              houseStrings[resource.type as keyof Houses].lang.sv,
                          )
                        : "unknown",
                    avatar: resource.avatar,
                    id: resource.id,
                });
            } else {
                setCurrentQuestion({
                    text: resource.type
                        ? question.question.text.sv.replaceAll(
                              "%data%",
                              personTypes[resource.type].lang.sv,
                          )
                        : "unknown",
                    avatar: resource.avatar,
                    id: resource.id,
                });
            }
        } else {
            // this should never happen - we should not end up in this question
            // type/component without any data
            console.error("This should not happen");
        }
    }, [question]);

    return currentQuestion ? (
        <div className="form-question-wrapper">
            <FormQuestion text={currentQuestion.text} />
            <div className="form-question-resource">
                {question.question.type === "single_person" ? (
                    <GetAvatar avatarData={currentQuestion.avatar} />
                ) : (
                    <img
                        className="form-questionSinglePerson-img"
                        src={getHousePath(currentQuestion.avatar)}
                        alt="avatar"
                    />
                )}
                <p className="questionPersons-person-text">
                    {question.question.type === "single_accommodation" &&
                        getPersonsInHouse(language[locales], profile, currentQuestion.id)}
                </p>
            </div>
            <div className="form-answers">
                {Object.entries(question.question.answers).map(([answerId, answer]) => {
                    switch (answer.type) {
                        case "text":
                            return (
                                <AnswersText
                                    testid="form-answer-alternative"
                                    key={answerId}
                                    text={answer.text.sv}
                                    onClick={() => {
                                        onAnswerClick(answerId);
                                    }}
                                    type={
                                        Object.keys(question.question.answers).length < 4
                                            ? "big"
                                            : "small"
                                    }
                                    marked={
                                        !!currentData.current &&
                                        answers.current.get(currentData.current)?.answer ===
                                            answerId
                                    }
                                />
                            );
                        default:
                            return <span key={answerId}>{FORM.UNKNOWN}</span>;
                    }
                })}
            </div>
        </div>
    ) : null;
};

export default QuestionSinglePersonAccommodation;
