import type * as React from "react";

type TStep = {
    isActive?: boolean;
    children: React.ReactNode;
};

const Step = ({ isActive, children }: TStep) => {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return isActive === false ? null : <>{children}</>;
};

export default Step;
