import { GetAvatar } from "@ommej/componente";
import { personTypes } from "@ommej/metadata";
import { getQuestion } from "@ommej/pregunta";
import type { BebMentions, Form, Uuid } from "@ommej/types";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import PencilIcon from "~/src/media/icons/pencil.svg";
import { isDemo } from "~/src/utils/api";
import language from "~/src/utils/language";
import { getHousePath, getPersonalQuestion } from "~/src/utils/utils";
import Header from "../header/header";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./houses.css";

type HousesProps = {
    data: Record<Uuid, BebMentions>;
    inactiveData: Record<Uuid, BebMentions>;
    form: Form.FormData;
    close: () => void;
};

const Houses = ({ data, inactiveData, form, close }: HousesProps) => {
    const { profile } = useContext(ProfileContext);
    const { locales } = useContext(LanguageContext);
    const { COMMON, BEB } = language[locales];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function getListItems(mentionedData: BebMentions, houseId: Uuid, editable: boolean) {
        return mentionedData.mentioned?.map((mentionedIn: { question: Uuid; answer: Uuid }) => {
            const question = getQuestion(form, undefined, mentionedIn.question);
            const q = getPersonalQuestion(profile, question?.question, houseId);
            return (
                <li className="bodyText14" key={houseId + mentionedIn.answer}>
                    <div className="houses-question-wrapper">
                        <p>{`${q.replace(BEB.REPLACE, "")}`}</p>
                        {editable && (
                            <Link
                                className="beb-edit-answer-link"
                                to={`/form/${form.metadata.id}/${form.metadata.version}/${mentionedIn.question}`}
                                state={{
                                    back: "/beb/houses",
                                    next: "/beb",
                                }}
                            >
                                <img src={PencilIcon} alt={BEB.CHANGE_QUESTION} />
                            </Link>
                        )}
                    </div>
                </li>
            );
        });
    }

    // handles showing houses that only have inactive answers
    function getInactiveLeftovers(
        active: Record<Uuid, BebMentions>,
        inactive: Record<Uuid, BebMentions>,
    ) {
        const onlyInInactive = Object.keys(inactive).filter((key) => {
            return !active[key];
        });
        return onlyInInactive.map((houseId) => {
            const houseData = inactive[houseId];
            return (
                <RoundWrapper key={houseId} className="persons-persons-wrapper">
                    {profile.houses && (
                        <h2>
                            {`${BEB.HOUSES_ACCOMMODATION} ${
                                Object.keys(profile.houses).indexOf(houseId) + 1
                            }`}
                        </h2>
                    )}
                    <div className="houses-persons-wrapper">
                        {profile.housePersonMap?.[houseId]?.map((person) => {
                            return (
                                <div className="houses-person-card" key={person}>
                                    {profile?.persons?.[person].avatar && (
                                        <GetAvatar avatarData={profile?.persons?.[person].avatar} />
                                    )}
                                    <p className="bodyText12">
                                        {profile.persons &&
                                            personTypes[profile.persons[person].type as string].lang
                                                .sv}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                    <ul>{getListItems(houseData, houseId, true)}</ul>
                </RoundWrapper>
            );
        });
    }

    if (!data) {
        return null;
    }
    return (
        <div className={`content-wrapper ${isDemo ? "app-demo-background" : "content-dark"}`}>
            <Header
                handleBack={() => {
                    close();
                }}
                header={BEB.HOUSES_HEADER}
            />
            {Object.entries(data).map(([houseId, houseData]) => {
                return (
                    <RoundWrapper key={houseId} className="persons-persons-wrapper">
                        {profile.houses && (
                            <div style={{ display: "grid", placeItems: "center", gap: "0.75rem" }}>
                                <img
                                    src={getHousePath(profile.houses[houseId].avatar)}
                                    alt={`${BEB.HOUSES_ACCOMMODATION} ${
                                        Object.keys(profile.houses).indexOf(houseId) + 1
                                    }`}
                                />
                                <h2 className="font3">
                                    {`${BEB.HOUSES_ACCOMMODATION} ${
                                        Object.keys(profile.houses).indexOf(houseId) + 1
                                    }`}
                                </h2>
                            </div>
                        )}
                        <div className="houses-persons-wrapper">
                            {profile.housePersonMap?.[houseId]?.map((person) => {
                                return (
                                    <div className="houses-person-card" key={person}>
                                        {profile?.persons?.[person].avatar && (
                                            <GetAvatar
                                                avatarData={profile?.persons?.[person].avatar}
                                            />
                                        )}
                                        <p className="bodyText12">
                                            {profile.persons &&
                                                personTypes[profile.persons[person].type as string]
                                                    .lang.sv}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <ul>{getListItems(houseData, houseId, true)}</ul>
                        {inactiveData[houseId] && (
                            <details className="inactive-answers-details">
                                <summary>{COMMON.INACTIVE_ANSWERS}</summary>
                                <p className="details-info">{COMMON.INACTIVE_ANSWERS_INFO}</p>
                                <ul className="inactive-answers">
                                    {getListItems(inactiveData[houseId], houseId, false)}
                                </ul>
                            </details>
                        )}
                    </RoundWrapper>
                );
            })}
            {getInactiveLeftovers(data, inactiveData)}
        </div>
    );
};

export default Houses;
