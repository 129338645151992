import type * as React from "react";
import PencilIcon from "~/src/media/icons/justPencil.svg";
import ButtonSecondary from "../buttons/buttonSecondary";
import "./roundWrapper.css";

type TRoundWrapperProps = {
    children: React.ReactNode;
    className?: string;
    background?: string;
    color?: string;
    handlePencilClick?: () => void;
    ariaLabel?: string;
};

const RoundWrapper = ({
    children,
    className,
    background = "#fff",
    color,
    handlePencilClick,
    ariaLabel,
}: TRoundWrapperProps) => {
    return (
        <div className={`roundWrapper-wrapper ${className}`} style={{ background, color }}>
            {handlePencilClick && (
                <ButtonSecondary
                    className="roundWrapper-button-wrapper roundWrapper-img"
                    size="xs"
                    icon={{ layout: "round", url: PencilIcon }}
                    onClick={handlePencilClick}
                    ariaLabel={ariaLabel || ""}
                />
            )}
            {children}
        </div>
    );
};

export default RoundWrapper;
