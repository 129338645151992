import { useContext } from "react";
import { LanguageContext } from "~/src/contexts/languageContext";
import ArrowLongIcon from "~/src/media/icons/arrow_long.svg";
import language from "~/src/utils/language";
import "./header.css";

interface Interface {
    func: (() => void) | null;
    step: number;
}

const ProfileHeader = ({ func, step }: Interface) => {
    const { locales } = useContext(LanguageContext);
    const { COMMON } = language[locales];

    return (
        <header className="header-wrapper" style={{ marginBottom: "2rem" }}>
            <div className="header-content">
                {func && (
                    <button className="header-button-left" type="button" onClick={func}>
                        <img
                            src={ArrowLongIcon}
                            alt={COMMON.BACK}
                            style={{ transform: "rotate(180deg)" }}
                        />
                    </button>
                )}
                <progress style={{ gridColumnStart: func ? "auto" : 2 }} value={step} max="6" />
            </div>
        </header>
    );
};

export default ProfileHeader;
