const _temp0 = require("../../media/svg/form-info1.svg");
const _temp1 = require("../../media/svg/form-info2.svg");
const _temp2 = require("../../media/svg/form-info3.svg");
const _temp3 = require("../../media/svg/form-intro-bb_base.svg");
const _temp4 = require("../../media/svg/form-mot1.svg");
const _temp5 = require("../../media/svg/form-mot2.svg");
const _temp6 = require("../../media/svg/form-mot3.svg");
module.exports = {
  "info1": _temp0,
  "info2": _temp1,
  "info3": _temp2,
  "intro-bb_base": _temp3,
  "mot1": _temp4,
  "mot2": _temp5,
  "mot3": _temp6
}