export const minLength = {
    username: 2,
    password: 2,
    creatingPassword: 6,
    invitation: 2,
};

export const STORAGE_KEY_CODES = "ommej-app-codes";

export const FORM_DEFAULT_ID = "general";
export const FORM_DEFAULT_VERSION = "2";
