import { GetAvatar } from "@ommej/componente";
import { AvatarGroups, avatars, genderTypes, personTypes } from "@ommej/metadata";
import { useContext } from "react";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import BigPlusIcon from "~/src/media/icons/bigPlus.svg";
import PencilIcon from "~/src/media/icons/pencil.svg";
import PlusIcon from "~/src/media/icons/plus.svg";
import type { AccommodationState, AvatarState, GenderState, HouseState } from "~/src/types";
import { isDemo } from "~/src/utils/api";
import language from "~/src/utils/language";
import { getHousePath } from "~/src/utils/utils";
import Header from "../header/header";
import ButtonSecondary from "../tools/buttons/buttonSecondary";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./myProfile.css";

const MyProfile = () => {
    const { profile } = useContext(ProfileContext);
    const { locales } = React.useContext(LanguageContext);
    const { MY_PROFILE, COMMON } = language[locales];
    const navigate = useNavigate();
    const currentAvatar = profile.avatar ?? avatars.DEFAULT[0];

    const handleNavigate = (
        url: string,
        state?: AvatarState | GenderState | HouseState | AccommodationState,
    ) => {
        navigate(`/${url}`, state && { state });
    };

    const handleChangePerson = (personId: string, type: "avatar" | "person") => {
        if (!personId || !profile.persons) {
            return;
        }
        const person = profile.persons[personId];
        const stateObj = {
            personId,
            ageGroup: personTypes[person.type ?? AvatarGroups.CHILD].data.avatarAgeGroups[0],
            type: person.type,
            avatar: person.avatar,
        };
        handleNavigate(type, { state: stateObj });
    };

    const checkPersonsNotInHouse = () => {
        if (!profile.persons || !profile.housePersonMap) {
            return [];
        }
        const set = new Set(Object.keys(profile.persons));
        Object.values(profile.housePersonMap).map((persons) => {
            return persons.map((person) => {
                return set.delete(person);
            });
        });
        return Array.from(set);
    };

    return (
        <div className={`content-wrapper ${isDemo ? "app-demo-background" : "content-dark"}`}>
            <Header
                handleBack={() => {
                    return navigate("/home");
                }}
                rightIcon="SETTINGS"
                header={MY_PROFILE.HEADER}
            />

            <RoundWrapper
                className="myProfile-avatar-section"
                ariaLabel={COMMON.CHANGE_AVATAR}
                handlePencilClick={() => {
                    handleNavigate("avatar", {
                        state: { ageGroup: AvatarGroups.CHILD, avatarChildSelection: true },
                    });
                }}
            >
                <GetAvatar downloadable avatarData={currentAvatar} />
            </RoundWrapper>

            <RoundWrapper
                className="myProfile-info-section"
                ariaLabel={COMMON.CHANGE_BIRTHYEAR}
                handlePencilClick={() => {
                    handleNavigate("birthyear");
                }}
            >
                <h2 style={{ marginTop: 0 }}>{MY_PROFILE.BORN}</h2>
                <span className="font2">{profile.birthYear ?? "-"}</span>
            </RoundWrapper>

            <RoundWrapper
                className="myProfile-info-section"
                ariaLabel={COMMON.CHANGE_GENDER}
                handlePencilClick={() => {
                    handleNavigate("gender");
                }}
            >
                <h2 style={{ marginTop: 0 }}>{MY_PROFILE.GENDER}</h2>
                {profile.gender ? (
                    <span className="font2 capitalize">
                        {genderTypes[profile.gender][locales] || "-"}
                    </span>
                ) : (
                    <span className="myProfile-grey">{MY_PROFILE.NO_GENDER}</span>
                )}
            </RoundWrapper>

            <hr className="myProfile-hr" />

            {profile.housePersonMap &&
                Object.entries(profile.housePersonMap).map(([houseId, persons], index) => {
                    return (
                        <RoundWrapper className="myProfile-accommodation-section" key={houseId}>
                            {profile.houses && (
                                <div className="myProfile-house-wrapper">
                                    <div
                                        style={{
                                            display: "grid",
                                            placeItems: "center",
                                            gap: "0.75rem",
                                        }}
                                    >
                                        {" "}
                                        <div className="myProfile-house-card">
                                            <img
                                                src={getHousePath(profile.houses[houseId].avatar)}
                                                alt={`${MY_PROFILE.ACCOMMODATION} ${index + 1}`}
                                            />
                                            <button
                                                className="myProfile-house-button"
                                                type="button"
                                                onClick={() => {
                                                    if (!profile.houses) {
                                                        return;
                                                    }
                                                    handleNavigate("houses", {
                                                        state: {
                                                            house: {
                                                                [houseId]: {
                                                                    avatar: profile.houses[houseId]
                                                                        .avatar,
                                                                    type: profile.houses[houseId]
                                                                        .type,
                                                                },
                                                            },
                                                        },
                                                    });
                                                }}
                                            >
                                                <img src={PencilIcon} alt={COMMON.CHANGE_AVATAR} />
                                            </button>
                                        </div>
                                        <h2 className="font3">{`Boende ${index + 1}`}</h2>
                                    </div>
                                </div>
                            )}
                            <div className="myProfile-persons-wrapper">
                                <h3
                                    className="font4"
                                    style={{ width: "100%", marginBottom: "1.5rem" }}
                                >
                                    {MY_PROFILE.PERSONS_LIVING}
                                </h3>
                                {persons.map((person) => {
                                    return (
                                        <div className="myProfile-person-card" key={person}>
                                            <button
                                                type="button"
                                                className="myProfile-person-button"
                                                onClick={() => {
                                                    return handleChangePerson(person, "avatar");
                                                }}
                                            >
                                                <GetAvatar
                                                    avatarData={profile?.persons?.[person].avatar}
                                                />
                                                <img
                                                    src={PencilIcon}
                                                    alt={COMMON.CHANGE_AVATAR}
                                                    className="myProfile-edit-button"
                                                />
                                            </button>
                                            <div className="bodyText12 myProfile-card-text">
                                                {profile.persons &&
                                                    personTypes[
                                                        profile.persons[person].type as string
                                                    ].lang.sv}
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="myProfile-person-card" key="new-person">
                                    <button
                                        type="button"
                                        className="myProfile-person-button"
                                        onClick={() => {
                                            handleNavigate(`accommodation/${houseId}`);
                                        }}
                                    >
                                        <img src={BigPlusIcon} alt={MY_PROFILE.ADD_ACCOMMODATION} />
                                    </button>
                                    <p className="bodyText12">{`${
                                        COMMON.BUTTON_ADD
                                    }/${COMMON.REMOVE.toLowerCase()}`}</p>
                                </div>
                            </div>
                        </RoundWrapper>
                    );
                })}
            <ButtonSecondary
                size="small"
                icon={{ url: PlusIcon, layout: "left" }}
                className="myProfile-addAccommodation-button"
                onClick={() => {
                    handleNavigate("accommodation");
                }}
            >
                {MY_PROFILE.ADD_ACCOMMODATION}
            </ButtonSecondary>
            {checkPersonsNotInHouse().length > 0 && (
                <>
                    <hr className="myProfile-hr" />
                    {profile.persons && (
                        <RoundWrapper className="myProfile-others-section">
                            <h2 className="font3" style={{ marginTop: 0 }}>
                                {MY_PROFILE.PERSONS}
                            </h2>
                            <div className="myProfile-others-wrapper">
                                {checkPersonsNotInHouse().map((person) => {
                                    return (
                                        <button
                                            key={person}
                                            type="button"
                                            className="myProfile-other-card"
                                            onClick={() => {
                                                return handleChangePerson(person, "person");
                                            }}
                                        >
                                            <GetAvatar
                                                avatarData={profile?.persons?.[person].avatar}
                                            />
                                            <img
                                                src={PencilIcon}
                                                alt={COMMON.CHANGE_AVATAR}
                                                className="myProfile-other-button"
                                            />
                                            <div className="bodyText12 myProfile-card-text">
                                                {profile.persons &&
                                                    personTypes[
                                                        profile.persons[person].type as string
                                                    ].lang.sv}
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                        </RoundWrapper>
                    )}
                </>
            )}
            <hr className="myProfile-hr" />
            <Link to="/deleteAccount" className="myProfile-delete-account">
                {MY_PROFILE.DELETE_ACCOUNT}
            </Link>
        </div>
    );
};

export default MyProfile;
