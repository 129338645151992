import { useContext, useState } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Confirmation from "~/src/components/confirmation";
import { AuthContext } from "~/src/contexts/authContext";
import { LanguageContext } from "~/src/contexts/languageContext";
import { request } from "~/src/utils/api";
import language from "~/src/utils/language";
import Header from "../header/header";

const TABS = {
    DELETE_ACCOUNT: "DELETE_ACCOUNT",
    KEEP_ANSWERS: "KEEP_ANSWERS",
    DELETED: "DELETED",
};

const DeleteAccount = () => {
    const { locales } = useContext(LanguageContext);
    const userContext = React.useContext(AuthContext);
    const { COMMON, DELETE_ACCOUNT } = language[locales];
    const [currentTab, setCurrentTab] = useState(TABS.DELETE_ACCOUNT);
    const navigate = useNavigate();

    // Need to scroll up When entering this component from myprofile
    window.scrollTo(0, 0);

    const handleDelete = async (keepAnswers: number) => {
        if (!userContext.user?.id) {
            // this should not be possible, but if it happens send the user to the
            // start page
            window.location.href = "/";
            return;
        }
        try {
            await request(
                `clients/${userContext.user.id}?keepAnswers=${keepAnswers}`,
                "DELETE",
                {},
            );
            setCurrentTab(TABS.DELETED);
            setTimeout(() => {
                userContext.setUser(null);
            }, 2000);
        } catch (err) {
            console.error(err);
        }
    };

    const tabs = {
        DELETE_ACCOUNT: {
            header: DELETE_ACCOUNT.HEADING_SURE,
            text: DELETE_ACCOUNT.TEXT_SURE,
            handlePrimary: () => {
                return setCurrentTab(TABS.KEEP_ANSWERS);
            },
            handleSecondary: undefined,
            handleText: () => {
                return navigate("/myprofile");
            },
            buttonPrimaryText: DELETE_ACCOUNT.BUTTON_CONTINUE,
            buttonSecondaryText: undefined,
            buttonTextText: COMMON.BUTTON_CANCEL,
        },
        KEEP_ANSWERS: {
            header: DELETE_ACCOUNT.HEADING_KEEP_ANSWER,
            text: DELETE_ACCOUNT.TEXT_KEEP_ANSWER,
            handlePrimary: () => {
                return handleDelete(1);
            },
            handleSecondary: () => {
                return handleDelete(0);
            },
            handleText: () => {
                return navigate("/myprofile");
            },
            buttonPrimaryText: DELETE_ACCOUNT.BUTTON_KEEP,
            buttonSecondaryText: DELETE_ACCOUNT.BUTTON_ERASE,
            buttonTextText: COMMON.BUTTON_CANCEL,
        },
        DELETED: {
            header: DELETE_ACCOUNT.HEADING_DELETED,
            text: "",
            handlePrimary: () => {
                return window.location.reload();
            },
            handleSecondary: undefined,
            handleText: undefined,
            buttonPrimaryText: COMMON.BUTTON_OK,
            buttonSecondaryText: undefined,
            buttonTextText: undefined,
        },
    };

    return (
        <>
            {currentTab !== TABS.DELETED && (
                <Header
                    handleBack={() => {
                        navigate("/myprofile");
                    }}
                    header={DELETE_ACCOUNT.HEADER}
                    rightIcon="HOME"
                />
            )}
            <Confirmation
                buttonPositive={tabs[currentTab as keyof typeof tabs].buttonPrimaryText}
                buttonNegative={tabs[currentTab as keyof typeof tabs].buttonSecondaryText}
                buttonCancel={tabs[currentTab as keyof typeof tabs].buttonTextText}
                onClickPositive={tabs[currentTab as keyof typeof tabs].handlePrimary}
                onClickNegative={tabs[currentTab as keyof typeof tabs].handleSecondary}
                onClickCancel={tabs[currentTab as keyof typeof tabs].handleText}
            >
                <h2 className="font1">{tabs[currentTab as keyof typeof tabs].header}</h2>
                <p style={{ margin: "1rem", lineHeight: "1.375rem" }}>
                    {tabs[currentTab as keyof typeof tabs].text}
                </p>
            </Confirmation>
        </>
    );
};

export default DeleteAccount;
