import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import { request } from "~/src/utils/api";
import language from "~/src/utils/language";
import Header from "../header/header";
import Gender from "../profile/gender";

const GenderPicker = () => {
    const { setProfile, profile } = useContext(ProfileContext);
    const { locales } = useContext(LanguageContext);
    const { GENDER_PICKER } = language[locales];
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleGenderClicked = async (gender: string) => {
        const sendObject = {
            gender,
        };
        try {
            await request("clients/profile/gender", "PUT", sendObject);
            setProfile({ ...profile, gender });
            navigate(-1);
        } catch (error: unknown) {
            console.error("error:", error);
        }
    };

    return (
        <div className="content-wrapper">
            <Header handleBack={handleBack} header={GENDER_PICKER.HEADING} />
            <Gender handleGenderClicked={handleGenderClicked} />
        </div>
    );
};

export default GenderPicker;
