import type { AvatarPartConstants } from "@ommej/componente";
import {
    AvatarColorSwatches,
    AvatarTabs,
    AvatarThumbs,
    RenderAvatarParts,
    avatarPartsColors,
    defaultAvatarParts,
} from "@ommej/componente";
import { avatarParts } from "@ommej/metadata";
import type { AvatarPart, AvatarParts } from "@ommej/types";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import ArrowWhiteIcon from "~/src/media/icons/arrow_white.svg";
import { request } from "~/src/utils/api";
import language from "~/src/utils/language";
import Header from "../header/header";
import AvatarDone from "../sprinkles/avatarDone";
import ButtonPrimary from "../tools/buttons/buttonPrimary";
import "./avatarBuilder.css";

type AvatarBuilderProps = {
    handleAvatarBuilderClicked?: (avatar: AvatarParts) => void;
};

type TabObject = {
    key: AvatarPartConstants;
    translatedTab: string;
};

const AvatarBuilder = ({ handleAvatarBuilderClicked }: AvatarBuilderProps) => {
    const navigate = useNavigate();
    const { profile, setProfile } = useContext(ProfileContext);
    const [avatar, setAvatar] = useState<AvatarParts | undefined>();
    const { locales } = useContext(LanguageContext);
    const { AVATAR_BUILDER, AVATAR_BUILDER_TABS, COMMON } = language[locales];
    const [activeTab, setActiveTab] = useState(Object.keys(avatarParts)[0]);
    const [showDoneDialog, setShowDoneDialog] = useState(false);
    const [activeState, setActiveState] = useState<AvatarPart>(defaultAvatarParts[0]);
    const empty = "empty";

    const translateTabs = () => {
        const tabArray: TabObject[] = [];
        for (const partObj of Object.keys(avatarParts)) {
            const obj: TabObject = {
                key: partObj as AvatarPartConstants,
                translatedTab: AVATAR_BUILDER_TABS[partObj],
            };
            tabArray.push(obj);
        }
        return tabArray;
    };

    const orderAvatarParts = (partsArray: TabObject[]): TabObject[] => {
        // Move "HEADS" to be the first avatar part to edit.
        const headIndex = partsArray.findIndex((item) => {
            return item.key === "HEADS";
        });
        if (headIndex > 0) {
            const headsTab = partsArray[headIndex];
            partsArray.splice(headIndex, 1);
            partsArray.splice(0, 0, headsTab);
        }
        return partsArray;
    };

    const preparedPartTabs = useMemo(() => {
        const translated: TabObject[] = translateTabs();
        return orderAvatarParts(translated);
    }, []);

    useEffect(() => {
        // set startvalues when component render.
        // set start tab
        const startTab = preparedPartTabs[0].key;
        setActiveTab(startTab);
        // set start avatar (either default or existing)
        let currentAvatar: AvatarParts = defaultAvatarParts;
        const existingAvatar = profile.avatar;
        if (
            existingAvatar &&
            existingAvatar instanceof Object &&
            "type" in existingAvatar &&
            "data" in existingAvatar &&
            existingAvatar.type === "PARTS"
        ) {
            currentAvatar = existingAvatar.data as AvatarParts;
        }
        setAvatar(currentAvatar);
        // set start activeState (avatarpart to be highlighted)
        const partsArray = avatarParts[startTab].base;
        let currentActiveState: AvatarPart | undefined;
        if (Array.isArray(partsArray)) {
            currentActiveState = currentAvatar?.find((item: AvatarPart) => {
                return partsArray.includes(item.id);
            });

            if (!currentActiveState && partsArray.includes(empty)) {
                currentActiveState = { id: empty };
            }
        }
        if (!currentActiveState) {
            console.error("This should not happen. Missing necessary avatarpart in avatar model");
            // There should be a object or a { id: empty } in avatarModel (Avatarparts).
            // It it's not present we create activeState with the first object from activeTab.
            const shouldHaveColor = Object.keys(avatarPartsColors).includes(activeTab);
            currentActiveState = shouldHaveColor
                ? {
                      id: avatarParts[activeTab as keyof typeof avatarParts].base[0],
                      color: avatarPartsColors[activeTab as keyof typeof avatarPartsColors][0],
                  }
                : { id: avatarParts[activeTab as keyof typeof avatarParts].base[0] };
        }
        setActiveState(currentActiveState);
    }, []);

    const handleThumb = useCallback(
        (clickedId: string): void => {
            // empty id clicked - delete old id from avatar model
            if (clickedId === empty) {
                const newAvatarArr = avatar?.filter((part) => {
                    return part.id !== activeState.id;
                });
                setAvatar(newAvatarArr);
                setActiveState({ id: empty });
                return;
            }
            const shouldHaveColor = Object.keys(avatarPartsColors).includes(activeTab);
            // add clicked id to avatar model
            if (activeState.id === empty) {
                const updateObject = shouldHaveColor
                    ? {
                          id: clickedId,
                          color:
                              activeState.color ||
                              avatarPartsColors[activeTab as keyof typeof avatarPartsColors][0],
                      }
                    : { id: clickedId };

                setAvatar([...(avatar || []), updateObject]);
                setActiveState(updateObject);
                return;
            }
            // update existing value with clicked id in avatar model
            const itemToUpdate = avatar?.find((item) => {
                return item.id === activeState.id;
            });
            if (!itemToUpdate) {
                return;
            }
            const updateObject = shouldHaveColor
                ? { id: clickedId, color: itemToUpdate.color }
                : { id: clickedId };

            const search = avatar?.map((item) => {
                return item.id === itemToUpdate?.id ? updateObject : item;
            });

            setAvatar(search);
            setActiveState(updateObject);
        },
        [avatar, activeState],
    );

    const handleColor = (color: string) => {
        const updateObject = { id: activeState.id, color };
        const newAvatarArray = avatar?.map((item) => {
            return item.id === activeState.id ? updateObject : item;
        });
        setActiveState(updateObject);
        setAvatar(newAvatarArray);
    };

    const handleTab = useCallback(
        (tab: string) => {
            setActiveTab(tab);
            const currentArray = avatarParts[tab as keyof typeof avatarParts].base;
            const currentActive = avatar?.find((item: AvatarPart) => {
                return currentArray.includes(item.id);
            });
            if (currentActive) {
                setActiveState(currentActive);
            } else {
                setActiveState({ id: empty });
            }
        },
        [avatar],
    );

    const sendAvatar = async () => {
        const sendObject = {
            avatar: {
                type: "PARTS",
                data: avatar,
            },
        };
        try {
            const req = await request("clients/profile/avatar", "PUT", sendObject);
            if (req.ok) {
                if (Array.isArray(avatar)) {
                    setProfile({ ...profile, avatar: { type: "PARTS", data: avatar } });
                }
                setShowDoneDialog(true);
            }
        } catch (err) {
            console.error(err);
        }
    };

    if (!avatar) {
        return <p>{COMMON.LOADING}</p>;
    }
    if (showDoneDialog) {
        return <AvatarDone setToggleComponent={setShowDoneDialog} />;
    }
    return (
        <>
            <div className="content-wrapper">
                {!handleAvatarBuilderClicked && (
                    <Header
                        header={AVATAR_BUILDER.HEADER}
                        handleBack={() => {
                            navigate(-1);
                        }}
                    />
                )}
                <div className="avatarBuilder-avatar-wrapper">
                    <RenderAvatarParts title="Din nuvarande avatar" avatarModel={avatar} />
                </div>
                <h3 className="avatarBuilder-header-text">{AVATAR_BUILDER[activeTab]}</h3>
                <AvatarTabs
                    tabsArray={preparedPartTabs}
                    activeTab={activeTab}
                    handleTab={handleTab}
                />
                <AvatarThumbs
                    activeArray={avatarParts[activeTab as keyof typeof avatarParts].base}
                    activeThumb={activeState}
                    avatarModel={avatar}
                    handleThumb={handleThumb}
                />
                {avatarPartsColors[activeTab as keyof typeof avatarPartsColors] &&
                    activeState.color && (
                        <AvatarColorSwatches
                            colorArray={
                                avatarPartsColors[activeTab as keyof typeof avatarPartsColors]
                            }
                            activeColor={activeState.color}
                            handleColor={handleColor}
                        />
                    )}
            </div>
            <div className="accommodation-button-wrapper">
                <ButtonPrimary
                    size="large"
                    icon={{ url: ArrowWhiteIcon, layout: "right" }}
                    className="gender-done-button gender-show"
                    onClick={() => {
                        if (handleAvatarBuilderClicked) {
                            handleAvatarBuilderClicked(avatar);
                        } else {
                            sendAvatar();
                        }
                    }}
                >
                    {COMMON.BUTTON_READY}
                </ButtonPrimary>
            </div>
        </>
    );
};

export default AvatarBuilder;
