import { type LanguageStrings, type PersonGenders, genderTypes } from "@ommej/metadata";
import { useCallback, useContext, useState } from "react";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import VideoComponent from "~/src/components/tools/videoComponent/videoComponent";
import { LanguageContext } from "~/src/contexts/languageContext";
import ArrowWhiteIcon from "~/src/media/icons/arrow_white.svg";
import language from "~/src/utils/language";
import "./gender.css";

type TSetGenderProps = {
    handleGenderClicked: (gender: string) => void;
};

function getImageClass(
    chosenGender: Extract<keyof PersonGenders, string> | undefined,
    key: Extract<keyof PersonGenders, string>,
): string {
    if (chosenGender && chosenGender !== key) {
        return "gender-button gender-button-unselected gender-button-faded";
    }
    if (chosenGender === key) {
        return "gender-button gender-button-selected";
    }
    return "gender-button gender-button-unselected";
}

const Gender = ({ handleGenderClicked }: TSetGenderProps) => {
    const { locales } = useContext(LanguageContext);
    const { COMMON, GENDER } = language[locales];
    // Extract:
    // https://stackoverflow.com/questions/51808160/keyof-inferring-string-number-when-key-is-only-a-string
    const [chosenGender, setChosenGender] = useState<Extract<keyof PersonGenders, string>>();

    const sendGender = () => {
        if (chosenGender) {
            handleGenderClicked(chosenGender);
        }
    };

    const getGenderButton = useCallback(
        (key: Extract<keyof PersonGenders, string>, genderItem: LanguageStrings) => {
            return (
                <div className="gender-button-box" key={key}>
                    <button
                        className={getImageClass(chosenGender, key)}
                        type="button"
                        id={key}
                        onClick={() => {
                            setChosenGender(key);
                        }}
                    >
                        <img
                            className={
                                chosenGender === key
                                    ? "gender-image gender-image-selected"
                                    : "gender-image"
                            }
                            src={`assets/genders/${key.toLowerCase()}.svg`}
                            alt={genderItem[locales]}
                        />
                    </button>
                    {genderItem[locales]}
                </div>
            );
        },
        [chosenGender],
    );

    return (
        <>
            <h2 className="font1" style={{ marginBottom: "1rem" }}>
                {GENDER.HEADING}
            </h2>
            <div className="gender-wrapper gender-wrapper-top">
                {Object.entries(genderTypes).map(([key, genderItem]) => {
                    if (key === "MALE" || key === "FEMALE") {
                        return getGenderButton(key, genderItem);
                    }
                    return null;
                })}
            </div>
            <div className="gender-wrapper gender-wrapper-bottom">
                {Object.entries(genderTypes).map(([key, genderItem]) => {
                    if (key !== "MALE" && key !== "FEMALE") {
                        return getGenderButton(key, genderItem);
                    }
                    return null;
                })}
            </div>
            <div className="gender-footer-wrapper">
                <ButtonPrimary
                    size="large"
                    icon={{ url: ArrowWhiteIcon, layout: "right" }}
                    onClick={sendGender}
                    className={`gender-done-button ${chosenGender && "gender-show"}`}
                >
                    {COMMON.BUTTON_READY}
                </ButtonPrimary>
            </div>
            <VideoComponent
                videoUrl="https://files.ommej.se/video/sexuell_laggning/sexuell_laggning_hd.mp4"
                captions
                heading={<h2>{GENDER.VIDEO_TEXT}</h2>}
                videoStyle={{
                    width: "100%",
                }}
            />
        </>
    );
};

export default Gender;
