import { useState } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import { LanguageContext } from "~/src/contexts/languageContext";
import ArrowWhiteIcon from "~/src/media/icons/arrow_white.svg";
import { STORAGE_KEY_CODES } from "~/src/utils/constants";
import language from "~/src/utils/language";
import Header from "../header/header";
import TextInput from "../tools/inputs/textInput";
import "./invitationCode.css";

const InvitationCode = () => {
    const { locales } = React.useContext(LanguageContext);
    const { COMMON, CODE } = language[locales];
    const [enteredCode, setEnteredCode] = useState("");
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 7) {
            return;
        }
        let codeString = e.target.value.split("-").join("").toUpperCase();
        if (codeString.length > 0) {
            const tempArr = codeString.match(/.{1,3}/g);
            if (tempArr) {
                codeString = tempArr.join("-");
            }
        }
        setEnteredCode(codeString);
    };

    const handleSendCode = async (codeFormatted: string) => {
        const code = codeFormatted.split("-").join("");
        const newCodes: string[] = [];

        const existingCodes = localStorage.getItem(STORAGE_KEY_CODES);
        if (existingCodes) {
            try {
                const codes: string[] = JSON.parse(existingCodes);
                newCodes.push(...codes);
                if (!newCodes.includes(code)) {
                    newCodes.push(code);
                }
            } catch (e) {
                console.error(e);
                // something is not right with the saved value, let's remove it
                localStorage.removeItem(STORAGE_KEY_CODES);
            }
        } else {
            newCodes.push(code);
        }

        try {
            localStorage.setItem(STORAGE_KEY_CODES, JSON.stringify(newCodes));
        } catch (e: unknown) {
            console.error(e);
        }

        return navigate("/home");
    };

    return (
        <div className="invitationCode-wrapper">
            <Header
                header={CODE.HEADER}
                handleBack={() => {
                    return navigate("/home");
                }}
            />

            <div style={{ display: "grid", gap: "2rem", placeItems: "center" }}>
                <div style={{ textAlign: "center", marginBottom: "1.5rem" }}>
                    <h2 className="font1">{CODE.HEADER_NORMAL}</h2>
                    {CODE.SUBHEADER_NORMAL}
                </div>
                <TextInput
                    name="code"
                    className="invitationCode-input"
                    ariaLabel={CODE.INPUT_CODE}
                    placeholder={CODE.INPUT_CODE}
                    value={enteredCode}
                    onError={false}
                    handleChange={handleChange}
                />
                <ButtonPrimary
                    size="large"
                    className="invitationCode-accept-button"
                    icon={{ url: ArrowWhiteIcon, layout: "right" }}
                    onClick={() => {
                        handleSendCode(enteredCode);
                    }}
                    disabled={enteredCode.length < 2}
                >
                    {COMMON.OK}
                </ButtonPrimary>
            </div>
        </div>
    );
};

export default InvitationCode;
