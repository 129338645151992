import { personTypes } from "@ommej/metadata";
import type { BebObject, Form, Uuid } from "@ommej/types";
import { useContext, useEffect } from "react";
import * as React from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import PencilIcon from "~/src/media/icons/pencil.svg";
import { isDemo } from "~/src/utils/api";
import language from "~/src/utils/language";
import { getPersonalQuestion } from "~/src/utils/utils";
import Header from "../header/header";
import RoundWrapper from "../tools/roundWrapper/roundWrapper";
import "./category.css";

type CategoryProp = {
    type: string;
    data: Record<Uuid, BebObject[]>;
    inactiveData: Record<Uuid, BebObject[]>;
    form: Form.FormData;
    close: () => void;
};

const Category = ({ type, data, inactiveData, form, close }: CategoryProp) => {
    const { profile } = useContext(ProfileContext);
    const { locales } = React.useContext(LanguageContext);
    const { COMMON, BEB } = language[locales];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function getListItems(listData: Record<Uuid, BebObject[]>, editable: boolean) {
        return Object.entries(listData).map(([questionId, bebData]) => {
            return bebData.map((entry: BebObject) => {
                switch (entry.answer.type) {
                    case "text": {
                        // type assertion: answer of type 'text' is an AnswerText
                        const answer = entry.answer as Form.AnswerText;
                        return entry.data.length > 0 ? (
                            entry.data.map((personId: Uuid) => {
                                const q = getPersonalQuestion(profile, entry.question, personId);
                                return (
                                    <li key={personId}>
                                        <div className="category-question-wrapper">
                                            <p>{`${q}: ${answer.text.sv}`}</p>
                                            {editable && (
                                                <Link
                                                    className="beb-edit-answer-link"
                                                    to={`/form/${form.metadata.id}/${form.metadata.version}/${questionId}`}
                                                    state={{
                                                        back: `/beb/${type.toLowerCase()}`,
                                                        next: "/beb",
                                                    }}
                                                >
                                                    <img
                                                        src={PencilIcon}
                                                        alt={BEB.CHANGE_QUESTION}
                                                    />
                                                </Link>
                                            )}
                                        </div>
                                    </li>
                                );
                            })
                        ) : (
                            <li key={questionId + answer.text.sv}>
                                <div className="category-question-wrapper">
                                    <p>{`${entry.question.text.sv}: ${answer.text.sv}`}</p>
                                    {editable && (
                                        <Link
                                            className="beb-edit-answer-link"
                                            to={`/form/${form.metadata.id}/${form.metadata.version}/${questionId}`}
                                            state={{
                                                back: `/beb/${type.toLowerCase()}`,
                                                next: "/beb",
                                            }}
                                        >
                                            <img src={PencilIcon} alt={BEB.CHANGE_QUESTION} />
                                        </Link>
                                    )}
                                </div>
                            </li>
                        );
                    }
                    case "accommodations":
                        // Answers with type accommodation is listed under houses,
                        // if the child has answered "ingen" then the answer is TEXT
                        // and rendered under AnswerTypes.TEXT
                        return null;
                    case "persons":
                    default:
                        return entry.data.map((personId: Uuid) => {
                            const q = getPersonalQuestion(profile, entry.question, personId);
                            const person = profile?.persons?.[personId];
                            return (
                                <li key={personId}>
                                    <div className="category-question-wrapper">
                                        <p>{`${q}: ${
                                            personTypes[person?.type as string]?.lang.sv
                                        }`}</p>
                                        {editable && (
                                            <Link
                                                className="beb-edit-answer-link"
                                                to={`/form/${form.metadata.id}/${form.metadata.version}/${questionId}`}
                                                state={{
                                                    back: `/beb/${type.toLowerCase()}`,
                                                    next: "/beb",
                                                }}
                                            >
                                                <img src={PencilIcon} alt={BEB.CHANGE_QUESTION} />
                                            </Link>
                                        )}
                                    </div>
                                </li>
                            );
                        });
                }
            });
        });
    }

    return (
        <div className={`content-wrapper ${isDemo ? "app-demo-background" : "content-dark"}`}>
            <Header
                handleBack={() => {
                    close();
                }}
                header={`${BEB.ANSWER} ${BEB[type].toLowerCase()}`}
            />
            <RoundWrapper className="category-content-wrapper">
                <ul className="bodyText14">{getListItems(data, true)}</ul>
                {Object.keys(inactiveData).length > 0 && (
                    <details className="inactive-answers-details">
                        <summary>
                            <h2 className="summary-header">{COMMON.INACTIVE_ANSWERS}</h2>
                        </summary>
                        <p className="details-info">{COMMON.INACTIVE_ANSWERS_INFO}</p>
                        <ul className="inactive-answers">{getListItems(inactiveData, false)}</ul>
                    </details>
                )}
            </RoundWrapper>
        </div>
    );
};

export default Category;
