import type { House } from "@ommej/types";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LanguageContext } from "~/src/contexts/languageContext";
import { ProfileContext } from "~/src/contexts/profileContext";
import type { TPersonObject } from "~/src/types";
import { request } from "~/src/utils/api";
import language from "~/src/utils/language";
import { mapPersonsToHouse, persistNewPersons, reloadProfile } from "~/src/utils/profileUtils";
import Header from "../header/header";
import Accommodation from "../profile/accommodation";
import Houses from "../profile/houses";
import Done from "../sprinkles/done";
import ErrorComponent from "../tools/errorComponent/errorComponent";

enum STEPS {
    ADD_HOUSE = 0,
    ADD_PERSONS = 1,
    DONE = 2,
}

const AccommodationPicker = () => {
    const { locales } = useContext(LanguageContext);
    const { profile, setProfile } = useContext(ProfileContext);
    const { ACCOMMODATION_PICKER } = language[locales];
    const [pickedHouse, setPickedHouse] = useState<House>();
    const [step, setStep] = useState<number>(STEPS.ADD_HOUSE);
    const navigate = useNavigate();
    const params = useParams();
    const currentHouseId = params.id || null;

    useEffect(() => {
        if (currentHouseId && Object.keys(profile.houses).length > 0 && step <= STEPS.ADD_PERSONS) {
            const currentHouse = profile.houses[currentHouseId];
            setPickedHouse({
                id: currentHouseId,
                type: currentHouse.type,
                avatar: currentHouse.avatar,
            });
            setStep(STEPS.ADD_PERSONS);
        }
    }, [profile]);

    const handleHouseClicked = (house: House | undefined) => {
        if (!house) {
            return;
        }
        setPickedHouse(house);
        setStep(STEPS.ADD_PERSONS);
    };

    const getNewPersons = (persons: TPersonObject[]) => {
        if (!currentHouseId) {
            return persons;
        }
        const oldPersonArr = profile.housePersonMap[currentHouseId];
        const personsToSend = persons.filter((item) => {
            if (!item.id) {
                return true;
            }
            return !oldPersonArr.includes(item.id);
        });
        return personsToSend;
    };

    const handlePersonsClicked = async (house: House, persons: TPersonObject[]) => {
        if (house && persons) {
            try {
                let houseId = "";
                if (!currentHouseId) {
                    const req = await request("clients/profile/houses", "POST", house);
                    houseId = await req.text();
                }
                const id = currentHouseId || houseId;
                const newPersons = getNewPersons(persons);
                const persistedNewPersons = await persistNewPersons(newPersons);
                const currentPersonsId = persons.reduce(
                    (personIdArr: string[], personObj: TPersonObject) => {
                        if (personObj.id) {
                            personIdArr.push(personObj.id);
                        }
                        return personIdArr;
                    },
                    [],
                );
                const mapToHouse = currentHouseId
                    ? [...currentPersonsId, ...persistedNewPersons]
                    : persistedNewPersons;
                await mapPersonsToHouse(id, mapToHouse);
                await reloadProfile(setProfile);
                setStep(STEPS.DONE);
            } catch (error: unknown) {
                <ErrorComponent />;
                console.error("error:", error);
            }
        }
    };

    const renderSteps = () => {
        switch (step) {
            case STEPS.ADD_HOUSE:
                return <Houses handleHouseClicked={handleHouseClicked} />;
            case STEPS.ADD_PERSONS:
                return (
                    pickedHouse && (
                        <Accommodation
                            handlePersonsClicked={handlePersonsClicked}
                            house={pickedHouse}
                        />
                    )
                );
            case STEPS.DONE:
                return (
                    <Done
                        navigateUrl="/myprofile"
                        text={
                            currentHouseId
                                ? ACCOMMODATION_PICKER.CHANGED
                                : ACCOMMODATION_PICKER.CREATED
                        }
                    />
                );
            default:
                return <Houses handleHouseClicked={handleHouseClicked} />;
        }
    };

    const headerStrings = () => {
        switch (step) {
            case STEPS.ADD_HOUSE:
                return ACCOMMODATION_PICKER.ADD_HOUSE;
            case STEPS.ADD_PERSONS:
                return ACCOMMODATION_PICKER.ADD_PERSONS;
            default:
                return "";
        }
    };

    if (profile.birthYear === 0) {
        return null;
    }
    return (
        <div className="content-wrapper">
            {step < STEPS.DONE && (
                <Header
                    handleBack={
                        step > STEPS.ADD_HOUSE && !currentHouseId
                            ? () => {
                                  setStep(step - 1);
                              }
                            : () => {
                                  navigate(-1);
                              }
                    }
                    header={headerStrings()}
                />
            )}
            {renderSteps()}
        </div>
    );
};

export default AccommodationPicker;
