import type { Profile, Uuid } from "@ommej/types";
import type { TPersonObject } from "~/src/types";
import { request } from "~/src/utils/api";

export const persistNewPersons = async (personObjects: TPersonObject[]) => {
    try {
        const persistProm = personObjects.map((person) => {
            return request("clients/profile/persons", "POST", person);
        });
        const fullfilledProms = await Promise.all(persistProm);
        const textArrProm = fullfilledProms.map((fullfilledProm) => {
            return fullfilledProm.text();
        });
        return Promise.all(textArrProm);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const updatePersons = async (
    personObjects: {
        personId: Uuid;
        personData: TPersonObject;
    }[],
) => {
    try {
        const persistProm = personObjects.map((person) => {
            return request(
                `clients/profile/persons/${person.personId}`,
                "PATCH",
                person.personData,
            );
        });
        return Promise.all(persistProm);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const mapPersonsToHouse = async (houseId: Uuid, persons: Uuid[]) => {
    try {
        return request(`clients/profile/houses/${houseId}/persons`, "PUT", persons);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const reloadProfile = async (setProfile: React.Dispatch<React.SetStateAction<Profile>>) => {
    try {
        const res = await request("clients/profile", "GET");
        const beProfile = await res.json();
        if (beProfile && Object.keys(beProfile).length !== 0) {
            setProfile(beProfile);
        }
    } catch (err) {
        console.error(err);
        throw err;
    }
};
