import { Share } from "@capacitor/share";
import { useCallback, useEffect, useState } from "react";
import type * as React from "react";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import CopyIcon from "~/src/media/icons/copy.svg";
import ShareIcon from "~/src/media/icons/share_white.svg";
import { request } from "~/src/utils/api";
import "./shareButton.css";

interface ShareButtonProps {
    text?: string;
    buttonText?: string;
}

export async function sharePossible(): Promise<boolean> {
    const share = await Share.canShare();
    return share.value;
}

const ShareButton: React.FC<ShareButtonProps> = ({ text, buttonText }) => {
    const [canShare, setCanShare] = useState<boolean | undefined>();

    const onShareClick = useCallback(async () => {
        let token: string;
        try {
            // get existing shares
            let res = await request("shares", "GET");
            const existingShares = await res.json();
            if (Object.keys(existingShares).length === 0) {
                // create a new share
                res = await request("shares", "POST", { name: "app" });
                token = (await res.json()).token;
            } else {
                // get token for an existing share. Use the first one (only one
                // should exist)
                const shareId = Object.keys(existingShares)[0];
                res = await request(`shares/token/${shareId}`, "GET");
                token = (await res.json()).token;
            }
        } catch (err) {
            console.error(err);
            return;
        }

        const shareData = {
            title: "Ommej",
            text,
            url: `${
                window.location.origin.startsWith("capacitor")
                    ? "https://app.ommej.se"
                    : window.location.origin
            }/shared?token=${token}`,
            dialogTitle: "Ommej",
        };

        if (canShare) {
            try {
                await Share.share(shareData);
            } catch (err) {
                console.warn("Share failed", err);
            }
        } else {
            try {
                await navigator.clipboard.writeText(shareData.url);
            } catch (err) {
                console.error("Failed writing to clipboard", err);
            }
        }
    }, [canShare]);

    useEffect(() => {
        async function run() {
            const share = await sharePossible();
            setCanShare(share);
        }
        run();
    }, []);

    if (canShare === undefined) {
        return null;
    }

    return (
        <ButtonPrimary
            size="large"
            testid="form-answer-alternative"
            onClick={() => {
                onShareClick();
            }}
            className="shareButton-wrapper"
        >
            <img
                src={canShare ? ShareIcon : CopyIcon}
                alt={canShare ? "share icon" : "copy icon"}
            />
            {buttonText}
        </ButtonPrimary>
    );
};

export default ShareButton;
