import type { Form, SingleAnswer, Uuid } from "@ommej/types";
import { useEffect, useState } from "react";
import * as React from "react";
import ButtonPrimary from "~/src/components/tools/buttons/buttonPrimary";
import { LanguageContext } from "~/src/contexts/languageContext";
import language from "~/src/utils/language";
import AnswersText from "./answersText";
import type { QuestionView } from "./form";
import "./form.css";
import FormFooter from "./formFooter";
import FormQuestion from "./formQuestion";

const QuestionMultiple: React.FC<QuestionView> = ({ question, setAnswer, existingAnswer }) => {
    const { locales } = React.useContext(LanguageContext);
    const { COMMON, FORM } = language[locales];
    const [selectedAnswers, setSelectedAnswers] = useState<Set<Uuid>>(new Set());
    const answerToGroup = new Map<Uuid, number | undefined>();

    const onAnswerClick = (answerId: Uuid) => {
        // toggle the answer
        if (selectedAnswers.has(answerId)) {
            selectedAnswers.delete(answerId);
        } else {
            selectedAnswers.add(answerId);
        }

        // remove answers in other groups
        for (const answer of selectedAnswers) {
            if (answerToGroup.get(answer) !== answerToGroup.get(answerId)) {
                selectedAnswers.delete(answer);
            }
        }

        setSelectedAnswers(new Set(selectedAnswers));
    };

    const onNextClick = () => {
        if (selectedAnswers.size === 0) {
            return;
        }
        const answers: SingleAnswer[] = [];
        for (const answer of selectedAnswers) {
            answers.push({ answer, data: [], timestamp: new Date() });
        }
        setAnswer(question.id, answers);
    };

    useEffect(() => {
        selectedAnswers.clear();
        if (existingAnswer) {
            for (const answer of existingAnswer) {
                selectedAnswers.add(answer.answer);
            }
        }
        setSelectedAnswers(new Set(selectedAnswers));
    }, [question]);

    return (
        <>
            <div className="form-question-wrapper">
                <FormQuestion text={question.question.text.sv} />
                <div className="bodyText14" style={{ textAlign: "center" }}>
                    {FORM.MULTIPLE}
                </div>
                <div className="form-answers">
                    {Object.entries(question.question.answers).map(([answerId, answer]) => {
                        answerToGroup.set(answerId, (answer as Form.AnswerMultiple).group);
                        switch (answer.type) {
                            case "text":
                                return (
                                    <AnswersText
                                        testid="form-answer-alternative"
                                        key={answerId}
                                        text={answer.text.sv}
                                        onClick={() => {
                                            onAnswerClick(answerId);
                                        }}
                                        type={
                                            Object.keys(question.question.answers).length > 4
                                                ? "small"
                                                : "big"
                                        }
                                        marked={selectedAnswers.has(answerId)}
                                    />
                                );
                            case "none":
                                return (
                                    <AnswersText
                                        testid="form-answer-alternative"
                                        key={answerId}
                                        text={COMMON.BUTTON_NEXT}
                                        onClick={() => {
                                            onAnswerClick(answerId);
                                        }}
                                        marked={selectedAnswers.has(answerId)}
                                    />
                                );
                            default:
                                return <span key={answerId}>{FORM.UNKNOWN}</span>;
                        }
                    })}
                </div>
            </div>
            <FormFooter>
                {selectedAnswers.size !== 0 && (
                    <ButtonPrimary
                        size="large"
                        onClick={onNextClick}
                        testid="form-done"
                        disabled={selectedAnswers.size === 0}
                    >
                        {COMMON.BUTTON_NEXT}
                    </ButtonPrimary>
                )}
            </FormFooter>
        </>
    );
};

export default QuestionMultiple;
